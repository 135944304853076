/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { DoneRounded, RoomServiceRounded } from "@mui/icons-material";
import logger from "utils/logger";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import DefaultLineChartWithoutHead from "examples/Charts/LineCharts/DefaultLineChartWithoutHead";
import ordinal from "ordinal";

function OnlineOrdersOverview({ items, swiggyOrders, startDate, endDate }) {
  // logger("PopularItems.js", items);
  const [menu, setMenu] = useState(null);

  function daysBetweenDates(date1, date2) {
    const date1Ms = date1.getTime();
    const date2Ms = date2.getTime();

    // Calculate the difference in milliseconds
    const differenceMs = Math.abs(date1Ms - date2Ms);

    // Convert the difference to days
    const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

    return daysDifference;
  }

  let ordersByDay = {},
    ordersByTime={};
  for (let ord of (swiggyOrders?.orders ?? [])) {
    ordersByDay[new Date(ord.date).toLocaleDateString()] = {
      amount:
        (ordersByDay[new Date(ord.date).toLocaleDateString()]?.amount ?? 0) +
        ord.bill,
      date: new Date(ord.date),
    };
    ordersByTime[new Date(ord.date).getHours()] = {
      amount:
        (ordersByTime[new Date(ord.date).getHours()]?.amount ?? 0) + ord.bill,
      date: new Date(ord.date),
    };
  }
  const renderOnlineOrdersChart = () => {
    let labels = [],
      chartData = [];
    if (daysBetweenDates(startDate, endDate) > 1) {
      labels = Object.values(ordersByDay ?? {})
        .sort((a, b) => {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
        })
        .map((item) => ordinal(item.date.getDate()));
      chartData = Object.values(ordersByDay)
        .sort((a, b) => {
          return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
        })
        .map((item) => item.amount);
    } else {
      labels = [
        "12am-3am",
        "3am-6am",
        "6am-9am",
        "9am-12pm",
        "12pm-3pm",
        "3pm-6pm",
        "6pm-9pm",
        "9pm-12am",
      ];
      chartData = [0, 0, 0, 0, 0, 0, 0, 0];
      for (let d of Object.values(ordersByTime ?? {})) {
        const date = new Date(d.date);
        logger(date.getHours(), date, "timeAndDate");
        if (date.getHours() < 3) {
          chartData[0] += d.amount;
        } else if (date.getHours() < 6) {
          chartData[1] += d.amount;
        } else if (date.getHours() < 9) {
          chartData[2] += d.amount;
        } else if (date.getHours() < 12) {
          chartData[3] += d.amount;
        } else if (date.getHours() < 15) {
          chartData[4] += d.amount;
        } else if (date.getHours() < 18) {
          chartData[5] += d.amount;
        } else if (date.getHours() < 21) {
          chartData[6] += d.amount;
        } else {
          chartData[7] += d.amount;
        }
      }
    }
    return {
      labels,
      datasets: [
        {
          label: "Swiggy",
          color: "info",
          data: chartData,
        },
      ],
    };
  };

  console.log(ordersByDay, ordersByTime,renderOnlineOrdersChart(), "ordersByTime");
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeMenu = () => setMenu(null);

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      {/* <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem> */}
    </Menu>
  );

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Online orders
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <MDBox color="text" px={2}>
          <Box sx={{ width: "100%" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="Online Orders" {...a11yProps(0)} />
                {/* <Tab label="Online Payments" {...a11yProps(1)} />
                <Tab label="Virtual outlets" {...a11yProps(2)} />
                <Tab label=<Tab label="Online Payments" {...a11yProps(1)} />
                <Tab label="Virtual outlets" {...a11yProps(2)} />
                <Tab label="No Accounted Sales" {...a11yProps(2)} />"No Accounted Sales" {...a11yProps(2)} /> */}
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ display: "flex", gap: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <b>{swiggyOrders?.orders?.length ?? 0}</b>
                    <span>No of orders</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      flexDirection: "column",
                    }}
                  >
                    <b>{swiggyOrders?.total ?? 0}</b>
                    <span>Total Sales</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      borderLeft: "1px solid #E0E0E0",
                      paddingLeft: "20px",
                      fontSize: "1rem",
                      flexDirection: "column",
                      color: "#F28421",
                    }}
                  >
                    <b>{swiggyOrders?.orders?.length ?? 0}</b>
                    <span>Swiggy</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      fontSize: "1rem",
                      flexDirection: "column",
                      color: "#EB354A",
                    }}
                  >
                    <b>0</b>
                    <span>Zomato</span>
                  </div>
                </div>
                <div style={{ paddingTop: "10px" }}>
                  <DefaultLineChartWithoutHead
                    icon={{ color: "info", component: "leaderboard" }}
                    title="Default Line Chart"
                    description="Product insights"
                    chart={renderOnlineOrdersChart()}
                    // chart={{
                    //   labels: [
                    //     "Apr",
                    //     "May",
                    //     "Jun",
                    //     "Jul",
                    //     "Aug",
                    //     "Sep",
                    //     "Oct",
                    //     "Nov",
                    //     "Dec",
                    //   ],
                    //   datasets: [
                    //     // {
                    //     //   label: "Organic Search",
                    //     //   color: "info",
                    //     //   data: [50, 40, 300, 220, 500, 250, 400, 230, 500],
                    //     // },
                    //     // {
                    //     //   label: "Referral",
                    //     //   color: "dark",
                    //     //   data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
                    //     // },
                    //     // {
                    //     //   label: "Direct",
                    //     //   color: "primary",
                    //     //   data: [40, 80, 70, 90, 30, 90, 140, 130, 200],
                    //     // },
                    //   ],
                    // }}
                  />
                </div>
              </div>
            </CustomTabPanel>
            {/* <CustomTabPanel value={value} index={1}>
              Item Two
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              Item Three
            </CustomTabPanel> */}
          </Box>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default OnlineOrdersOverview;
