import { createContext, useState } from "react";
const sidebar = createContext({});
const { Provider } = sidebar;

const SidebarProvider = ({ children }) => {
	const [sidebarOpen, setSidebarOpen] = useState(false)

	return (
		<Provider
			value={{
				sidebarOpen,
                setSidebarOpen
			}}
		>
			{children}
		</Provider>
	);
};

export { SidebarProvider, sidebar };
