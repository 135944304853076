/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import styles from "./AddItem.module.css";
import commonStyles from "../../../styles.module.css";

// Authentication layout components

// Images
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    FormControl,
    FormControlLabel,
    InputLabel,
    List,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { admin } from "providers/AdminProvider";
import { restaurant } from "providers/RestaurantProvider";
import { RiAddCircleLine, RiDeleteBin7Fill } from "react-icons/ri";
import axios from "axios";
import toast from "utils/toast";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const tags = [
    "fruits",
    "vegitables",
    "meat",
    "seafood",
    "poultry",
    "lamb",
    "eggs",
    "beef",
    "pork",
    "dairy",
    "milk",
    "cheese",
    "yogurt",
    'butter',
    "plant based milk",
    "macaroni",
    "pizza",
    "burgers",
    "fried chicken",
    "mashed potatoes",
    "casseroles",
    "grains and cereals",
    "rice",
    "wheat",
    "oats",
    "barley",
    "quinoa",
    "pasta",
    "bread",
    "cereal",
    'legumes',
    "nuts and seeds",
    "beverages",
    "water",
    "tea",
    "coffee",
    "juice",
    "soft Drinks",
    "smoothies",
    "alcoholic beverages",
    "sweets and desserts",
    "cakes",
    "cookies",
    "ice cream",
    "pies",
    "puddings",
    "chocolates",
    "dessert bars",
    "herbs and spices",
    "condiments and sauces",
    "snacks",
    "chips",
    "pretzels",
    "popcorn",
    "nuts",
    "dried fruits",
    "soups and stews",
    "salads",
    'ethnic and international',
    "healthy food",
    "crackers",
    "snacks",
    "bread and backery",
    "Ethnic and specialty foods"

]


function AddItemDialog({
    setConfirmDialogOpen,
    confirmDialogOpen,
    categoryToUpdate,
    setCategoryToUpdate,
    item = {},
    isUpdate = false,
}) {
    const restaurantContext = useContext(restaurant);
    const [isLoading, setIsLoading] = useState(false);
    const { items, setItems, updateItem } = restaurantContext;
    const adminContext = useContext(admin);
    const { createItem, UpdateItem } = adminContext;
    const [data, setData] = useState({
        name: item.name ?? "",
        price: item.price ?? 0,
        description: item.description ?? "",
        mustTry: item.mustTry ?? null,
        isVeg: item.isVeg ?? null,
        isBar: item.isBar ?? false,
        hasComplements: item?.complements?.length > 0 ?? null,
        hasProperties: item?.properties?.length > 0 ?? null,
        available: item.available ?? null,
        department: item.department ?? null,
        maxPropertySelectionsAllowed: item.maxPropertySelectionsAllowed ?? null,
        // minPropertySelectionsRequired: item.minPropertySelectionsRequired ?? null
    });
    const handleChange = (e) => {
        let { name, value } = e.target;
        if (value === "yes" || value === "no") {
            value = value === "yes";
        }
        if (value === "neither" && name == "isVeg") {
            value = null;
        }
        if (name === "price") {
            value = value < 0 ? 0 : value;
        }
        setData((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSwitchChange = (e) => {
        setData((prevState) => ({
            ...prevState,
            [e.target.name]: e.target.checked,
        }));
    };

    const [departments, setDepartments] = useState({})

    const [complements, setComplements] = useState(item.complements ?? []);
    const [newComplement, setNewComplement] = useState("");

    const [properties, setProperties] = useState(item.properties ?? []);

    const [customisationDialogOpen, setCustomisationDialogOpen] = useState(false);

    const [selectedTags, setSelectedTags] = useState([]);


    const handleComplementChange = (e) => {
        setNewComplement(e.target.value);
    };

    const handleAddComplement = (e) => {
        e.preventDefault();
        if (newComplement.trim() !== "") {
            if (!complements.includes(newComplement)) {
                setComplements([...complements, newComplement]);
            }
            setNewComplement("");
        }
    };

    const handleRemoveComplement = (index) => {
        const updatedComplements = [...complements];
        updatedComplements.splice(index, 1);
        setComplements(updatedComplements);
    };

    const handleOptionNameChange = (event, propertyIndex, optionIndex) => {
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex].options[optionIndex].name =
            event.target.value;
        setProperties(updatedProperties);
    };

    const handleOptionPriceChange = (event, propertyIndex, optionIndex) => {
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex].options[optionIndex].price =
            event.target.value < 0 ? 0 : event.target.value;
        setProperties(updatedProperties);
    };

    const handleAddProperty = (name, isMandatory, hasMultipleSelection) => {
        const updatedProperties = [
            ...properties,
            { name, isMandatory, hasMultipleSelection, options: [{ name: "", price: "" }] },
        ];
        setProperties(updatedProperties);
    };

    const handleAddOption = (propertyIndex) => {
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex].options.push({
            name: "",
            price: 0,
        });
        setProperties(updatedProperties);
    };

    const fetchDepartments = async () => {
        try {
            const res = await axios.get(
                `restaurants/${localStorage.getItem("restaurant")}/departments`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );

            const departmentMap = {};
            (res?.data ?? []).forEach(dep => {
                departmentMap[dep._id] = dep.name
            })

            setDepartments({ ...departmentMap });

        } catch (err) {
            console.log(err);
        }
    };


    const handleRemoveProperty = (propertyIndex) => {
        const updatedProperties = [...properties];
        updatedProperties.splice(propertyIndex, 1);
        setProperties(updatedProperties);
    };

    const handleRemoveOption = (propertyIndex, optionIndex) => {
        const updatedProperties = [...properties];
        updatedProperties[propertyIndex].options.splice(optionIndex, 1);
        setProperties(updatedProperties);
    };


    const saveHandler = (e) => {
        setIsLoading(true)
        let finalData = { ...data, tags: selectedTags };
        finalData.complements = complements;
        finalData.properties = properties;
        finalData.additionalDetails = additionalDetails
        finalData.categoryId = categoryToUpdate._id
        const callFunction = createItem(
            finalData,
            categoryToUpdate,
            setCategoryToUpdate,
            setConfirmDialogOpen,
            items,
            setItems
        );
        toast.promise(callFunction, {
            loading: "Adding Item",
            error: (err) => err?.message ?? "item Adding failed",
            success: (data) => {
                setIsLoading(false)
                setData({
                    name: "",
                    price: 0,
                    description: "",
                    mustTry: null,
                    isVeg: null,
                    isBar: false,
                    hasComplements: null,
                    hasProperties: null,
                    available: null,
                    department: null,
                    maxPropertySelectionsAllowed: null,
                    // minPropertySelectionsRequired: null
                })
                setSelectedTags([]);
                setAdditionalDetails({
                    taste: null,
                    countryOfOrigin: null,
                    calories: null,
                    carbs: null,
                    protein: null,
                    fat: null
                })
                restaurantContext.refreshRestaurant();
                return "Item Added Successfully"
            }
        });

    };

    const [additionalDetails, setAdditionalDetails] = useState({
        taste: null,
        countryOfOrigin: null,
        calories: null,
        carbs: null,
        protein: null,
        fat: null
    })

    const handleAdditionalDetailsChange = (e) => {
        setAdditionalDetails({
            ...additionalDetails,
            [e.target.name]: e.target.value
        })
    }

    const updateHandler = async (e) => {
        setIsLoading(true)
        let finalData = { ...data, tags: selectedTags };
        finalData.complements = complements;
        finalData.properties = properties;
        finalData.additionalDetails = additionalDetails;

        const callFunction = UpdateItem(finalData, setConfirmDialogOpen, item, updateItem);

        toast.promise(callFunction, {
            loading: "Updating Item...",
            error: (err) => err?.message ?? "item updation failed",
            success: (data) => {
                setData({
                    name: "",
                    price: 0,
                    description: "",
                    mustTry: null,
                    isVeg: null,
                    isBar: false,
                    hasComplements: null,
                    hasProperties: null,
                    available: null,
                    department: null,
                    maxPropertySelectionsAllowed: null,
                    // minPropertySelectionsRequired: null
                })
                setSelectedTags([])
                setAdditionalDetails({
                    taste: null,
                    countryOfOrigin: null,
                    calories: null,
                    carbs: null,
                    protein: null,
                    fat: null
                })
                return "Items Updated Successfully"
            }
        });
        setIsLoading(false)
    };

    useEffect(() => {
        fetchDepartments()
        setData({
            name: item.name ?? "",
            price: item.price ?? 0,
            description: item.description ?? "",
            mustTry: item.mustTry ?? null,
            isVeg: item.isVeg ?? null,
            isBar: item.isBar ?? false,
            hasComplements: item?.complements?.length > 0 ?? null,
            hasProperties: item?.properties?.length > 0 ?? null,
            available: item.available === false ? false : true,
            department: item.department ?? null,
            maxPropertySelectionsAllowed: item.maxPropertySelectionsAllowed ?? null,
            // minPropertySelectionsRequired: item.minPropertySelectionsRequired ?? null
        });
        setSelectedTags(item.tags ?? []);
        setComplements(item.complements ?? []);
        setAdditionalDetails({
            taste: item.additionalDetails?.taste ?? null,
            countryOfOrigin: item.additionalDetails?.countryOfOrigin ?? null,
            calories: item.additionalDetails?.calories ?? null,
            carbs: item.additionalDetails?.carbs ?? null,
            protein: item.additionalDetails?.protein ?? null,
            fat: item.additionalDetails?.fat ?? null
        })
        setProperties(item.properties ?? []);
    }, [item]);

    const handleClose = () => {
        setAdditionalDetails({
            taste: null,
            countryOfOrigin: null,
            calories: null,
            carbs: null,
            protein: null,
            fat: null
        })
        setData({
            name: "",
            price: 0,
            description: "",
            mustTry: null,
            isVeg: null,
            isBar: false,
            hasComplements: null,
            hasProperties: null,
            available: null,
            department: null,
            maxPropertySelectionsAllowed: null,
            // minPropertySelectionsRequired: null
        })
        setConfirmDialogOpen(false);
    }



    return (
        <>
            <Dialog
                open={confirmDialogOpen}
                onClose={() => {
                    handleClose()
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "600px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            {isUpdate ? "Edit Item" : "Add Item"}
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Item Name"
                                    name="name"
                                    value={data.name}
                                    onChange={handleChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="number"
                                    label="Item Price"
                                    name="price"
                                    value={data.price}
                                    onChange={handleChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={1}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Is it Vegeterian
                                    </InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        sx={{ width: "100%", height: "29px" }}
                                        value={
                                            data.isVeg
                                                ? "yes"
                                                : data.isVeg === false
                                                    ? "no"
                                                    : "neither"
                                        }
                                        onChange={handleChange}
                                        name="isVeg"
                                        variant="standard"
                                    >
                                        <MenuItem value={"yes"}>Yes</MenuItem>
                                        <MenuItem value={"no"}>No</MenuItem>
                                        <MenuItem value={"neither"}>Neither</MenuItem>
                                    </Select>
                                </FormControl>
                            </MDBox>
                            {
                                Object.values(item).length === 0 ?
                                    (<MDBox mb={1}>
                                        <FormControl variant="standard" sx={{ width: "100%" }}>
                                            <InputLabel id="demo-simple-select-standard-label">
                                                Department
                                            </InputLabel>
                                            <Select
                                                // labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                sx={{ width: "100%", height: "29px" }}
                                                value={
                                                    data.department}
                                                onChange={handleChange}
                                                name="department"
                                                variant="standard"
                                            >
                                                {
                                                    Object.keys(departments ?? {}).map((key, index) => {
                                                        return <MenuItem key={index} value={key}>{departments[key]}</MenuItem>
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                    </MDBox>) : null
                            }
                            <MDBox mb={1}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Must try</span>
                                    <Switch
                                        name={"mustTry"}
                                        checked={data.mustTry}
                                        onChange={handleSwitchChange}
                                        color="warning"
                                    />
                                </div>
                            </MDBox>
                            <MDBox mb={1}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Is it Drink</span>
                                    <Switch
                                        name={"isBar"}
                                        checked={data.isBar}
                                        onChange={handleSwitchChange}
                                        color="warning"
                                    />
                                </div>
                            </MDBox>
                            <MDBox mb={2}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Is the item available now</span>
                                    <Switch
                                        name={"available"}
                                        checked={data.available}
                                        onChange={handleSwitchChange}
                                        color="warning"
                                    />
                                </div>
                            </MDBox>

                            <MDBox mb={1}>
                                <MDInput
                                    type="text"
                                    label="Description"
                                    name="description"
                                    multiline
                                    rows={3}
                                    maxRows={3}
                                    style={{ fontSize: ".7rem" }}
                                    value={data.description}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Does the item have complements?</span>
                                    <Switch
                                        name="hasComplements"
                                        onChange={handleSwitchChange}
                                        checked={data.hasComplements}
                                        color="warning"
                                    />
                                </div>
                                {data.hasComplements && (
                                    <>
                                        <div>
                                            <MDBox>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <ol style={{ width: "100%" }}>
                                                        {complements.map((complement, index) => (
                                                            <li style={{ width: "100%" }}>
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <span>{complement}</span>
                                                                    <Button color="black">
                                                                        <RiDeleteBin7Fill
                                                                            onClick={() =>
                                                                                handleRemoveComplement(index)
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </MDBox>
                                        </div>
                                        <div>
                                            <MDBox mb={2}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <MDInput
                                                        type="text"
                                                        label="compliment"
                                                        name="complement"
                                                        value={newComplement}
                                                        onChange={handleComplementChange}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handleAddComplement(e);
                                                            }
                                                        }}
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        onClick={handleAddComplement}
                                                    >
                                                        <RiAddCircleLine
                                                            style={{ color: "white", fontSize: "1rem" }}
                                                            color="white"
                                                        />
                                                    </Button>
                                                </div>
                                            </MDBox>
                                        </div>
                                    </>
                                )}
                            </MDBox>
                            <MDBox mb={2}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                    }}
                                >
                                    <span>Does the item have customizations?</span>
                                    <Switch
                                        checked={data.hasProperties}
                                        onChange={handleSwitchChange}
                                        color="warning"
                                        name="hasProperties"
                                    />
                                </div>
                                {data.hasProperties && (
                                    <div>
                                        <MDBox>
                                            {properties.map((property, propertyIndex) => (
                                                <div
                                                    className={styles.customisation}
                                                    key={propertyIndex}
                                                >
                                                    <div style={{ justifyContent: "flex-start", display: "flex", gap: "10px", marginTop: "20px" }}>
                                                        <b>{property.name}</b>
                                                        <RiDeleteBin7Fill
                                                            onClick={() =>
                                                                handleRemoveProperty(propertyIndex)
                                                            }
                                                        />
                                                    </div>


                                                    {
                                                        property.hasMultipleSelection &&

                                                        <div style={{ justifyContent: "flex-start", display: "flex", gap: "10px", marginTop: "20px", paddingLeft: "0px" }}>
                                                            <MDInput
                                                                type="number"
                                                                label="Minimum Selection Required"
                                                                name="minSelectionsRequired"
                                                                placeholder="If no minmum limit leave it"
                                                                id="minSelectionsRequired"
                                                                value={property.minSelectionsRequired}
                                                                onChange={(e) => {
                                                                    setProperties(prev => {
                                                                        let updatedData = [...prev]
                                                                        updatedData[propertyIndex].minSelectionsRequired = e.target.value < 1 ? 1 : e.target.value;
                                                                        return [...updatedData];
                                                                    })
                                                                }}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                            <MDInput
                                                                type="number"
                                                                label="Maximum Selections Allowed"
                                                                placeholder="If no maximum limit leave it"
                                                                name="maxSelectionsAllowed"
                                                                id="maxSelectionsAllowed"
                                                                value={property.maxSelectionsAllowed}
                                                                onChange={(e) => {
                                                                    setProperties(prev => {
                                                                        let updatedData = [...prev]
                                                                        updatedData[propertyIndex].maxSelectionsAllowed = e.target.value < 1 ? 1 : e.target.value;
                                                                        return [...updatedData];
                                                                    })
                                                                }}
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </div>
                                                    }
                                                    <div style={{ justifyContent: "flex-start", display: "flex", gap: "10px", marginTop: "10px", paddingLeft: "5px" }}>
                                                        <FormControlLabel control={
                                                            <Checkbox checked={property.isMandatory}
                                                                onChange={(e) => {
                                                                    setProperties(prev => {
                                                                        let updatedData = [...prev]
                                                                        updatedData[propertyIndex].isMandatory = e.target.checked
                                                                        return [...updatedData];
                                                                    })
                                                                }}
                                                            />
                                                        } label="Is Mandatory" />
                                                        <FormControlLabel control={
                                                            <Checkbox checked={property.hasMultipleSelection}
                                                                onChange={(e) => {
                                                                    setProperties(prev => {
                                                                        let updatedData = [...prev]
                                                                        updatedData[propertyIndex].hasMultipleSelection = e.target.checked
                                                                        return [...updatedData];
                                                                    })
                                                                }}
                                                            />
                                                        } label="Has Multiple Selection" />
                                                    </div>
                                                    <div className={styles.customisation_options}>
                                                        {property.options.map((option, optionIndex) => (
                                                            <div
                                                                className={styles.customisation_option}
                                                                key={optionIndex}
                                                            >

                                                                <div className={styles.option_details}>
                                                                    <div className={styles.option_row}>

                                                                        <MDInput
                                                                            type="text"
                                                                            label="Name"
                                                                            name="option_name"
                                                                            id="option_name"
                                                                            value={option.name}
                                                                            onChange={(e) =>
                                                                                handleOptionNameChange(
                                                                                    e,
                                                                                    propertyIndex,
                                                                                    optionIndex
                                                                                )
                                                                            }
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </div>
                                                                    <div className={styles.option_row}>
                                                                        <MDInput
                                                                            type="number"
                                                                            label="Price"
                                                                            id="option_price"
                                                                            name="option_price"
                                                                            value={option.price}
                                                                            onChange={(e) =>
                                                                                handleOptionPriceChange(
                                                                                    e,
                                                                                    propertyIndex,
                                                                                    optionIndex
                                                                                )
                                                                            }
                                                                            variant="outlined"
                                                                            fullWidth
                                                                        />
                                                                    </div>
                                                                    <RiDeleteBin7Fill
                                                                        onClick={() =>
                                                                            handleRemoveOption(
                                                                                propertyIndex,
                                                                                optionIndex
                                                                            )
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        ))}

                                                        <Button
                                                            sx={{ marginLeft: "5px" }}
                                                            variant="contained"
                                                            onClick={() => handleAddOption(propertyIndex)}
                                                        >
                                                            <span style={{ color: "white" }}>
                                                                Add Option
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </div>
                                            ))}
                                            {
                                                properties.length >0 &&
                                            <MDBox mb={2} mt={2}>
                                                <MDInput
                                                    type="number"
                                                    label="Maximum Property Selections Allowed"
                                                    placeholder="If no maximum limit leave it"
                                                    name="maxPropertySelectionsAllowed"
                                                    id="maxPropertySelectionsAllowed"
                                                    value={data.maxPropertySelectionsAllowed}
                                                    onChange={handleChange}
                                                    variant="standard"
                                                    fullWidth
                                                />
                                            </MDBox>
                                            }
                                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>

                                                <Button
                                                    onClick={() => setCustomisationDialogOpen(true)}
                                                >
                                                    Add Customization
                                                </Button>
                                            </div>
                                        </MDBox>
                                    </div>
                                )}
                            </MDBox>
                            {/* <MDBox mb={2}>
                                <MDInput
                                    type="number"
                                    label="Minimum Property Selections Required"
                                    name="minPropertySelectionsRequired"
                                    placeholder="If no minmum limit leave it"
                                    id="minPropertySelectionsRequired"
                                    value={data.minPropertySelectionsRequired}
                                    onChange={handleChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox> */}

                            <MDBox mb={2}>
                                <b>Additional Details</b>
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Taste"
                                    name="taste"
                                    value={additionalDetails.taste}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Country of origin"
                                    name="countryOfOrigin"
                                    value={additionalDetails.countryOfOrigin}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Calories"
                                    name="calories"
                                    value={additionalDetails.calories}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Carbs"
                                    name="carbs"
                                    value={additionalDetails.carbs}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Protein"
                                    name="protein"
                                    value={additionalDetails.protein}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Fat"
                                    name="fat"
                                    value={additionalDetails.fat}
                                    onChange={handleAdditionalDetailsChange}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <Autocomplete
                                    multiple
                                    id="tags-standard"
                                    value={selectedTags}
                                    onChange={(event, newValue) => {
                                        console.log(newValue)
                                        setSelectedTags([
                                            ...(new Set(newValue))
                                        ]);
                                    }}
                                    options={tags}
                                    getOptionLabel={(option) => option}
                                    filterSelectedOptions
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Tags"
                                            variant="standard"
                                            placeholder="Tags"
                                        />
                                    )}
                                />
                            </MDBox>

                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={Object.values(item).length === 0 ? saveHandler : updateHandler}
                                    // disabled={name.trim() === "" || isLoading}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>

                        </MDBox>
                    </MDBox >
                </Card >
                <CustomisationDialog
                    open={customisationDialogOpen}
                    setOpen={setCustomisationDialogOpen}
                    onAdd={handleAddProperty}
                />
            </Dialog >
        </>
    );
}

const CustomisationDialog = ({ open, setOpen, onAdd }) => {
    const [name, setName] = useState("");
    const [hasMultipleSelection, sethasMultipleSelection] = useState(false);
    const [isMandatory, setIsMandatory] = useState(false);
    const [otherName, setOtherName] = useState("");

    const handleAdd = () => {
        onAdd(name === "Other" ? otherName : name, isMandatory, hasMultipleSelection);
        setName("");
        setOtherName("");
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(false)}
            sx={{
                "& .MuiDialog-paper": {
                    backgroundColor: "transparent",
                    boxShadow: "none",
                    borderRadius: "15px"
                },

                "& .MuiModal-backdrop": {
                    backdropFilter: "blur(8px)",
                },
            }}
        >

            <div className={styles.container}>
                <h2>Add Customisation</h2>
                <div className={styles.form}>
                    <div className={styles.form_group}>
                        <FormControl variant="standard" sx={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-standard-label">
                                What do you want to customise?
                            </InputLabel>

                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                sx={{ width: "100%", height: "50px" }}
                                onChange={(e) => setName(e.target.value)}
                                variant="standard"
                            >
                                <MenuItem value={"Quantity"}>Quantity</MenuItem>
                                <MenuItem value={"Add-Ons"}>Add-Ons</MenuItem>
                                <MenuItem value={"Other"}>Other</MenuItem>
                            </Select>
                        </FormControl>

                    </div>
                    <div className={styles.form_group}>
                        <FormControlLabel control={
                            <Checkbox checked={isMandatory} onChange={(e) => setIsMandatory(e.target.checked)} />
                        } label="Is Mandatory" />
                        <FormControlLabel control={
                            <Checkbox checked={hasMultipleSelection} onChange={(e) => sethasMultipleSelection(e.target.checked)} />
                        } label="Has Multiple Options" />

                    </div>
                    {name === "Other" && (
                        <div className={styles.form_group}>

                            <MDInput
                                type="text"
                                label="Name"
                                name="other_name"
                                value={otherName}
                                onChange={(e) => setOtherName(e.target.value)}
                                variant="standard"
                                fullWidth
                            />
                        </div>
                    )}
                </div>

                <div style={{ display: "flex", justifyContent: "center" }}>

                    <Button
                        disabled={name.trim() === ""}
                        sx={{ marginLeft: "5px" }}
                        variant="contained"
                        onClick={handleAdd}
                    >
                        <span style={{ color: "white" }} >
                            Add
                        </span>
                    </Button>
                </div>

            </div>
        </Dialog>
    );
};

export default AddItemDialog;
