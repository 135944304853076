/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DoneAllIcon from "@mui/icons-material/DoneAll";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { TbReportMoney } from "react-icons/tb";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import { Grid } from "@mui/material";
import { RiEdit2Line, RiPrinterCloudLine } from "react-icons/ri";
import { Link } from "react-router-dom";

function RevenueLeakage({ data }) {
  return (
    <Card sx={{ marginTop: "10px" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Revenue Leakage
        </MDTypography>
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <Link to="/order-summary/modified">
              <TimelineItem
                color="secondary"
                icon={<RiEdit2Line />}
                title="Bills Modified"
                dateTime={data.billsModified ?? 0}
              />
            </Link>
            <Link to="/order-summary/waivedoff">
              <TimelineItem
                color="secondary"
                icon={<TbReportMoney />}
                title="Waived Off"
                dateTime={data.totalWaivedOff ?? 0}
              />
            </Link>
            <TimelineItem
              color="secondary"
              icon={<RiPrinterCloudLine />}
              title="Bills Re-Printed"
              dateTime={data.billsRePrinted ?? 0}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <Link to="/order-summary/cancelled">
            <TimelineItem
              color="secondary"
              icon={<TbReportMoney />}
              title="Cancelled"
              dateTime={data.cancelledOrders ?? 0}
              />
              </Link>
            <TimelineItem
              color="secondary"
              icon={<TbReportMoney />}
              title="Modified KOTs"
              dateTime={data.kotsModified ?? 0}
            />
            <TimelineItem
              color="secondary"
              icon={<TbReportMoney />}
              title="Not used in bills"
              dateTime={data.settled ?? 0}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}></Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default RevenueLeakage;
