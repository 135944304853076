import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { inventory } from "providers/InventoryProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
import toast from "utils/toast";
import logger from "utils/logger";
import commonStyles from "styles.module.css";
import styles from './recipeStyle.module.css'
import MDInput from "components/MDInput";
import RecipeCard from "./RecipeCard";
import AddRecipeDialog from "./AddRecipeDialog";
import EditRecipeDialog from "./EditRecipeDialog";
import { admin } from "providers/AdminProvider";



function RecipeManagements() {

    const inventoryContext = useContext(inventory);
    const [search, setSearch] = useState("");
    const [editRecipeItem, setEditRecipeItem] = useState({})
    const [addRecipeDialogOpen, setAddRecipeDialogOpen] = useState(false);
    const [editRecipeDialogOpen, setEditRecipeDialogOpen] = useState(false);
    const { setLoading } = useContext(admin)

    useEffect(() => {
        (async function () {
            inventoryContext.fetchInventoryItems();
            const callFunction = inventoryContext.fetchRecipes();
            toast.promise(callFunction, {
                loading: "Fetching Data..",
                error: "Fetching Data Failed",
                success: "Data Refreshed Successfully"
            });
        })()
    }, []);

    return (
        <DashboardLayout>
            <Sidenav className="animate" routes={routes.inventory} />
            <DashboardNavbar />
            <div
                style={{

                }}
            >
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div style={{ maxWidth: "65%" }}>
                                        <MDTypography variant="h6" color="white">
                                            Recipe management
                                        </MDTypography>
                                        <MDInput
                                            name="search"
                                            type="text"
                                            size="small"
                                            label="Search"
                                            variant="filled"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "10px",
                                                width: "300px",
                                                maxWidth: "90%"
                                            }}

                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                                        <button
                                            className={commonStyles.secondary_button}
                                            onClick={() => {
                                                setAddRecipeDialogOpen(true);
                                            }}
                                        >
                                            Add Recipe
                                        </button>


                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    <div className={styles.cardContainer} style={{ padding: "20px" }}>
                                        {(inventoryContext?.recipes ?? [])
                                            .filter(recipe => {
                                                return recipe?.item?.name?.match(new RegExp(search?.replace(/\\/g, '\\\\'), 'i'))
                                            })
                                            .map((recipe) => {
                                                return (
                                                    <RecipeCard setEditRecipe={setEditRecipeItem} setEditRecipeDialogOpen={setEditRecipeDialogOpen} recipe={recipe} />
                                                );
                                            })}

                                    </div>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>

            </div>
            <AddRecipeDialog
                open={addRecipeDialogOpen}
                setOpen={setAddRecipeDialogOpen}
            />
            <EditRecipeDialog
                open={editRecipeDialogOpen}
                setOpen={setEditRecipeDialogOpen}
                recipe={editRecipeItem}
            />


        </DashboardLayout>
    );
}

export default RecipeManagements;
