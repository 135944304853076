import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton'
import React from 'react'
import CountUp from 'react-countup'

export default function NumberLoad({ loading, showRs, number=0 , variant, className, style}) {
    const Element = variant ?? 'b';
    return (
        <Typography component={variant ?? 'b'} variant={variant ?? 'b'} className={"d-flex "+className} style={{height: "30px",justifyContent:"center", display:"flex", ...(style, {})}}>
            {
                loading ? <Skeleton width={showRs ? 70 : 40} style={{marginTop:"-5px"}} /> :
                    <>
                        {showRs ? <Element>₹ </Element> : null}
                        <CountUp start={0} end={number} duration={1} delay={0}>
                            {({ countUpRef }) => (
                                <Element ref={countUpRef} />
                            )}
                        </CountUp>
                    </>
            }
            
        </Typography>
    )
}
