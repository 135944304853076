
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import styles from './AddUserDialog.module.css'

// Authentication layout components

// Images
import {
    Dialog, FormControl, InputLabel, MenuItem, Select
} from "@mui/material";
import { useEffect, useState } from "react";
import toast from "utils/toast";
import axios from "axios";
// import BasicLayout from "../../../components/";

function AddExpenseDialog({
    open,
    setOpen,
    fetchExpenses,
    expense,
    setExpense
}) {
    const [amount, setAmount] = useState(0);
    const [description, setDescription] = useState("");
    const [category, setCategory] = useState("");

    useEffect(()=>{
        if(expense?._id){
            console.log(expense)
            setAmount(expense.amount ??0);
            setDescription(expense.description ?? "");
            setCategory(expense.category ?? "");
        }
    },[expense])

    const saveHandler = async (e) => {
        e.preventDefault();
        try {
            let res;
            if(expense._id){
                res = await axios.put(
                    `/restaurants/${localStorage.getItem(
                        "restaurant"
                    )}/expense/${expense._id}`,
                    {
                        amount, description, category
                    },
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );
            }else{
                res = await axios.post(
                    `/restaurants/${localStorage.getItem(
                        "restaurant"
                    )}/expense`,
                    {
                        amount, description, category
                    },
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );

            }
            toast.promise(fetchExpenses(), {
                loading: "refreshing expenses",
                error: "refreshing failed",
                success: "Successfully Refreshed"
            });
            return res
        } catch (error) {
            toast.error(error?.response?.data?.message ?? "Network Connection failed", {
                autoClose: 2000,
            });
            throw new Error("Failed")
        } finally {
            setExpense({})
            setOpen(false)
        }
    }
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setExpense({})
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "400px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Add Expense
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox role="form">
                            <MDBox mb={2}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Category
                                    </InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        // sx={{ width: "100%"}}
                                        value={category}
                                        onChange={(e) => {
                                            setCategory(e.target.value)
                                        }}
                                        name="Category"
                                        variant="standard"
                                    >
                                        <MenuItem value={"Food Expense"}>Food Cost</MenuItem>
                                        <MenuItem value={"Labor Expense"}>Labor Cost</MenuItem>
                                        <MenuItem value={"Rent"}>Rent</MenuItem>
                                        <MenuItem value={"Utilities"}>Utilities</MenuItem>
                                        <MenuItem value={"Maintenance"}>Maintenance</MenuItem>
                                        <MenuItem value={"Cleaning Service"}>Cleaning Serice</MenuItem>
                                        <MenuItem value={"Technology Expense"}>Technology Expense</MenuItem>
                                        <MenuItem value={"Technology Expense"}>Technology Expense</MenuItem>
                                        <MenuItem value={"Transportation"}>Transportation</MenuItem>
                                        <MenuItem value={"Equipment Costs"}>Equipment Costs</MenuItem>
                                        <MenuItem value={"Other"}>Other</MenuItem>
                                    </Select>
                                </FormControl>
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Amount"
                                    name="amount"
                                    value={amount}
                                    onChange={(e) => setAmount(e.target.value)}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Description"
                                    name="description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>

                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={(e) => {
                                        const callFunction = saveHandler(e);
                                        toast.promise(callFunction, {
                                            loading: "loading",
                                            error: "Failed",
                                            success: "Updated Successfully"
                                        });
                                    }}
                                    disabled={amount == "" || description.trim() == "" || category == ""}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default AddExpenseDialog;
