import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import {
	Card,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Input,
	List,
	ListItem,
	ListItemButton,
	TextField,
} from "@mui/material";
import { restaurant } from "providers/RestaurantProvider";
import commonStyles from "styles.module.css";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import styles from './Settings.module.css'
import { Radio, Space, Tabs } from "antd";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ResetPassword from "./ResetPassword";
import UserDetails from "./UserDetails";
import TwoFactorAuth from "./TwoFactorAuth";

const Settings = () => {
	const restaurantContext = useContext(restaurant);

	const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
		useState(false);
	const [tabPosition, setTabPosition] = useState('left');
	const changeTabPosition = (e) => {
		setTabPosition(e.target.value);
	};
	useEffect(() => {
		function handleResize() {
		  if(window.innerWidth<768){
			  setTabPosition("top")
			}else{
				setTabPosition("left")
			}
		}
		handleResize();
		window.addEventListener('resize', handleResize);
	  }, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Sidenav className="animate" routes={routes.main} />
				<Card className={styles.settings_tab_container}>
				
							<MDBox
								mx={2}
								mt={-5}
								py={3}
								px={2}
								mb={3}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" color="white">
									Settings
								</MDTypography>
							</MDBox>

					<Tabs
						style={{ backgroundColor: "white" }}
						tabPosition={tabPosition}
						items={[
							{
								label: `User Details`,
								key: "mndkn",
								children: <UserDetails/>,
							},
							{
								label: `Security`,
								key: "jsa",
								children: <ResetPassword/>,
							},
							{
								label: `Two factor Authentication`,
								key: "ht",
								children: <TwoFactorAuth/>,
							},
						]}
					/>
				</Card>

		</DashboardLayout>
	);
};

export default Settings;
