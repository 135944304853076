/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import styles from './AddUserDialog.module.css'


// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import { Dialog, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { admin } from "providers/AdminProvider";
import axios from "axios";
import { restaurant as restaurantData } from "../../../providers/RestaurantProvider";
import logger from "utils/logger";
import toast from "utils/toast";
import { ClipLoader } from 'react-spinners'

// import BasicLayout from "../../../components/";

function EditUserDialog({ open, setOpen, editUser }) {
    const restaurantContext = useContext(restaurantData);
    const adminContext = useContext(admin);
    // const { editUser } = adminContext;
    const [user, setUser] = useState({
        name: "",
        email: "",
        password: "",
    });
    const [selectedRights, setSelectedRights] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const { restaurant, setRestaurant } = restaurantContext;
    const { rightsOptions } = adminContext;
    const handleRightChange = (event) => {
        const { value, checked } = event.target;
        if (checked) {
            setSelectedRights([...selectedRights, value]);
        } else {
            setSelectedRights(selectedRights.filter((right) => right !== value));
        }
    };
    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };
    const changeHandler = (e) => {
        const { name, value } = e.target;
        setUser((prevState) => ({ ...prevState, [name]: value }));
    };

    const validateForm = () => {
        if (user.name.trim() === "" ||
            user.email.trim() === "" ||
            // user.password.trim() === "" ||
            selectedRole.trim() === "") {
            return false
        }
        return true
    }

    useEffect(() => {
        if (editUser) {
            setUser({
                name: editUser.name,
                email: editUser.email,
                password: editUser.password,
            })
            setSelectedRights([...editUser.rights])
            setSelectedRole(editUser.role)
        }
    }, [editUser])

    const saveHandler = async (e) => {
        e.preventDefault();
        const data = {
            name: user.name,
            role: selectedRole,
            email: user.email,
            rights: selectedRights,
        };
        if (user.password) {
            if (user.password != editUser.password) {
                data["password"] = user.password;
            }
        }
        if (
            data.name?.trim() === "" ||
            data.email?.trim() === "" ||
            data.role?.trim() === "" ||
            data.password?.trim() === ""
        ) {
            return;
        }
        try {
            setIsLoading(true)
            const res = await axios.put(
                `/restaurants/staff/update/${editUser._id}`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            logger(res);
            if (res.status === 200) {
                const copy = { ...restaurant };
                for (let i = 0; i < copy.staff.length; i++) {
                    if (copy.staff[i]._id === res.data._id) {
                        copy.staff[i] = res.data;
                        break;
                    }
                }
                setRestaurant(copy);
                toast.success("Successfully updated", {
                    autoClose: 2000,
                });
                setOpen(false)
                navigate("/dashboard")
            }
            setIsLoading(false)
            return res;

        } catch (error) {
            logger(error);
            setIsLoading(false)
            toast.error(error?.response?.data?.message ?? "Network connection failed", {
                autoClose: 2000,
            });
        }
    };

    return (
        <>
            <Dialog
                open={open}
                onClose={() => { setOpen(false) }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <Card sx={{ width: "600px", maxWidth: "100%", }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"    
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={0}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Edit User
                        </MDTypography>
                        {/* <MDTypography display="block" variant="button" color="white" my={1}>
                            Enter your email and password to register
                        </MDTypography> */}
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput type="text" label="Name" name="name" value={user.name} onChange={changeHandler} variant="standard" fullWidth />
                                <MDInput
                                    name="email" value={user.email} onChange={changeHandler}
                                    type="email"
                                    label="Email"
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>

                            <MDBox className={styles.add_user_form_row} mb={2}>
                                <MDInput
                                    name="password" value={user.password} onChange={changeHandler}
                                    type="password"
                                    label="Password"
                                    variant="standard"
                                    fullWidth
                                />
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">Choose Role</InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        sx={{ width: "100%", height: "29px" }}
                                        name="role"
                                        variant="standard"
                                        placeholder="Choose Role"
                                        value={selectedRole}
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value={'CAPTAIN'}>CAPTAIN</MenuItem>
                                        <MenuItem value={"KITCHEN"}>KITCHEN</MenuItem>
                                        <MenuItem value={"CASHIER"}>CASHIER</MenuItem>
                                    </Select>
                                </FormControl>
                            </MDBox>

                            <MDBox display="flex" justifyContent={"center"} alignItems="center" ml={-1}>
                                <table style={{ width: "97%", borderCollapse: "collapse", fontSize: ".85rem" }}>
                                    <tr>
                                        <th style={{ textAlign: "left", height: "40px", borderBottom: "1px solid #DADDE0" }} colSpan={3}>Rights</th>
                                    </tr>
                                    {rightsOptions.map((r, index1) => {
                                        return (
                                            <tr>
                                                <th style={{ textAlign: "left" }}>{r.label}</th>
                                                {r.fields.map((f, index2) => {
                                                    return (
                                                        <td>
                                                            <div style={{ display: "flex", gap: "5px", alignItems: "center" }}>
                                                                <Checkbox onChange={handleRightChange}
                                                                    id={index1+"r"+index2}
                                                                    checked={selectedRights.includes(f.value)}
                                                                    value={f.value} />
                                                                <label htmlFor={index1+"r"+index2} style={{ maxWidth: "155px" }}>{f.label}</label>
                                                            </div>
                                                        </td>)
                                                })}
                                            </tr>
                                        )
                                    })}

                                </table>
                            </MDBox>
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" onClick={(e)=>{
                                    const callFunction = saveHandler(e);
                                    toast.promise(callFunction, {
                                        loading: "Saving Changes...",
                                        error: "Saving Changes Failed",
                                        success: "Saved Changes Successfully"
                                    });
                                }} disabled={!validateForm() || isLoading} color="info" fullWidth>
                                    Save <ClipLoader className="ms-1" loading={isLoading} size={10} color="white" />
                                </MDButton>
                            </MDBox>

                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog >
        </>
    );
}

export default EditUserDialog;
