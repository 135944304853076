/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "./Footer";
import OrdersOverview from "./components/OrdersOverview";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import exportToExcel from "utils/exportToExcel";
import { restaurant } from "providers/RestaurantProvider";
import { RiArrowDownSLine, RiBarChart2Line, RiCoinLine, RiLineChartFill, RiPieChart2Line, RiRestaurantFill, RiTakeawayFill, RiUser3Fill } from "react-icons/ri";
import { BiLineChartDown } from "react-icons/bi";
import { RiRefreshLine } from "react-icons/ri";
import RevenueLeakage from "./components/RevenueLeakage";
import ordinal from "ordinal";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import { Accordion, AccordionDetails, AccordionSummary, BottomNavigation, BottomNavigationAction, Skeleton } from "@mui/material";
import { Progress } from "antd";
import DefaultDoughnutChart from "examples/Charts/DoughnutCharts/DefaultDoughnutChart";
import DataTable from "examples/Tables/DataTable";
import SalesAccordion from "./MobileComponents/SalesAccordion";
import OnlineAccordion from "./MobileComponents/OnlineAccordion";
import PaymentMethodItem from "./MobileComponents/PaymentMethodItem";
import styles from './dashboardmobile.module.css'
import NumberLoad from "components/NumberLoad/NumberLoad";
import ChartLoader from "components/Loader/ChartLoader";
import AccordionLoader from "components/Loader/AccordionLoader";
import MDButton from "components/MDButton";

export default function DashboardMobile() {
    const restaurantContext = useContext(restaurant);
    const [loading, setLoading] = useState(false)

    const [startDate, setStartDate] = useState(
        new Date(new Date().setHours(0, 0, 0, 0))
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setHours(23, 59, 59, 999))
    );
    const [bottomNav, setBottomNav] = useState(0)
	const [expenses, setExpenses] = useState([])

    const [swiggyLoading, setSwiggyLoading]=useState(false)


    const [orders, setOrders] = useState([]);
    const [swiggyOrders, setSwiggyOrders]= useState({
        orders:[],
        total:0,
        minOrder:0,
        maxOrder:0,
        orderAvg:0
        
    })
    const fetchOrders = async () => {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const tomorrow = new Date(today);
        tomorrow.setDate(tomorrow.getDate() + 1);
        setLoading(true)
        console.log(startDate, endDate)
        try {
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/orders/timeRange?from=${startDate}&to=${endDate}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            if ((res.data ?? []).length == 0) {
                toast.error("No data available")
            }

            setOrders(res.data);

            return res
        } catch (err) {
            logger(err);
            toast.error(
                err?.response?.data?.message ?? "Network connection failed"
            );
        } finally {
            setLoading(false)
        }
    };
    	const fetchExpense = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/expense/byCategory?from=${startDate}&to=${endDate}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setExpenses(res.data)
			return res;
		} catch (err) {
			logger(err);
			toast.error(
				err?.response?.data?.message ?? "Network connection failed"
			);
			throw new Error("Failed")
		} finally {
			setLoading(false)
		}
	};
    const [refresh, setRefresh] = useState(false)

    const fetchSwiggyOrders=async ()=>{
        setSwiggyLoading(true)
        return axios.get(
            `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/onlineOrders/swiggy?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            ).then(res=>{
                console.log(res.data);
                toast.success("Swiggy Orders Refreshed")
                setSwiggyOrders(res.data);
                setSwiggyLoading(false)
            }).catch(err=>{
                console.log(err)
                setSwiggyLoading(false)
            })
    }

    useEffect(()=>{
        fetchSwiggyOrders()
    }, [startDate, endDate])

    useEffect(() => {
        const callFunction1 = fetchOrders();
		const callFunction2 = fetchExpense();
		toast.promise(Promise.all([callFunction1, callFunction2]), {
			loading: "Fetching Dashboard Data",
			error: "Fetching Data Failed",
			success: "Dashboard Refreshed Successfully"
		});

    }, [startDate, endDate, refresh]);
    let totalCashCollected = 0;
    for (let ord of orders) {
        totalCashCollected += ord?.payment?.customerPaid ?? 0
    }
    let otherPayments = {}
    const renderPaymentMethodBarChart = () => {
        const paymentMethodBarChartData = {
            labels: ["Cash", "Card", "Others", "Due"],
            datasets: [{
                label: "Payment Method",
                data: [0, 0, 0, 0, 0],
            }]
        };
        otherPayments = {}

        for (let ord of orders) {
            if (ord.payment?.method === "Cash") {
                paymentMethodBarChartData.datasets[0].data[0] += ord?.payment?.customerPaid ?? 0;
            } else if (ord.payment?.method === "Card") {
                paymentMethodBarChartData.datasets[0].data[1] += ord?.payment?.customerPaid ?? 0;
            } else if (ord.payment?.method === "Part") {
                ord.payment.parts.forEach((payment) => {
                    if (payment?.method === "Cash") {
                        paymentMethodBarChartData.datasets[0].data[0] += payment.amount ?? 0;
                    } else if (payment?.method === "Card") {
                        paymentMethodBarChartData.datasets[0].data[1] += payment.amount ?? 0;
                    } else if (payment?.method === "Other") {
                        paymentMethodBarChartData.datasets[0].data[2] += payment.amount ?? 0;
                        const keyName = payment.otherMethod ?? "UPI";
                        otherPayments[keyName] = (otherPayments[keyName] ?? 0) + payment.amount ?? 0;
                    } else {
                        paymentMethodBarChartData.datasets[0].data[3] += payment.amount ?? 0;
                    }
                })
            } else if (ord.payment?.method === "Other") {
                paymentMethodBarChartData.datasets[0].data[2] += ord?.payment?.customerPaid ?? 0;
                otherPayments[ord?.payment?.otherMethod ?? "UPI"] = (otherPayments[ord?.payment?.otherMethod ?? "UPI"] ?? 0) + ord?.payment?.customerPaid ?? 0;
            } else {
                paymentMethodBarChartData.datasets[0].data[3] += ord?.payment?.customerPaid ?? 0;
            }
        }

        logger(paymentMethodBarChartData);
        return paymentMethodBarChartData;
    };

    const getOrdersOverview = () => {
        let ordersOverview = {
            completed: 0,
            cancelled: 0,
            settled: 0,
            running: 0,
        };
        for (let ord of orders) {
            ordersOverview[ord.status]++;
            if (ord.status === 'settled') {
                ordersOverview["completed"]++;
            }
        }
        return ordersOverview;
    };

    const getSortedItemsBySoldPrice = () => {
        const items = {};

        for (let ord of orders) {
            if(ord.status!="settled"){
                continue;
            }
            for (let kot of ord.kots) {
                for (let item of kot.items) {
                    if (items?.[item?.item?._id]) {
                        items[item.item._id].sold += item.item.price * item.quantity;
                        items[item.item._id].quantity += item.quantity;
                    } else {
                        items[item.item?._id] = {
                            name: item.item?.name,
                            price: item.item?.price,
                            quantity: item?.quantity,
                            sold: item.item?.price * item.quantity,
                        };
                    }
                }
            }
        }

        logger("sort items", items);

        const sortedItems = Object.values(items).sort((a, b) => {
            return b.sold - a.sold;
        });

        logger(sortedItems);

        return sortedItems;
    };

    const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
        (acc, item) => {
            return acc + item.value;
        },
        0
    );

    const totalSales =
        orders.reduce((acc, ord) => {
            if (ord.status === "settled") {
                return (
                    acc +
                    ord.kots?.reduce((acc, kot) => {
                        return acc + kot?.total;
                    }, 0) ?? 0
                );
            } else {
                return (
                    acc + 0
                );
            }
        }, 0) *
        (1 + totalTaxValue / 100);
    const netSales = orders.reduce((acc, ord) => {
        if (ord.status === "settled") {
            return (
                acc +
                ord.kots?.reduce((acc, kot) => {
                    return acc + kot?.total;
                }, 0) ?? 0
            );
        } else {
            return (
                acc + 0
            );
        }
    }, 0);
    const totalOrders = orders.length;
    const totalCustomers = orders.reduce((acc, ord) => {
        const personCount = ord.personCount ?? 1;
        const kotPersonCount = new Set();
        ord.kots?.forEach((kot) => {
            const key = kot?.userData?.phone
                ? `${kot.userData.name}-${kot.userData.phone}`
                : kot.user;
            kotPersonCount.add(key);
        });
        return acc + Math.max(personCount, kotPersonCount.size);
    }, 0);


    const totalTax = netSales * (totalTaxValue / 100);



    const takeAwayTables = [], dineInTables = [];
    restaurantContext.restaurant?.sections
        .map(section => {
            if (section.type === 'takeaway') {
                section?.tables.map((table) => {
                    takeAwayTables.push(table.name)
                });
            } else if (section.type === "dinein") {
                section?.tables.map((table) => {
                    dineInTables.push(table.name)
                });
            }

        })

    let takeAwayOrders = [],
        dineInOrders = [];

    let minDineInOrder = null,
        maxDineInOrder = null,
        minTakeAwayOrder = null,
        maxTakeAwayOrder = null,
        totalRoundOff = 0,
        dineInTotal = 0,
        takeAwayTotal = 0,
        billsRePrinted = 0,
        kotsModified = 0,
        totalWaivedOff = 0,
        cancelledOrders = 0,
        billsModified = 0,
        totalCharges = 0,
        totalDiscount = 0,
        maxDiscount = 0,
        orderTotal = 0,
        totalSettledOrders = 0;

    let discountByDay = {};
    let ordersByDay = {}, ordersByTime = {};

    function daysBetweenDates(date1, date2) {
        // Convert both dates to milliseconds
        const date1Ms = date1.getTime();
        const date2Ms = date2.getTime();

        // Calculate the difference in milliseconds
        const differenceMs = Math.abs(date1Ms - date2Ms);

        // Convert the difference to days
        const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

        return daysDifference;
    }

    let discounts = [];
    orders.forEach((ord) => {
        if (ord.billPrinted > 1) {
            billsRePrinted += ord.billPrinted - 1;
        }
        if (ord.status === "cancelled") {
            cancelledOrders++;
        }
        if (ord.status === "waivedOff") {
            totalWaivedOff++;
        }
        if (ord.status === "settled") {
            totalSettledOrders++;
            totalCharges += (ord.deliveryCharge ?? 0) + (ord.containerCharge ?? 0)
            if (ord.discount?.amount) {
                discounts.push({ ...ord.discount, date: ord.createdAt });
                if (!discountByDay[new Date(ord.createdAt).toLocaleDateString()]) {
                    discountByDay[new Date(ord.createdAt).toLocaleDateString()] = {
                        amount: 0,
                        date: new Date(ord.createdAt),
                    };
                }

                discountByDay[new Date(ord.createdAt).toLocaleDateString()] = {
                    amount:
                        discountByDay[new Date(ord.createdAt).toLocaleDateString()].amount +
                        ord.discount.amount,
                    date: new Date(ord.createdAt),
                };

                totalDiscount += ord.discount.amount ?? 0;
                if (maxDiscount < ord.discount.amount) {
                    maxDiscount = ord.discount.amount;
                }
            }
            if (ord.billModifiedBy) {
                billsModified++;
            }
            orderTotal =
                ord.kots?.reduce((acc, kot) => {
                    if (kot?.kotModifiedBy) {
                        kotsModified++;
                    }
                    return acc + kot?.total;
                }, 0) ?? 0;


            if (!ordersByDay[new Date(ord.createdAt).toLocaleDateString()]) {
                ordersByDay[new Date(ord.createdAt).toLocaleDateString()] = {
                    amount: 0,
                    date: new Date(ord.createdAt),
                };
            }

            ordersByDay[new Date(ord.createdAt).toLocaleDateString()] = {
                amount:
                    ordersByDay[new Date(ord.createdAt).toLocaleDateString()].amount +
                    orderTotal,
                date: new Date(ord.createdAt),
            };
            if (!ordersByTime[new Date(ord.createdAt).getHours()]) {
                ordersByTime[new Date(ord.createdAt).getHours()] = {
                    amount: 0,
                    date: new Date(ord.createdAt),
                };
            }

            ordersByTime[new Date(ord.createdAt).getHours()] = {
                amount:
                    ordersByTime[new Date(ord.createdAt).getHours()].amount +
                    orderTotal,
                date: new Date(ord.createdAt),
            };

            if (ord?.payment?.customerPaid < orderTotal) {
                totalRoundOff +=
                    orderTotal - (ord.discount?.amount ?? 0) - (ord?.payment?.customerPaid ?? 0);
            }
            if (takeAwayTables.includes(ord?.table?.name)) {
                if (minTakeAwayOrder == null) {
                    minTakeAwayOrder = orderTotal;
                } else {
                    minTakeAwayOrder = Math.min(minTakeAwayOrder, orderTotal);
                }
                if (maxTakeAwayOrder == null) {
                    maxTakeAwayOrder = orderTotal;
                } else {
                    maxTakeAwayOrder = Math.max(maxTakeAwayOrder, orderTotal);
                }
                takeAwayTotal += orderTotal;
                takeAwayOrders.push({ ...ord, total: orderTotal });
            } else if (dineInTables.includes(ord?.table?.name)) {
                if (minDineInOrder == null) {
                    minDineInOrder = orderTotal;
                } else {
                    minDineInOrder = Math.min(minDineInOrder, orderTotal);
                }
                if (maxDineInOrder == null) {
                    maxDineInOrder = orderTotal;
                } else {
                    maxDineInOrder = Math.max(maxDineInOrder, orderTotal);
                }
                dineInTotal += orderTotal;
                dineInOrders.push({ ...ord, total: orderTotal });
            }
        }
    });

    const renderDailySalesLineChart = () => {

        let labels = [],
            data = [];
        if (daysBetweenDates(startDate, endDate) > 1) {
            labels = Object.values(ordersByDay)
                .sort((a, b) => {
                    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                })
                .map((item) => ordinal(item.date.getDate()));
            data = Object.values(ordersByDay).sort((a, b) => {
                return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
            }).map((item) => item.amount);
        } else {
            labels = [
                "12-03",
                "03-06",
                "06-09",
                "09-12",
                "12-03",
                "03-06",
                "06-09",
                "09-12",
            ];
            data = [0, 0, 0, 0, 0, 0, 0, 0];
            for (let d of Object.values(ordersByTime)) {
                const date = new Date(d.date);
                console.log(new Date(d.date).toLocaleTimeString(), "timeAndDate")
                if (date.getHours() < 3) {
                    data[0] += d.amount;
                } else if (date.getHours() < 6) {
                    data[1] += d.amount;
                } else if (date.getHours() < 9) {
                    data[2] += d.amount;
                } else if (date.getHours() < 12) {
                    data[3] += d.amount;
                } else if (date.getHours() < 15) {
                    data[4] += d.amount;
                } else if (date.getHours() < 18) {
                    data[5] += d.amount;
                } else if (date.getHours() < 21) {
                    data[6] += d.amount;
                } else {
                    data[7] += d.amount;
                }
            }

        }
        const dailySalesLineChartData = {
            labels,
            datasets: [
                {
                    label: "Today",
                    color: "dark",
                    data,
                }
            ]
        };
        return dailySalesLineChartData;
    };

    const getDiscountChartData = () => {
        let labels = [],
            data = [];

        if (daysBetweenDates(startDate, endDate) > 1) {
            labels = Object.values(discountByDay)
                .sort((a, b) => {
                    return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
                })
                .map((item) => ordinal(item.date.getDate()));
            data = Object.values(discountByDay).map((item) => item.amount);
        } else {
            labels = [
                "3am",
                "6am",
                "9am",
                "12pm",
                "3pm",
                "6pm",
                "9pm",
                "12am",
            ];
            data = [0, 0, 0, 0, 0, 0, 0, 0];
            for (let d of Object.values(discountByDay)) {
                const date = new Date(d.date);
                if (date.getHours() < 3) {
                    data[0] += d.amount;
                } else if (date.getHours() < 6) {
                    data[1] += d.amount;
                } else if (date.getHours() < 9) {
                    data[2] += d.amount;
                } else if (date.getHours() < 12) {
                    data[3] += d.amount;
                } else if (date.getHours() < 15) {
                    data[4] += d.amount;
                } else if (date.getHours() < 18) {
                    data[5] += d.amount;
                } else if (date.getHours() < 21) {
                    data[6] += d.amount;
                } else {
                    data[7] += d.amount;
                }
            }
        }
        const chartData = {
            labels: labels,
            datasets: [{
                label: "Today",
                data: data,
            }],
        };
        return chartData;
    };

    const handleExport = () => {
        exportToExcel(
            "Outlet details",
            [
                [
                    "Name",
                    "Orders",
                    "Sales",
                    "Net Sales",
                    "Tax",
                    "Discount",
                    "Modified",
                    "Re-Printed",
                    "Waived Off",
                    "Round Off",
                    "Charges",
                ],
                [
                    restaurantContext.restaurant.name,
                    orders.length,
                    totalSales,
                    netSales,
                    totalTax,
                    totalDiscount,
                    billsModified,
                    billsRePrinted,
                    totalWaivedOff,
                    totalRoundOff,
                    totalCharges,
                ],
            ],
            startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
        );
    };

    const expenseTotal= expenses.reduce((acc, it)=>acc+it.total, 0)



    return (
        <>
            <Sidenav className="animate" routes={routes.main} />
            <DashboardLayout padding={2}>
                <DashboardNavbar
                    showDateFilter
                    showExport={false}
                    handleExport={handleExport}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    startDate={startDate}
                    endDate={endDate}
                />
                {
                    bottomNav === 0 &&
                    <div className={styles.dashboard_main}>
                        <div className={styles.dashboard_row}>
                            <MDButton disabled={loading} style={{boxShadow: "0px 3px 5px 0px rgb(214, 214, 214)"}} onClick={() => setRefresh(prev => !prev)} className={styles.dashboardCardDetails}>
                            <div style={{display:'flex', alignItems:"center"}} >
                                <RiRefreshLine style={{ fontSize: '1.3rem', marginRight:"5px" }} /> Refresh
                            </div>
                            </MDButton>
                            <div className={styles.dashboardCardDetails}>
                                <div className={styles.dashboardCardItem}>
                                    <h4>Total Sales</h4>
                                    <span>Orders</span>
                                    <NumberLoad number={totalSettledOrders} loading={loading} />
                                    <span>Estimated Total</span>
                                    <NumberLoad number={totalSales} loading={loading} showRs />
                                </div>

                            </div>
                            <div className={styles.dashboardCardDetails}>

                                <div className={styles.dashboardCardItem}>
                                    <h4>Net Sales</h4>
                                    <span>Orders</span>
                                    <NumberLoad number={totalSettledOrders} loading={loading} />
                                    <span>Estimated Total</span>
                                    <NumberLoad number={netSales} loading={loading} showRs />
                                </div>
                                <div className={styles.dashboardCardItem}>
                                    <h4>Online</h4>
                                    <span>Orders</span>
                                    <NumberLoad number={swiggyOrders?.orders?.length ?? 0} loading={swiggyLoading} />
                                    <span>Estimated Total</span>
                                    <NumberLoad number={swiggyOrders?.total ?? 0} loading={swiggyLoading} showRs />
                                </div>
                            </div>
                            <div className={styles.dashboardCardDetails}>
                                <div className={styles.dashboardCardItem}>
                                    <h4>DineIn</h4>
                                    <span>Orders</span>
                                    <NumberLoad number={dineInOrders.length} loading={loading} />
                                    <span>Estimated Total</span>
                                    <NumberLoad number={dineInTotal} loading={loading} showRs />
                                </div>
                                <div className={styles.dashboardCardItem}>
                                    <h4>Takeaway</h4>
                                    <span>Orders</span>
                                    <NumberLoad number={takeAwayOrders.length} loading={loading} />
                                    <span>Estimated Total</span>
                                    <NumberLoad number={takeAwayTotal} loading={loading} showRs />
                                </div>
                            </div>
                        </div>
                        <div className={styles.dashboard_row}>
                            {loading ?
                                <div className={styles.dashboard_chart_card}>
                                    <div className={styles.dashboard_chart_card_header}>
                                        <b>Order Overview</b>
                                    </div>
                                    <AccordionLoader></AccordionLoader>
                                </div> :
                                <div className={styles.dashboard_chart_card}>
                                    <OrdersOverview data={getOrdersOverview()} />
                                </div>}
                        </div>
                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Sales</b>
                                        </div>
                                        <ChartLoader></ChartLoader>
                                    </div>
                                    :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Sales</b>
                                        </div>
                                        <div className={styles.dashboard_chart_card_header_center}>
                                            <h2>₹{(totalSales?.toFixed(2))}</h2>
                                        </div>
                                        <div className={styles.dashboard_chart_card_header_center}>
                                            <span>{totalSettledOrders} Orders</span>
                                        </div>
                                        <VerticalBarChart
                                            titleHide={true}
                                            hideBar={true}
                                            title=" "
                                            description=""
                                            chart={renderDailySalesLineChart()}
                                        />

                                        <SalesAccordion
                                            heading={"Net Sales"}
                                            orderDetails={{
                                                noOfOrders: totalSettledOrders,
                                                min: minDineInOrder,
                                                max: maxDineInOrder,
                                                avg: (netSales / totalOrders).toFixed(2),
                                                discount: 0,
                                                tax: 0,
                                                total: netSales,
                                            }}
                                            icon={<RiRestaurantFill />}
                                        />
                                        <SalesAccordion
                                            heading={"DineIn"}
                                            orderDetails={{
                                                noOfOrders: dineInOrders.length,
                                                min: minDineInOrder,
                                                max: maxDineInOrder,
                                                avg: (dineInTotal / dineInOrders.length).toFixed(2),
                                                discount: 0,
                                                tax: (dineInTotal * (totalTaxValue / 100)).toFixed(2),
                                                total: dineInTotal + dineInTotal * (totalTaxValue / 100),
                                            }}
                                            icon={<RiRestaurantFill />}
                                        />
                                        <SalesAccordion
                                            heading={"Delivery"}
                                            orderDetails={{}}
                                            icon={<RiTakeawayFill></RiTakeawayFill>}
                                        />
                                        <SalesAccordion
                                            heading={"Takeaway"}
                                            orderDetails={{
                                                noOfOrders: takeAwayOrders.length,
                                                min: minTakeAwayOrder,
                                                max: maxTakeAwayOrder,
                                                avg: (takeAwayTotal / takeAwayOrders.length).toFixed(2),
                                                discount: 0,
                                                tax: (takeAwayTotal * (totalTaxValue / 100)).toFixed(2),
                                                total: takeAwayTotal + takeAwayTotal * (totalTaxValue / 100),
                                            }}
                                            icon={<RiUser3Fill />}
                                        />
                                    </div>
                            }

                        </div>
                        <div className={styles.dashboard_row}>
                            <div className={styles.dashboard_chart_card}>
                                <div className={styles.dashboard_chart_card_header} style={{ marginBottom: "20px" }}>
                                    <b>Taxes</b>
                                </div>
                                {
                                    restaurantContext.restaurant.taxes.map((item, index) => {
                                        return <div className={styles.dashboard_item + " " + styles.outside} >
                                            <div className={styles.dashboard_item_left}>

                                                <div className={styles.dashboard_item}>
                                                    <span>{item.name}</span>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                            <div className={styles.dashboard_item_right}>
                                                <NumberLoad number={takeAwayTotal} loading={loading} showRs />
                                            </div>
                                        </div>
                                    })}

                                <div className={styles.dashboard_item + " " + styles.outside}>
                                    <div className={styles.dashboard_item_left}>

                                        <div className={styles.dashboard_item}>
                                            <span>Total</span>
                                        </div>
                                    </div>
                                    <div>
                                    </div>
                                    <div className={styles.dashboard_item_right}>
                                        <NumberLoad number={totalTax} loading={loading} showRs />
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={styles.dashboard_row}>
                            {
                                (swiggyLoading) ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Online Orders</b>
                                        </div>
                                        <ChartLoader></ChartLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Online Orders</b>
                                        </div>
                                        <DefaultDoughnutChart
                                            title=" "
                                            chart={{
                                                labels: ["Swiggy", "Zomato", "Others"],
                                                datasets: {
                                                    label: "Total",
                                                    backgroundColors: ["secondary", "error",
                                                    "primary"
                                                ],
                                                    data: [swiggyOrders.total, 0, 0],
                                                },
                                            }}
                                        />

                                        <OnlineAccordion heading={"swiggy"} data={swiggyOrders} />
                                        <OnlineAccordion heading={"Zomato"} />
                                        {/* <OnlineAccordion heading={"Other"} /> */}


                                    </div>}
                        </div>

                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Payment Methods</b>
                                        </div>
                                        <ChartLoader></ChartLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Payment Methods</b>
                                        </div>
                                        <HorizontalBarChart
                                            title=" "
                                            description=""
                                            chart={renderPaymentMethodBarChart()}
                                        />
                                        <PaymentMethodItem
                                            name={"cash"}
                                            price={renderPaymentMethodBarChart().datasets[0].data[0].toFixed(2)}
                                        />
                                        <PaymentMethodItem
                                            name={"Card"}
                                            price={renderPaymentMethodBarChart().datasets[0].data[1].toFixed(2)}
                                        />
                                        <PaymentMethodItem
                                            name={"Other Total"}
                                            price={renderPaymentMethodBarChart().datasets[0].data[2].toFixed(2)}
                                        />
                                        {
                                            Object.keys(otherPayments ?? {}).map((payment) => {
                                                return <PaymentMethodItem
                                                    name={"Other(" + payment + ")"}
                                                    price={otherPayments?.[payment]?.toFixed(2) ?? 0}
                                                />
                                            })
                                        }

                                        <PaymentMethodItem
                                            name={"Due"}
                                            price={renderPaymentMethodBarChart().datasets[0].data[3].toFixed(2)}
                                        />
                                        <PaymentMethodItem
                                            name={"Total"}
                                            price={totalCashCollected?.toFixed(2)}
                                        />
                                    </div>}
                        </div>


                    </div>
                }
                {
                    bottomNav === 1 &&
                    <div className={styles.dashboard_main}>

                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Revenue Leakage</b>
                                        </div>
                                        <AccordionLoader></AccordionLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <RevenueLeakage
                                            data={{
                                                billsRePrinted,
                                                kotsModified,
                                                billsModified,
                                                cancelledOrders,
                                                totalWaivedOff,
                                            }}
                                        />

                                    </div>}
                        </div>
                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Sales Numbers</b>
                                        </div>
                                        <AccordionLoader></AccordionLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header} style={{ marginBottom: "20px" }}>
                                            <b>Sales Numbers</b>
                                        </div>

                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<RiArrowDownSLine />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className={styles.dashboard_item}>
                                                    <div className={styles.dashboard_item_left}>
                                                        <div className={styles.dashboard_item_icon + " " + styles.orange}>
                                                            <RiLineChartFill />
                                                        </div>
                                                        <div className={styles.dashboard_item_head}>
                                                            <b>Most Sold</b>
                                                        </div>
                                                    </div>
                                                    <div className={styles.dashboard_item_right}>
                                                        <b></b>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={styles.dashboard_item + " " + styles.inside} style={{ padding: "0px" }}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {
                                                                    Header: "Name",
                                                                    accessor: "name",
                                                                },
                                                                {
                                                                    Header: "Total Quantity",
                                                                    accessor: "quantity",
                                                                },
                                                                {
                                                                    Header: "Total Sold",
                                                                    accessor: "sold",
                                                                },
                                                            ],
                                                            rows: (getSortedItemsBySoldPrice() || []).slice(0, 5),
                                                        }}
                                                        showTotalEntries={false}
                                                        isSorted={false}
                                                        noEndBorder
                                                        entriesPerPage={false}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>

                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<RiArrowDownSLine />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className={styles.dashboard_item}>
                                                    <div className={styles.dashboard_item_left}>
                                                        <div className={styles.dashboard_item_icon + " " + styles.orange}>
                                                            <BiLineChartDown />
                                                        </div>
                                                        <div className={styles.dashboard_item_head}>
                                                            <b>Least Sold</b>
                                                        </div>
                                                    </div>
                                                    <div className={styles.dashboard_item_right}>
                                                        <b></b>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <div className={styles.dashboard_item + " " + styles.inside} style={{ padding: "0px" }}>
                                                    <DataTable
                                                        table={{
                                                            columns: [
                                                                {
                                                                    Header: "Name",
                                                                    accessor: "name",
                                                                },
                                                                {
                                                                    Header: "Total Quantity",
                                                                    accessor: "quantity",
                                                                },
                                                                {
                                                                    Header: "Total Sold",
                                                                    accessor: "sold",
                                                                },
                                                            ],
                                                            rows: (getSortedItemsBySoldPrice() || []).slice(0).reverse().slice(0, 5),
                                                        }}
                                                        showTotalEntries={false}
                                                        isSorted={false}
                                                        noEndBorder
                                                        entriesPerPage={false}
                                                    />
                                                </div>
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>}
                        </div>


                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Discounts</b>
                                        </div>
                                        <ChartLoader></ChartLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Discounts</b>
                                        </div>
                                        <VerticalBarChart
                                            titleHide={true}
                                            hideBar={true}
                                            title=" "
                                            description=""
                                            chart={getDiscountChartData()}
                                        />

                                        <Accordion elevation={0}>
                                            <AccordionSummary
                                                expandIcon={<RiArrowDownSLine />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                            >
                                                <div className={styles.dashboard_item}>
                                                    <div className={styles.dashboard_item_left}>
                                                        <div className={styles.dashboard_item_icon}>
                                                            <RiCoinLine />
                                                        </div>
                                                        <div className={styles.dashboard_item_head}>
                                                            <b>Discounts</b>
                                                        </div>
                                                    </div>
                                                    <div className={styles.dashboard_item_right}>
                                                        <b>₹{totalDiscount?.toFixed(2)}</b>
                                                    </div>
                                                </div>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                                <PaymentMethodItem
                                                    name={"Average"}
                                                    price={(totalDiscount / totalOrders).toFixed(2)}
                                                />
                                                <PaymentMethodItem
                                                    name={"Maximum"}
                                                    price={maxDiscount}
                                                />
                                            </AccordionDetails>
                                        </Accordion>
                                    </div>}
                        </div>




                        <div className={styles.dashboard_row}>
                            {
                                loading ?
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header}>
                                            <b>Expense Tracker</b>
                                        </div>
                                        <AccordionLoader></AccordionLoader>
                                    </div> :
                                    <div className={styles.dashboard_chart_card}>
                                        <div className={styles.dashboard_chart_card_header} style={{ marginBottom: "20px"}}>
                                            <b>Expense tracker</b>
                                        </div>
                                        {
                                            expenses.map((exp, key)=>{
                                                return <div className={styles.dashboard_item + " " + styles.outside} >
                                                <div className={styles.dashboard_item_left}>
    
                                                    <div className={styles.dashboard_item}>
                                                        <span>{exp._id}</span>
                                                    </div>
                                                </div>
                                                <div>
                                                    <Progress style={{ width: "100px" }} percent={(exp.total/expenseTotal)*100} showInfo={false} />
                                                </div>
                                                <div className={styles.dashboard_item_right}>
                                                    <b>₹{exp.total}</b>
                                                </div>
                                            </div>
                                            })
                                        }
                                        

                                    </div>}
                        </div>
                    </div>
                }
                <div style={{ marginTop: "20px" }}>
                </div>

                <Footer />
                <div style={{ marginTop: "75px" }}></div>
                <BottomNavigation
                    sx={{
                        position: "fixed",
                        bottom: "0px",
                        left: "0px",
                        width: "100%",
                        height: "60px",
                        boxShadow: "0px 0px 10px 0px #B0A695",
                        backgroundColor: "white",
                        zIndex: 999
                    }}
                    showLabels
                    value={bottomNav}
                    onChange={(event, newValue) => {
                        setBottomNav(newValue);
                    }}
                >
                    <BottomNavigationAction label="Sales" icon={<RiBarChart2Line />} />
                    <BottomNavigationAction label="Operations" icon={<RiPieChart2Line />} />
                </BottomNavigation>
            </DashboardLayout>
        </>
    );
}

