import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import Header from "../../components/Header/Header";
import { Card, Grid, IconButton } from "@mui/material";
import vibrator from "../../utils/vibrator";
import CloseIcon from "@mui/icons-material/Close";
import OrderSummaryDetails from "./OrderSummaryDetails";
import MuiDrawer from "@mui/material/Drawer";
import commonStyles from "../../styles.module.css";
import {
	Button,
	Dialog,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import styles from "./OrderSummary.module.css";
import { FilterListRounded } from "@mui/icons-material";
import {
	CancelRounded,
	PrintRounded,
	Search,
	VisibilityRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { admin } from "providers/AdminProvider";
import { RiEdit2Fill } from "react-icons/ri";
import EditBillDialog from "./EditBillDialog";
import regexSearch from "utils/regexSearch";
import { restaurant } from "providers/RestaurantProvider";

const OrderSummary = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [editBillDialogOpen, setEditBillDialogOpen] = useState(false)
	const [orderId, setOrderId] = useState(null);
	const [showDetail, setShowDetail] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [orders, setOrders] = useState([]);
	const navigate = useNavigate();
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [isSearching, setIsSearching] = useState(false);
	const [searchQuery, setSearchQuery] = useState("");
	const [orderID, setOrderID] = useState("");
	const [order, setOrder] = useState({});
	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	const { setLoading } = useContext(admin)
	const restaurantContext = useContext(restaurant)

	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);

	let cancelReason = "";
	let password = "";

	const fetchOrders = async () => {
		setLoading(true)
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setLoading(false)
			setOrders(res.data);
			return res;
		} catch (error) {
			setLoading(false)
			logger(error);
			toast.error(error?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	const handleCancel = async () => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/${orderID}`,
				{
					status: "cancelled",
					cancelReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);


			fetchOrders();
			setConfirmDialogOpen(false);
			return res
		} catch (error) {
			logger(error);
			toast.error(error?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		const callFunction = fetchOrders();
		toast.promise(callFunction, {
			loading: "Fetching Order Summary",
			error: "Fetching Order Summary Failed",
			success: "Order Summary refreshed Successfully"
		});
	}, [startDate, endDate]);

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<Card sx={{ width: "400px", maxWidth: "100%" }}>
					<MDBox
						variant="gradient"
						bgColor="info"
						// borderRadius="lg"
						coloredShadow="success"
						sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
						mx={2}
						mt={-1}
						p={1}
						textAlign="center"
					>
						<MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
							Confirm cancellation
						</MDTypography>
					</MDBox>
					<MDBox pt={2} pb={3} px={3}>
						<MDBox component="form" role="form">
							<MDBox mb={2}>
								<MDInput
									type="password"
									label="Password"
									name="password"
									onChange={(e) => {
										password = e.target.value;
									}}
									variant="standard"
									fullWidth
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Cancel reason"
									onChange={(e) => {
										cancelReason = e.target.value;
									}}
									variant="standard"
									fullWidth
								/>
							</MDBox>

							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									onClick={() => {
										if (password === "st24632") {
											if (cancelReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											logger(orderID);
											const callFunction = handleCancel();
											toast.promise(callFunction, {
												loading: "Cancelling Order",
												error: "Cancelling Order Failed",
												success: "Order cancelled Successfully"
											});
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Save{" "}

								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</Dialog>
		);
	};

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.orderSummary} />
			<DashboardNavbar
			showDateFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
			/>
			<div
			>
				<div className={styles.topHeadingContainer}></div>
				<div className={styles.mainContainer}>
					<MDBox pt={6} pb={3}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card>
									<MDBox
										mx={2}
										mt={-3}
										py={3}
										px={2}
										variant="gradient"
										className="primary-bg"
										borderRadius="lg"
									>
										<MDTypography variant="h6" color="white">
											Order List
										</MDTypography>
									</MDBox>
									<MDBox pt={3}>
										{orders.length > 0 && (
											<DataTable
												onChange={(e) => {
													password = e.target.value;
												}} table={{
													columns: [
														{
															Header: "Order No",
															accessor: "orderNo",
														},
														{
															Header: "Order Type",
															accessor: "orderType",
														},
														{
															Header: "Status",
															accessor: "status",
														},
														{
															Header: "Payment Type",
															accessor: "paymentType",
														},
														{
															Header: "Bill Settled By",
															accessor: "billSettledBy",
														},
														{
															Header: "Bill Modified By",
															accessor: "billModifiedBy",
														},
														{
															Header: "Payment Modified By",
															accessor: "paymentModifiedBy",
														},
														{
															Header: "Customer Name",
															accessor: "userName",
														},
														{
															Header: "Customer Phone",
															accessor: "userPhone",
														},
														{
															Header: "Payment Type",
															accessor: "paymentMethod",
														},
														{
															Header: "My Amount",
															accessor: "myAmount",
														},
														{
															Header: "Tax",
															accessor: "tax",
														},
														{
															Header: "Discount",
															accessor: "discount",
														},
														{
															Header: "Grand Total",
															accessor: "grandTotal",
														},
														{
															Header: "Customer Paid",
															accessor: "customerPaid",
														},
														{
															Header: "Tip",
															accessor: "tip",
														},
														{
															Header: "Created",
															accessor: "createdAt",
														},
														{
															Header: "Actions",
															accessor: "actions",
														},
													],
													rows: orders.filter((order) => {
														return (regexSearch(order?.orderNumber, searchQuery))
													}
													).map((ord) => {
														let myAmount = ord.kots.reduce((acc, kot) => {
															return acc + kot.total;
														}, 0);
														return {
															orderNo: ord.orderNumber,
															orderType: (ord?.table?.type?.toUpperCase() ?? "DINEIN") + "(" + ord?.table?.name + ")",
															status: ord.status,
															paymentType:ord?.payment?.transactionNo ? `${ord?.payment?.method} (${ord?.payment?.transactionNo})`: ord?.payment?.method,
															billSettledBy: ord.billSettledBy?.name,
															billModifiedBy: ord.billModifiedBy?.name,
															paymentModifiedBy: ord.paymentModifiedBy?.name,
															userName: ord.user?.name,
															userPhone: ord.user?.phone,
															paymentMethod: ord.payment?.method || "Due",
															myAmount: myAmount,
															tax: (myAmount * (totalTaxValue / 100)).toFixed(2),
															discount: (ord.discount?.amount ?? 0).toFixed(2),
															grandTotal: (myAmount * 1.05).toFixed(2),
															customerPaid: ord.payment?.customerPaid ?? 0,
															tip: ord.payment?.tip ?? 0,
															createdAt: new Date(
																ord.createdAt
															).toLocaleString(),
															actions: (
																<div className={styles.actions}>
																	{
																		ord.status == "settled" ?
																			<RiEdit2Fill
																				style={{ cursor: "pointer" }}
																				onClick={() => {
																					setEditBillDialogOpen(true)
																					setOrder({ ...ord });
																				}}
																			></RiEdit2Fill> : null
																	}
																	<VisibilityRounded
																		onClick={() => {
																			vibrator.tap();
																			setIsDrawerOpen(true);
																			setOrder({ ...ord });
																		}}
																		style={{ cursor: "pointer" }}
																	/>
																	{ord.status !== "cancelled" && (
																		<CancelRounded
																			onClick={() => {
																				setOrderID(ord);
																				setConfirmDialogOpen(true);
																			}}
																		/>
																	)}
																</div>
															),
														};
													}),
												}}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												noEndBorder
												canSearch
											/>
										)}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>

					<ConfirmDialog />

					<React.Fragment>
						<MuiDrawer
							variant="temporary"
							anchor="right"
							open={isDrawerOpen}
							onClose={() => setIsDrawerOpen(false)}
							sx={{
								"& .MuiDrawer-paper": {
									width: "85%",
									maxWidth: "100%",
								},
								"& .MuiModal-backdrop": {
									backdropFilter: "blur(5px)",
								},
							}}
						>
							<div
								className={styles.details}
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										marginRight: "10px",
									}}
								></div>
								<OrderSummaryDetails order={order} />
							</div>
						</MuiDrawer>
					</React.Fragment>
				</div>
			</div>
			<EditBillDialog
				open={editBillDialogOpen}
				fetchOrders={fetchOrders}
				setOpen={setEditBillDialogOpen}
				order={order}
			/>
		</DashboardLayout>
	);
};

export default OrderSummary;
