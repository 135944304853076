/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect, useContext } from "react";

// react-router components
import { useLocation, Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
import { HiMenuAlt1 } from "react-icons/hi";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import { DatePicker, Space, Button } from "antd";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";

// Material Dashboard 2 React example components
import Breadcrumbs from "examples/Breadcrumbs";
import NotificationItem from "examples/Items/NotificationItem";
import { RiShareForwardBoxLine } from "react-icons/ri";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "examples/Navbars/DashboardNavbar/styles";

// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
} from "context";
// import { Button, Typography } from "@mui/material";
import { Select } from "antd";
import exportToExcel from "utils/exportToExcel";
import { restaurant } from "providers/RestaurantProvider";
import { sidebar } from "providers/SidebarProvider";

function DashboardNavbar({
  absolute,
  light,
  isMini,
  setStartDate,
  setEndDate,
  startDate,
  endDate,
  showDateFilter,
  handleExport,
  showExport,
}) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav,
    transparentNavbar,
    fixedNavbar,
    openConfigurator,
    darkMode,
  } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);
  const restaurantContext = useContext(restaurant);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
	 */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  useEffect(() => {
    if (window.innerWidth < 1200) {
      setMiniSidenav(dispatch, true);
    }
  }, []);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () =>
    setOpenConfigurator(dispatch, !openConfigurator);
  const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);

  // Render the notifications menu
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem
        icon={<Icon>podcasts</Icon>}
        title="Manage Podcast sessions"
      />
      <NotificationItem
        icon={<Icon>shopping_cart</Icon>}
        title="Payment successfully completed"
      />
    </Menu>
  );

  // Styles for the navbar icons
  const iconsStyle = ({
    palette: { dark, white, text },
    functions: { rgba },
  }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });
  const { RangePicker } = DatePicker;
  const [filterDate, setFilterDate] = useState("Today");
  const [options, setOptions] = useState([
    {
      value: "today",
      label: "Today",
    },
    {
      value: "yesterday",
      label: "Yesterday",
    },
    {
      value: "last7Day",
      label: "Last 7 Day",
    },
    {
      value: "last30Day",
      label: "Last 30 Day",
    },
    {
      value: "thisMonth",
      label: "This Month",
    },
    {
      value: "lastMonth",
      label: "Last Month",
    },
    {
      value: "customRange",
      label: "Custom Range",
    },
  ]);

  const todayDate = new Date(new Date().setHours(0, 0, 0, 0));
  const todayDateEnd = new Date(new Date().setHours(23, 59, 59, 999));

  const dates = {
    today: {
      startDate: todayDate,
      endDate: todayDateEnd,
    },
    yesterday: {
      startDate: new Date(
        new Date(todayDate).setDate(new Date().getDate() - 1)
      ),
      endDate: new Date(
        new Date(todayDateEnd).setDate(new Date().getDate() - 1)
      ),
    },
    last7Day: {
      startDate: new Date(
        new Date(todayDate).setDate(new Date().getDate() - 7)
      ),
      endDate: new Date(new Date(todayDateEnd)),
    },
    last30Day: {
      startDate: new Date(
        new Date(todayDate).setDate(new Date().getDate() - 30)
      ),
      endDate: new Date(new Date(todayDateEnd)),
    },
    thisMonth: {
      startDate: new Date(todayDate.getFullYear(), todayDate.getMonth(), 1),
      endDate: new Date(
        new Date(
          todayDateEnd.getFullYear(),
          todayDateEnd.getMonth() + 1,
          0
        ).setHours(23, 59, 59, 999)
      ),
    },
    lastMonth: {
      startDate: new Date(todayDate.getFullYear(), todayDate.getMonth() - 1, 1),
      endDate: new Date(
        new Date(
          todayDateEnd.getFullYear(),
          todayDateEnd.getMonth(),
          0
        ).setHours(23, 59, 59, 999)
      ),
    },
  };

  const handleDateChange = (data) => {
    if (data === "customRange") {
      let optionData = [...options];
      optionData[optionData.length - 1].label = (
        <div className="responsive-range-picker">
          <RangePicker
            disabledDate={(current) => {
              return current && current > new Date();
            }}
            onChange={handleRangeChange}
            style={{ border: "none", outline: "none" }}
            size="large"
          />
        </div>
      );
      setOptions([...optionData]);
    } else {
      setStartDate(dates[data].startDate);
      setEndDate(dates[data].endDate);
      let optionData = [...options];
      optionData[optionData.length - 1].label = "Custom Range";
      setOptions([...optionData]);
    }
    setFilterDate(data);
  };

  const handleRangeChange = (dateArr) => {
    const start = new Date(new Date(dateArr[0]).setHours(0, 0, 0, 0));
    const end = new Date(new Date(dateArr[1]).setHours(23, 59, 59, 999));
    setStartDate(start);
    setEndDate(end);
  };
  const { sidebarOpen, setSidebarOpen } = useContext(sidebar);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) =>
        navbar(theme, { transparentNavbar, absolute, light, darkMode })
      }
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          sx={{
            ...(theme) => navbarRow(theme, { isMini }),
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div>
            <HiMenuAlt1
              className="menu-icon"
              onClick={() => setSidebarOpen(true)}
            ></HiMenuAlt1>
            <div className="menu-breadcrumbs">
              <Breadcrumbs
                icon="home"
                title={route[route.length - 1]}
                route={route}
                light={light}
              />
            </div>
          </div>
          <div style={{ display: "flex", gap: "10px" }}>
            {showExport && (
              <Button
                onClick={handleExport}
                style={{ display: "flex", alignItems: "center", gap: "10px" }}
                size={"large"}
              >
                Export
                <RiShareForwardBoxLine />
              </Button>
            )}
            {showDateFilter && (
              <div>
                <Select
                  size={"large"}
                  defaultValue={filterDate}
                  value={filterDate}
                  onChange={handleDateChange}
                  style={{ minWidth: 150 }}
                  options={options}
                />
              </div>
            )}
          </div>
        </MDBox>
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
