import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { RiArrowDownSLine } from 'react-icons/ri'
import styles from '../dashboardmobile.module.css'

export default function SalesAccordion({heading, orderDetails, icon}) {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<RiArrowDownSLine />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={styles.dashboard_item}>
                    <div className={styles.dashboard_item_left}>
                        <div className={styles.dashboard_item_icon + " " + styles.orange}>
                            {icon}
                        </div>
                        <div className={styles.dashboard_item_head}>
                            <b>{heading}</b>
                            <span>{orderDetails.noOfOrders ?? 0} Orders</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.total ?? 0}</b>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.dashboard_item  + " " + styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>No of Orders</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>{orderDetails.noOfOrders}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + " " + styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Minimum</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.min ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + " " + styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Maximum</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.max ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + " "+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Average</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{(isNaN(orderDetails?.avg) ? 0 : orderDetails?.avg ) ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item +" "+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Discount</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.discount ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + " " +styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Tax</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.tax ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + " " + styles.inside} style={{marginTop:"20px"}}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Total</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{orderDetails.total}</b>
                    </div>
                </div>
            </AccordionDetails>
        </Accordion>
    )
}
