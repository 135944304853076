/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { DoneRounded, RoomServiceRounded } from "@mui/icons-material";
import logger from "utils/logger";
import { Tab, Tabs } from "@mui/material";

function OutletStatistics({ items, orderDetails }) {
  // logger("PopularItems.js", items);
  const [menu, setMenu] = useState(null);
  const [isMostSelected, setIsMostSelected] = useState(true);

  const openMenu = ({ currentTarget }) => setMenu(currentTarget);
  const closeMenu = () => setMenu(null);

  const renderMenu = (
    <Menu
      id="simple-menu"
      anchorEl={menu}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
    >
      <MenuItem onClick={closeMenu}>Action</MenuItem>
      <MenuItem onClick={closeMenu}>Another action</MenuItem>
      <MenuItem onClick={closeMenu}>Something else</MenuItem>
    </Menu>
  );

  return (
    <Card>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <MDBox>
          <MDTypography variant="h6" gutterBottom>
            Outlet Statistics
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox>
        <DataTable
          table={{
            columns: [
              {
                Header: "Orders",
                accessor: "orders",
              },
              {
                Header: "Sales",
                accessor: "sales",
              },
              {
                Header: "Net Sales",
                accessor: "netSales",
              },
              {
                Header: "Tax",
                accessor: "tax",
              },
              {
                Header: "Discount",
                accessor: "discount",
              },
              {
                Header: "Modified",
                accessor: "modified",
              },
              {
                Header: "Re-printed",
                accessor: "rePrinted",
              },
              {
                Header: "Waived Off",
                accessor: "waivedOff",
              },
              {
                Header: "Round Off",
                accessor: "roundOff",
              },
              {
                Header: "Charges",
                accessor: "charges",
              },
            ],
            rows: [{ 
				orders: orderDetails.orders ?? 0,
				sales:(orderDetails.totalSales)?.toFixed(2) ?? 0,
				netSales:orderDetails.netSales ?? 0,
				tax:(orderDetails.totalTax).toFixed(2) ?? 0,
				discount:orderDetails.totalDiscount,
				modified:orderDetails.kotsModified,
				rePrinted:orderDetails.billsRePrinted,
				waivedOff:orderDetails.totalWaivedOff,
				roundOff:orderDetails.roundOff,
				charges:orderDetails.totalCharges
			 }],
          }}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
          entriesPerPage={false}
        />
      </MDBox>
    </Card>
  );
}

export default OutletStatistics;
