import { sidebar } from 'providers/SidebarProvider';
import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'

export default function ProtectedComponent({ Component }) {
    const token = localStorage.getItem('token')
    const { sidebarOpen, setSidebarOpen } = useContext(sidebar);
    return (
        <div onClick={()=>{
            if(sidebarOpen){
                setSidebarOpen(false)
            }
        }} style={{minHeight:"100vh"}}>
            {
                token ?
                    <Component /> :
                    <Navigate to="/auth/login" />
            }
        </div>
    )
}
