import { toast as reactToast } from "react-hot-toast";

let myOptions={
	style: {
        background: 'green', // Set your desired background color here
      }
	  ,
	  onClick: () => toast.dismiss()
}


const toast = {
	success: (message, options = {}) => {
		reactToast.success(message);
	},
	error: (message, options = {}) => {
		reactToast.error(message);
	},
	warning: (message, options = {}) => {
		reactToast(message, {
			icon:"⚠️"
		});
	},
	info: (message, options = {}) => {
		reactToast(message, {
			icon:"🛈"
		});
	},
	promise: (message, options = {}) => {
		reactToast.promise(message, options);
	},
	default: (message, options = {}) => {
		reactToast(message);
	},
};

export default toast;
