import React, { useContext } from 'react'
import styles from './Settings.module.css'
import { Button, TextField } from '@mui/material'
import { restaurant } from 'providers/RestaurantProvider'

export default function UserDetails() {
    const restaurantContext = useContext(restaurant)
    const admin= restaurantContext.restaurant.staff.find((user)=>user.role==="ADMIN")
    return (
        <div>
            <div className={styles.settings_header}>
                <h4>User Details</h4>
            </div>
            <div className={styles.reset_container}>
                
                <TextField
                    type='text'
                    label={"Name"}
                    value={admin.name}
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
                <TextField
                    type='text'
                    label={"email"}
                    value={admin.email}
                    disabled
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
                <TextField
                    type='text'
                    label={"Mobile"}
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
            </div>
            <div className={styles.settings_footer}>
                {/* <Button variant='contained' sx={{width:"150px", marginTop:"10px", color:"white"}}>Save</Button> */}
            </div>

        </div>
    )
}
