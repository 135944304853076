import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Accordion, AccordionDetails, AccordionSummary, Button, Card, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { inventory } from "providers/InventoryProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
import toast from "utils/toast";
import logger from "utils/logger";
import commonStyles from "styles.module.css";
// import styles from './recipeStyle.module.css'
import MDInput from "components/MDInput";
import { RiArrowDownLine, RiArrowDropDownLine, RiDeleteBin7Fill } from "react-icons/ri";



function PreparationRecipeView({ setViewRecipe, viewRecipe, setRecipeItemMap }) {

    const navigate = useNavigate();
    const [quantity, setQuantity] = useState(1)
    const [unit, setUnit] = useState("kilogram")
    const [newOption, setNewOption] = useState({});

    const [ingredients, setIngredients] = useState([])
    const [recipeProcess, setRecipeProcess] = useState("")
    const [choppingMethod, setChoppingMethod] = useState("")
    const [additionalRecipes, setAdditionalRecipes] = useState({})



    useEffect(() => {
        if (viewRecipe.recipe) {
            setIngredients([...(viewRecipe.recipe?.ingredients ?? [])])
            setQuantity(viewRecipe.recipe.quantity ?? 0)
            setUnit(viewRecipe.recipe.unit ?? "")
            setRecipeProcess(viewRecipe.recipe.process ?? "")
            setChoppingMethod(viewRecipe.recipe.choppingMethod ?? "")

            const addditionalRecipeMap = {}
            viewRecipe.recipe.additionalIngredients.forEach((ing) => {
                addditionalRecipeMap[ing.property] = {
                    ...(addditionalRecipeMap[ing.property]),
                    [ing.option]: ing
                }
            })
            setAdditionalRecipes({ ...addditionalRecipeMap })
        }
    }, [viewRecipe])

    const handleAddOption = (propId) => {
        const recipesToUpdate = { ...additionalRecipes }
        if ((Object.keys(recipesToUpdate?.[propId] ?? {})?.map(op => op?.toLowerCase()?.trim())).includes(newOption?.[propId]?.toLowerCase()?.trim())) {
            toast.error("Recipe already Added")
            return;
        }
        if (recipesToUpdate?.[propId]?.[newOption[propId]]) {
            toast.error("Recipe already Added")
            return;
        }
        recipesToUpdate[propId] = {
            [newOption[propId]]: {
                process: "",
                choppingMethod: "",
                ingredients: [],
                option: newOption[propId],
                property: propId,
                unit: "kilogram",
                quantity: 1
            },
            ...(recipesToUpdate[propId] ?? {}),
        }
        setAdditionalRecipes({ ...recipesToUpdate })
        setNewOption({})
    }

    const handleAddIngredient = () => {
        setIngredients([...ingredients, { name: "", quantity: 0, unit: "" }])
    }
    const handleAddAdditionalIngredient = (option, propId) => {
        const recipesToUpdate = { ...additionalRecipes }
        recipesToUpdate[propId][option].ingredients = [
            ...(recipesToUpdate[propId][option]?.ingredients ?? []),
            { name: "", quantity: 0, unit: "" }
        ]
        setAdditionalRecipes({ ...recipesToUpdate })

    }

    const handleUpdateIngredient = (index, e) => {
        const { name, value } = e.target
        const updateIngredients = [...ingredients];
        updateIngredients[index] = {
            ...updateIngredients[index],
            [name]: value
        }
        if (name === "unit" && value === "as_required") {
            updateIngredients[index].quantity = 0
        }
        setIngredients([...updateIngredients])
    }

    const handleUpdateAdditionalIngredient = (index, e, option, propId) => {
        const { name, value } = e.target
        const recipesToUpdate = { ...additionalRecipes }
        recipesToUpdate[propId][option].ingredients[index] = {
            ...(recipesToUpdate?.[propId]?.[option]?.ingredients[index] ?? {}),
            [name]: value
        }
        if (name === "unit" && value === "as_required") {
            recipesToUpdate[propId][option].ingredients[index].quantity = 0
        }
        setAdditionalRecipes({ ...recipesToUpdate })
    }

    const handleUpdateAdditionalRecipe = (e, option, propId) => {
        const { name, value } = e.target
        const recipesToUpdate = { ...additionalRecipes }
        recipesToUpdate[propId][option] = {
            ...(recipesToUpdate?.[propId]?.[option] ?? {}),
            [name]: value
        }
        setAdditionalRecipes({ ...recipesToUpdate })
    }

    const handleRemoveIngredient = (index) => {
        const updatedIngredients = [...ingredients];
        updatedIngredients.splice(index, 1);
        setIngredients([...updatedIngredients]);
    }
    const handleRemoveAdditionalIngredient = (index, option, propId) => {
        const recipesToUpdate = { ...additionalRecipes }
        recipesToUpdate[propId][option].ingredients.splice(index, 1);
        setAdditionalRecipes({ ...recipesToUpdate })
    }
    const handleUpdateOptionName = (option, newOptionName, propId) => {
        const recipesToUpdate = { ...additionalRecipes }
        // recipesToUpdate[propId] = {
        //     [newOptionName]: {
        //         ...recipesToUpdate[propId][option],
        //         option: newOptionName

        //     },
        //     ...recipesToUpdate[propId]
        // }
        // delete recipesToUpdate[propId][option]
        let newObj = {}
        for (let key in recipesToUpdate[propId]) {
            if (Object.hasOwnProperty.call(recipesToUpdate[propId], key)) {
                if (key == option) {
                    newObj = {
                        ...newObj,
                        [newOptionName]: {
                            ...recipesToUpdate[propId][option],
                            option:newOptionName
                        }
                    }
                } else {
                    newObj = {
                        ...newObj,
                        [key]: recipesToUpdate[propId][key]
                    }
                }
            }
        }
        recipesToUpdate[propId]={...newObj}

        setAdditionalRecipes({ ...recipesToUpdate })
    }
    const handleRemoveOption = (option, propId) => {
        const recipesToUpdate = { ...additionalRecipes }
        delete recipesToUpdate[propId][option]
        setAdditionalRecipes({ ...recipesToUpdate })
    }

    const handleSave = async (e) => {
        e.preventDefault();
        if (viewRecipe?.recipe?._id) {
            handleUpdate()
        } else {
            handleAdd()
        }

    }

    const handleAdd = async () => {
        try {
            const additionalIngredients = []
            Object.values(additionalRecipes).map(propData => {
                Object.values(propData).map(optionData => {
                    additionalIngredients.push(optionData)
                })
            })
            console.log(additionalIngredients)
            const data = {
                additionalIngredients,
                ingredients,
                process: recipeProcess,
                unit,
                quantity,
                choppingMethod
            }
            const res = await axios.post(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/recipe/preparationRecipe/${viewRecipe.item._id}`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            toast.success("Recipe Added Successfully", {
                autoClose: 2000
            })

            setRecipeItemMap(prev => {
                return { ...prev, [res.data.item._id]: res.data }
            })

            setViewRecipe({
                status: false,
                recipe: {},
                item: {}
            })

        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, {
                    autoClose: 2000
                })
            } else {

                toast.error(err?.response?.data?.error?.message ?? "Network connection failed", {
                    autoClose: 2000
                })
            }
        }
    }
    const handleUpdate = async () => {
        try {

            const additionalIngredients = []
            Object.values(additionalRecipes).map(propData => {
                Object.values(propData).map(optionData => {
                    additionalIngredients.push(optionData)
                })
            })
            const data = {
                additionalIngredients,
                ingredients,
                process: recipeProcess,
                unit,
                quantity,
                choppingMethod
            }
            const res = await axios.put(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/recipe/preparationRecipe/${viewRecipe.recipe._id}`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            toast.success("Recipe Updated Successfully", {
                autoClose: 2000
            })
            setRecipeItemMap(prev => {
                return { ...prev, [res.data.item._id]: res.data }
            })
            setViewRecipe({
                status: false,
                recipe: {},
                item: {}
            })
        } catch (err) {
            if (err?.response?.data?.message) {
                toast.error(err?.response?.data?.message, {
                    autoClose: 2000
                })
            } else {

                toast.error(err?.response?.data?.error?.message ?? "Network connection failed", {
                    autoClose: 2000
                })
            }
        }
    }

    const propertiesAvailable = (viewRecipe.item?.properties ?? []).filter(prop => prop.name !== "Quantity")

    return (
        <div
            style={{

            }}
        >
            <MDBox pt={6} pb={3}>
                <Grid container spacing={6}>
                    <Grid item xs={12}>
                        <Card>
                            <MDBox
                                mx={2}
                                mt={-3}
                                py={3}
                                px={2}
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                                className="flex-column-sm"
                            >
                                <div >
                                    <MDTypography variant="h6" color="white">
                                        {viewRecipe?.item?.name}
                                    </MDTypography>
                                </div>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <button
                                        className={commonStyles.secondary_button}
                                        style={{ border: "1px solid white", background: "none", color: "white" }}
                                        onClick={() => {
                                            setViewRecipe({
                                                status: false,
                                                item: {}
                                            })
                                        }}
                                    >
                                        Back
                                    </button>
                                    <button
                                        className={commonStyles.secondary_button}
                                        onClick={handleSave}
                                    >
                                        Save
                                    </button>


                                </div>
                            </MDBox>
                            <MDBox pt={3}>
                                <MDBox component="form" role="form">


                                    <MDBox mb={2} p={3}>
                                        {
                                            !propertiesAvailable?.[0] &&
                                            <>
                                                <MDBox>
                                                    <div style={{ display: 'flex', gap: "10px" }}>
                                                        <MDInput
                                                            type="number"
                                                            label="Base Quantity"
                                                            name="quantity"
                                                            value={quantity}
                                                            onChange={(e) => setQuantity(e.target.value)}
                                                            variant="outlined"
                                                            fullWidth
                                                        />
                                                        <FormControl fullWidth>
                                                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                            <Select
                                                                labelId="demo-simple-select-label"
                                                                id="demo-simple-select"
                                                                label="Unit"
                                                                value={unit}
                                                                onChange={(e) => setUnit(e.target.value)}
                                                                sx={{ height: "43px" }}
                                                            >
                                                                <MenuItem value={"kilogram"}>Kilo Gram</MenuItem>
                                                                <MenuItem value={"gram"}>Gram</MenuItem>
                                                                <MenuItem value={'litre'}>Litre</MenuItem>
                                                                <MenuItem value={'millilitre'}>Milli Litre</MenuItem>
                                                                <MenuItem value={'nos'}>Nos</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </div>
                                                </MDBox>
                                                <div>
                                                    <MDBox mt={2} mb={2}>
                                                        <b>Ingredients</b>
                                                    </MDBox>
                                                </div>
                                                <MDBox>
                                                    {
                                                        ingredients.map((ingredient, index) => {
                                                            return <div style={{ display: 'flex', gap: "10px", marginTop: "10px" }} key={index}>
                                                                <MDInput
                                                                    type="text"
                                                                    label="Item"
                                                                    name="name"
                                                                    value={ingredient.name}
                                                                    onChange={(e) => handleUpdateIngredient(index, e)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                                <MDInput
                                                                    type="number"
                                                                    label="Quantity"
                                                                    name="quantity"
                                                                    value={ingredient.quantity}
                                                                    disabled={ingredient.unit === "as_required"}
                                                                    onChange={(e) => handleUpdateIngredient(index, e)}
                                                                    variant="outlined"
                                                                    fullWidth
                                                                />
                                                                <FormControl fullWidth>
                                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                    <Select
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Age"
                                                                        sx={{ height: "43px" }}
                                                                        name="unit"
                                                                        value={ingredient.unit}
                                                                        onChange={(e) => handleUpdateIngredient(index, e)}
                                                                    >
                                                                        <MenuItem value={"kilogram"}>Kilo Gram</MenuItem>
                                                                        <MenuItem value={"gram"}>Gram</MenuItem>
                                                                        <MenuItem value={'litre'}>Litre</MenuItem>
                                                                        <MenuItem value={'millilitre'}>Milli Litre</MenuItem>
                                                                        <MenuItem value={'nos'}>Nos</MenuItem>
                                                                        <MenuItem value={'as_required'}>As Required</MenuItem>

                                                                    </Select>
                                                                </FormControl>
                                                                <Button color="black" onClick={() => { handleRemoveIngredient(index) }}>
                                                                    <RiDeleteBin7Fill
                                                                        style={{ fontSize: "1rem" }}
                                                                    />
                                                                </Button>


                                                            </div>
                                                        })
                                                    }
                                                    <Button onClick={handleAddIngredient} sx={{ color: "white !important", marginTop: "10px" }} fullWidth variant="contained" >Add new Ingredient</Button>
                                                </MDBox>
                                                <MDBox mt={2}>
                                                    <div style={{ display: 'flex', gap: "10px" }}>
                                                        <MDInput
                                                            type="text"
                                                            label="Chopping Method"
                                                            name="choppingMethod"
                                                            variant="outlined"
                                                            value={choppingMethod}
                                                            onChange={(e) => setChoppingMethod(e.target.value)}
                                                            fullWidth
                                                        />

                                                    </div>
                                                </MDBox>
                                                <MDBox mt={2}>
                                                    <div style={{ display: 'flex', gap: "10px" }}>
                                                        <MDInput
                                                            type="text"
                                                            label="Process"
                                                            name="process"
                                                            multiline
                                                            rows={6}
                                                            variant="outlined"
                                                            value={recipeProcess}
                                                            onChange={(e) => setRecipeProcess(e.target.value)}
                                                            fullWidth
                                                        />

                                                    </div>
                                                </MDBox>
                                            </>
                                        }

                                        {
                                            (viewRecipe.item?.properties ?? [])
                                                .filter(prop => prop.name !== "Quantity")
                                                .map((prop, index) => {
                                                    return <div  key={index}>
                                                        <div>
                                                            <MDBox mt={2} mb={2}>
                                                                <b>{prop.name}</b>
                                                            </MDBox>
                                                        </div>
                                                        <MDBox mt={2}>
                                                            <div style={{ display: 'flex', gap: "10px" }}>
                                                                <MDInput
                                                                    type="text"
                                                                    label="Option Name"
                                                                    name="optionName"
                                                                    variant="outlined"
                                                                    value={newOption?.[prop._id] ?? ''}
                                                                    onChange={(e) => setNewOption({ [prop._id]: e.target.value })}
                                                                    fullWidth
                                                                />

                                                                <Button onClick={() => handleAddOption(prop._id)} sx={{ color: "white !important", width: "200px" }} disabled={!newOption?.[prop._id] || newOption?.[prop._id]?.trim() === ""} variant="contained">Add Option</Button>
                                                            </div>
                                                        </MDBox>
                                                        {(Object.values(additionalRecipes[prop._id] ?? {})).map((optionData, index) => {
                                                            const option = optionData.option;
                                                            return <div style={{ marginTop: "10px" }} key={index}>
                                                                <Accordion sx={{ border: "none", boxShadow: "0px 2px 10px -6px grey" }}>
                                                                    <AccordionSummary
                                                                        expandIcon={<RiArrowDropDownLine />}
                                                                        aria-controls="panel1a-content"
                                                                        id="panel1a-header"

                                                                    >
                                                                        <Typography fontSize={".9rem"}>{option}</Typography>
                                                                    </AccordionSummary>
                                                                    <AccordionDetails>
                                                                        <div>
                                                                            <MDBox mb={2}>
                                                                                <b>{option}</b>
                                                                                <Button color="black" onClick={() => { handleRemoveOption(option, prop._id) }}>
                                                                                    <RiDeleteBin7Fill
                                                                                        style={{ fontSize: "1rem" }}
                                                                                    />
                                                                                </Button>
                                                                            </MDBox>
                                                                        </div>
                                                                        <MDBox mt={2}>
                                                                            <div style={{ display: 'flex', gap: "10px" }}>
                                                                                <MDInput
                                                                                    type="text"
                                                                                    label="Option Name"
                                                                                    name="optionName"
                                                                                    variant="outlined"
                                                                                    value={option}
                                                                                    onChange={(e) => handleUpdateOptionName(option, e.target.value, prop._id)}
                                                                                    fullWidth
                                                                                />
                                                                                <MDInput
                                                                                    type="number"
                                                                                    label="Quantity"
                                                                                    name="quantity"
                                                                                    value={additionalRecipes?.[prop._id]?.[option]?.quantity}
                                                                                    onChange={(e) => handleUpdateAdditionalRecipe(e, option, prop._id)}
                                                                                    variant="outlined"
                                                                                    fullWidth
                                                                                />
                                                                                <FormControl fullWidth>
                                                                                    <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                                    <Select
                                                                                        labelId="demo-simple-select-label"
                                                                                        id="demo-simple-select"
                                                                                        label="Age"
                                                                                        sx={{ height: "43px" }}
                                                                                        name="unit"
                                                                                        value={additionalRecipes?.[prop._id]?.[option]?.unit}
                                                                                        onChange={(e) => handleUpdateAdditionalRecipe(e, option, prop._id)}
                                                                                    >
                                                                                        <MenuItem value={"kilogram"}>Kilo Gram</MenuItem>
                                                                                        <MenuItem value={"gram"}>Gram</MenuItem>
                                                                                        <MenuItem value={'litre'}>Litre</MenuItem>
                                                                                        <MenuItem value={'millilitre'}>Milli Litre</MenuItem>


                                                                                    </Select>
                                                                                </FormControl>
                                                                            </div>
                                                                        </MDBox>
                                                                        <MDBox mt={2} mb={2}>
                                                                            Ingredients
                                                                        </MDBox>
                                                                        <MDBox>
                                                                            {
                                                                                (additionalRecipes?.[prop._id]?.[option]?.ingredients ?? []).map((ingredient, index) => {
                                                                                    return <div style={{ display: 'flex', gap: "10px", marginTop: "10px" }} key={index}>
                                                                                        <MDInput
                                                                                            type="text"
                                                                                            label="Item"
                                                                                            name="name"
                                                                                            value={ingredient.name}
                                                                                            onChange={(e) => handleUpdateAdditionalIngredient(index, e, option, prop._id)}
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                        <MDInput
                                                                                            type="number"
                                                                                            label="Quantity"
                                                                                            name="quantity"
                                                                                            value={ingredient.quantity}
                                                                                            disabled={ingredient.unit === "as_required"}
                                                                                            onChange={(e) => handleUpdateAdditionalIngredient(index, e, option, prop._id)}
                                                                                            variant="outlined"
                                                                                            fullWidth
                                                                                        />
                                                                                        <FormControl fullWidth>
                                                                                            <InputLabel id="demo-simple-select-label">Unit</InputLabel>
                                                                                            <Select
                                                                                                labelId="demo-simple-select-label"
                                                                                                id="demo-simple-select"
                                                                                                label="Age"
                                                                                                sx={{ height: "43px" }}
                                                                                                name="unit"
                                                                                                value={ingredient.unit}
                                                                                                onChange={(e) => handleUpdateAdditionalIngredient(index, e, option, prop._id)}
                                                                                            >
                                                                                                <MenuItem value={"kilogram"}>Kilo Gram</MenuItem>
                                                                                                <MenuItem value={"gram"}>Gram</MenuItem>
                                                                                                <MenuItem value={'litre'}>Litre</MenuItem>
                                                                                                <MenuItem value={'millilitre'}>Milli Litre</MenuItem>
                                                                                                <MenuItem value={'nos'}>Nos</MenuItem>
                                                                                                <MenuItem value={'as_required'}>As Required</MenuItem>

                                                                                            </Select>
                                                                                        </FormControl>
                                                                                        <Button color="black" onClick={() => { handleRemoveAdditionalIngredient(index, option, prop._id) }}>
                                                                                            <RiDeleteBin7Fill
                                                                                                style={{ fontSize: "1rem" }}

                                                                                            />
                                                                                        </Button>


                                                                                    </div>
                                                                                })
                                                                            }
                                                                            <Button onClick={() => handleAddAdditionalIngredient(option, prop._id)} sx={{ color: "white !important", marginTop: "10px" }} fullWidth variant="contained">Add New Ingredient</Button>
                                                                        </MDBox>
                                                                        <MDBox mt={2}>
                                                                            <div style={{ display: 'flex', gap: "10px" }}>
                                                                                <MDInput
                                                                                    type="text"
                                                                                    label="Chopping Method"
                                                                                    name="choppingMethod"
                                                                                    variant="outlined"
                                                                                    value={additionalRecipes?.[prop._id]?.[option]?.choppingMethod}
                                                                                    onChange={(e) => handleUpdateAdditionalRecipe(e, option, prop._id)}
                                                                                    fullWidth
                                                                                />

                                                                            </div>
                                                                        </MDBox>
                                                                        <MDBox mt={2}>
                                                                            <div style={{ display: 'flex', gap: "10px" }}>
                                                                                <MDInput
                                                                                    type="text"
                                                                                    label="Process"
                                                                                    name="process"
                                                                                    multiline
                                                                                    rows={6}
                                                                                    variant="outlined"
                                                                                    value={additionalRecipes?.[prop._id]?.[option]?.process}
                                                                                    onChange={(e) => handleUpdateAdditionalRecipe(e, option, prop._id)}
                                                                                    fullWidth
                                                                                />
                                                                            </div>
                                                                        </MDBox>

                                                                    </AccordionDetails>
                                                                </Accordion>
                                                            </div>
                                                        })}
                                                    </div>

                                                })
                                        }

                                    </MDBox>
                                </MDBox>
                            </MDBox>
                        </Card>
                    </Grid>
                </Grid>
            </MDBox>

        </div>
    );
}

export default PreparationRecipeView;
