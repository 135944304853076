import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { inventory } from "providers/InventoryProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx";
import axios from "axios";
import toast from "utils/toast";
import logger from "utils/logger";
import commonStyles from "styles.module.css";
import AddInventoryItem from "./AddInventoryItemDialog";
import EditInventoryItemDialog from "./EditInventoryItemDialog";
import MDInput from "components/MDInput";
import AddStockDialog from "./AddStockDialog";



function RawMaterialss() {
    const [anchorEl, setAnchorEl] = useState(null);
    const fileInputRef = useRef(null);
    const [decide, setDecide] = useState(1);
    const [toEdit, setToEdit] = useState(null);
    const inventoryContext = useContext(inventory);
    const [search, setSearch] = useState("");
    const navigate = useNavigate();
    const [addDialogOpen, setAddDialogOpen] = useState(false);
    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [addItem, setAddItem] = useState({});
    const [addStockDialogOpen, setAddStockDialogOpen] = useState(false)

    useEffect(() => {
        const callFunction = inventoryContext.fetchInventoryItems();;
        toast.promise(callFunction, {
            loading: "Fetching Inventory Items...",
            error: "Fetching Inventory Items Failed",
            success: "Inventory Items Refreshed Successfully"
        });
    }, []);

    const deleteHandler = async (item) => {
        try {
            const res = await axios.delete(
                `/restaurants/${localStorage.getItem("restaurant")}/inventory/${item._id
                }`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            if (res.status === 200) {
                let itemCopy = [...inventoryContext.inventoryItems];
                for (let i = 0; i < itemCopy.length; i++) {
                    if (itemCopy[i]._id === item._id) {
                        itemCopy.splice(i, 1);
                    }
                }
                inventoryContext.setInventoryItems(itemCopy);
                
            }
            return res
        } catch (error) {
            logger(error);
        }
    };

    const importHandler = () => {
        const options = [
            {
                name: "",
                unit: "",
                openingStock: "",
                usedStock: 0,
                expirationDate: "",
                cost: 0,
                smallestUnit: 0,
                available: false,
            },
        ];
        const worksheet = XLSX.utils.json_to_sheet(options);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Options");
        XLSX.writeFile(workbook, "inventory_items.xlsx");
    };


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = async (event) => {
            const data = new Uint8Array(event.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const worksheet = workbook.Sheets[workbook.SheetNames[0]];
            const jsonData = XLSX.utils.sheet_to_json(worksheet);
            try {
                for (let row of jsonData) {
                    const response = await axios.post(
                        `/restaurants/${localStorage.getItem(
                            "restaurant"
                        )}/inventory/addInventoryItem`,
                        row,
                        {
                            headers: {
                                "x-auth-token": localStorage.getItem("token"),
                            },
                        }
                    );
                    if (response.status === 200) {
                        let itemCopy = [...inventoryContext.inventoryItems];
                        itemCopy.push(response.data);
                        inventoryContext.setInventoryItems(itemCopy);
                        let mapCopy = inventoryContext.inventoryItemsMap;
                        mapCopy[response.data._id] = response.data;
                        inventoryContext.setStateInventoryItemsMap(mapCopy);
                    } else {
                        console.error("Failed to upload row");
                    }
                }
            } catch (error) {
                console.error("Error occurred during file upload:", error);
            }
        };
        reader.readAsArrayBuffer(file);
    };

    return (
        <DashboardLayout>
            <Sidenav className="animate" routes={routes.inventory} />
            <DashboardNavbar />
            <div
                style={{

                }}
            >
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div style={{ maxWidth: "65%" }}>
                                        <MDTypography variant="h6" color="white">
                                            Raw Materials management
                                        </MDTypography>
                                        <MDInput
                                            name="search"
                                            type="text"
                                            size="small"
                                            label="Search"
                                            variant="filled"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "10px",
                                                width: "300px",
                                                maxWidth: "90%"
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <button
                                            className={commonStyles.primary_button}
                                            onClick={() => {
                                                setAddDialogOpen(true);
                                            }}
                                        >
                                            Add Raw Material
                                        </button>
                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    {(
                                        <DataTable
                                            table={{
                                                columns: [
                                                    {
                                                        Header: "Name",
                                                        accessor: "name",
                                                    },
                                                    {
                                                        Header: "Current Stock",
                                                        accessor: "currentStock",
                                                    },
                                                    {
                                                        Header: "Unit",
                                                        accessor: "unit",
                                                    },
                                                    {
                                                        Header: "Cost Per unit",
                                                        accessor: "cost",
                                                    },
                                                    {
                                                        Header: "Available",
                                                        accessor: "available",
                                                    },
                                                    {
                                                        Header: "Last modified",
                                                        accessor: "lastModified",
                                                    },
                                                    {
                                                        Header: "Who modified",
                                                        accessor: "whoModified",
                                                    },
                                                    {
                                                        Header: "Action",
                                                        accessor: "action",
                                                    },

                                                ],
                                                rows: (inventoryContext?.inventoryItems ?? [])
                                                    .filter((item) => (item.name.match(new RegExp(search?.replace(/\\/g, '\\\\'), 'i')) && item.deleted !== true))
                                                    .map((item, index) => {
                                                        return {
                                                            name: item.name,
                                                            currentStock: (item.currentStock).toFixed(2),
                                                            unit: item.unit,
                                                            cost: (item.cost).toFixed(2),
                                                            available: item.available ? "Yes" : "No",
                                                            lastModified: new Date(item.updatedAt).toLocaleString(),
                                                            whoModified: item?.staff?.name ?? "--",
                                                            action: (<div
                                                                style={{ display: "flex", gap: "20px" }}
                                                            >
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setEditDialogOpen(true);
                                                                        setToEdit(item);
                                                                    }}
                                                                >
                                                                    <EditIcon />
                                                                </span>
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        
                                                                        const callFunction = deleteHandler(item);
                                                                        toast.promise(callFunction, {
                                                                            loading: "Deleting Inventory Item...",
                                                                            error: "Deleting Inventory Item Failed",
                                                                            success: "Inventory Item Deleted Successfully"
                                                                        });
                                                                    }}
                                                                >
                                                                    <DeleteIcon />
                                                                </span>
                                                                <span
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setAddItem({ id: item._id, unit: item.unit, currentStock: item.currentStock, cost: item.cost });
                                                                        setAddStockDialogOpen(true);
                                                                    }}
                                                                >
                                                                    <AddIcon />
                                                                </span>
                                                            </div>),

                                                        };
                                                    }),
                                            }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    )}
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>

            </div>
            <AddStockDialog
                open={addStockDialogOpen}
                setOpen={setAddStockDialogOpen}
                setAddItem={setAddItem}
                addItem={addItem}
            />
            <AddInventoryItem
                open={addDialogOpen}
                setOpen={setAddDialogOpen}
            />
            <EditInventoryItemDialog
                open={editDialogOpen}
                setOpen={setEditDialogOpen}
                item={toEdit}
            />

        </DashboardLayout>
    );
}

export default RawMaterialss;
