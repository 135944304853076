/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import styles from "./AddUserDialog.module.css";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { admin } from "providers/AdminProvider";
import axios from "axios";
import { restaurant as restaurantData } from "../../../providers/RestaurantProvider";
import logger from "utils/logger";
import toast from "utils/toast";
import { ClipLoader } from "react-spinners";

// import BasicLayout from "../../../components/";

function ViewUserDialog({ open, setOpen, userDetails }) {
  const restaurantContext = useContext(restaurantData);
  const adminContext = useContext(admin);
  // const { editUser } = adminContext;
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
  });
  const [selectedRights, setSelectedRights] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const { rightsOptions } = adminContext;

  useEffect(() => {
    if (userDetails) {
      setUser({
        name: userDetails.name,
        email: userDetails.email,
        password: userDetails.password,
      });
      setSelectedRights([...userDetails.rights]);
      setSelectedRole(userDetails.role);
    }
  }, [userDetails]);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        // sx={{ zIndex: 200 }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <Card sx={{ width: "600px", maxWidth: "100%" }}>
          <MDBox
            variant="gradient"
            bgColor="info"
            // borderRadius="lg"
            coloredShadow="success"
            sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
            mx={2}
            mt={0}
            p={1}
            textAlign="center"
          >
            <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
              View user details
            </MDTypography>
          
          </MDBox>
          <MDBox pt={2} pb={3} px={3}>
            <MDBox component="form" role="form">
              <MDBox mb={2} className={styles.add_user_form_row}>
                <MDInput
                  type="text"
                  disabled
                  label="Name"
                  name="name"
                  value={user.name}
                  variant="standard"
                  fullWidth
                />
                <MDInput
                  name="email"
                  value={user.email}
                  type="email"
                  label="Email"
                  variant="standard"
                  fullWidth
                  disabled
                />
              </MDBox>

              <MDBox className={styles.add_user_form_row} mb={2}>
               
                <MDInput
                  name="role"
                  value={selectedRole}
                  type="text"
                  label="Role"
                  variant="standard"
                  fullWidth
                  disabled
                />
              </MDBox>

              <MDBox
                display="flex"
                justifyContent={"center"}
                alignItems="center"
                ml={-1}
              >
                <table
                  style={{
                    width: "97%",
                    borderCollapse: "collapse",
                    fontSize: ".85rem",
                  }}
                >
                  <tr>
                    <th
                      style={{
                        textAlign: "left",
                        height: "40px",
                        borderBottom: "1px solid #DADDE0",
                      }}
                      colSpan={3}
                    >
                      Rights
                    </th>
                  </tr>
                  {rightsOptions.map((r, index1) => {
                    return (
                      <tr>
                        <th style={{ textAlign: "left" }}>{r.label}</th>
                        {r.fields.map((f, index2) => {
                          return (
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  gap: "5px",
                                  alignItems: "center",
                                }}
                              >
                                <Checkbox
                                  disabled
                                  id={index1 + "r" + index2}
                                  checked={selectedRights.includes(f.value)}
                                  value={f.value}
                                />
                                <label
                                  htmlFor={index1 + "r" + index2}
                                  style={{ maxWidth: "155px" }}
                                >
                                  {f.label}
                                </label>
                              </div>
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </table>
              </MDBox>
            </MDBox>
          </MDBox>
        </Card>
      </Dialog>
    </>
  );
}

export default ViewUserDialog;
