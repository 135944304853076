import { createContext, useState } from "react";
const cart = createContext();

const { Provider } = cart;

const CartProvider = ({ children }) => {
  const [items, setItems] = useState(
    JSON.parse(sessionStorage.getItem("cart")) || []
  );

  const setCart = (cart) => {
    sessionStorage.setItem("cart", JSON.stringify(cart));
    setItems(cart);
  };

  const addItem = (item, properties = null, note = null) => {
    let itemFound = false;

    const newCart = items.map((cartItem) => {
      if (
        cartItem.item._id === item._id &&
        (properties === null || properties === cartItem.properties)
      ) {
        itemFound = true;
        return {
          ...cartItem,
          quantity: cartItem.quantity + 1,
        };
      }
      return cartItem;
    });

    if (!itemFound) {
      newCart.push({
        item,
        quantity: 1,
        properties,
        note,
      });
    }

    setCart(newCart);
  };

  const subtractItem = (item, properties = null) => {
    const newCart = [];

    items.forEach((cartItem) => {
      if (
        cartItem.item._id === item._id &&
        (properties === null || properties === cartItem.properties)
      ) {
        if (cartItem.quantity > 1) {
          newCart.push({
            ...cartItem,
            quantity: cartItem.quantity - 1,
          });
        }
      } else {
        newCart.push(cartItem);
      }
    });

    setCart(newCart);
  };

  const getQuantity = (id, properties = null) => {
    const item = items.find((item) => {
      return (
        item.item._id === id &&
        (properties === null || properties === item.properties)
      );
    });
    return item ? item.quantity : 0;
  };

  const addNote = (id, note) => {
    const newCart = items.map((item) => {
      if (item.item._id === id) {
        return {
          ...item,
          note,
        };
      }
      return item;
    });
    setCart(newCart);
  };

  const getItemCount = () => {
    let count = 0;
    items.forEach((item) => {
      count += item.quantity;
    });
    return count;
  };

  const getCartTotal = () => {
    let total = 0;
    items.forEach((item) => {
      total += item.item.price * item.quantity;
      if (item.properties === null) return;
      item.properties.forEach((property) => {
        const prop = item.item.properties.find(
          (prop) => prop.name === property.name
        );

        let optPrice = 0;
        if (property?.options?.[0]) {
          prop.options?.forEach((option) => {
            if (property.options.includes(option.name)) {
              optPrice += option?.price ?? 0;
            }
          });
        } else {
          const opt = prop?.options.find((opt) => opt.name === property.option);
          optPrice = opt?.price ?? 0;
        }
        total += optPrice * item.quantity;
      });
    });
    return total;
  };

  const clearCart = () => {
    setCart([]);
  };

  return (
    <Provider
      value={{
        items,
        setCart,
        addItem,
        subtractItem,
        addNote,
        getQuantity,
        getItemCount,
        getCartTotal,
        clearCart,
      }}
    >
      {children}
    </Provider>
  );
};

export { CartProvider, cart };
