import React, { useContext, useEffect, useRef, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Button, Card, Grid, TextField } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

import MDInput from "components/MDInput";
import { restaurant } from "providers/RestaurantProvider";
import { TbEyeDiscount } from "react-icons/tb";
import { BsEye } from "react-icons/bs";
import PreparationRecipeView from "./PreparationRecipeView";
import axios from "axios";
import toast from "utils/toast";
import { admin } from "providers/AdminProvider";



function PreparationRecipe() {
    const restaurantContext = useContext(restaurant);
    const [search, setSearch] = useState("");
    const {setLoading} = useContext(admin)
    const [viewRecipe, setViewRecipe]= useState({
        item:{},
        status:false
    })
    const [recipeItemMap, setRecipeItemMap] = useState({})

    const fetchPreperationRecipes=async()=>{
        try{
            setLoading(true)
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/recipe/preparationRecipe/all`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            const recipeMap={}
            setLoading(false)
            res.data.forEach(rec=>{
                console.log(rec?.item)
                if(rec?.item?._id){
                    recipeMap[rec.item._id]=rec
                }
            })
            setRecipeItemMap({...recipeMap})
        }catch(err){
            setLoading(false)
            console.log(err)
            toast.error(err?.response?.data?.message ?? "Network connection  failed", {
                autoClose: 2000
            })
        }
    }
    useEffect(()=>{
        fetchPreperationRecipes()
    },[])



    return (
        <DashboardLayout>
            <Sidenav className="animate" routes={routes.main} />
            <DashboardNavbar />
{           
            !viewRecipe?.status?

            <div
                style={{
                      
                }}
            >
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    vankaya          >
                                    <div style={{maxWidth:"65%"}}>
                                        <MDTypography variant="h6" color="white">
                                            Preparation Recipe management
                                        </MDTypography>
                                        <MDInput
                                            name="search"
                                            type="text"
                                            size="small"
                                            label="Search"
                                            variant="filled"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "10px",
                                                width: "300px",
                                                maxWidth:"90%"
                                            }}
                                        />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                      
                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    {(
                                        <DataTable
                                            table={{
                                                columns: [
                                                    {
                                                        Header: "Name",
                                                        accessor: "name",
                                                    },
                                                    {
                                                        Header: "Recipe Added",
                                                        accessor: "recipeAdded",
                                                    },
                                                    {
                                                        Header: "Action",
                                                        accessor: "action",
                                                    },

                                                ],
                                                rows: (Object.values(restaurantContext.items ?? {}) ?? [])
                                                    .filter((item) => (item?.name?.match(new RegExp(search?.replace(/\\/g, '\\\\'), 'i'))))
                                                    .map((item, index) => {
                                                        return {
                                                            name: item.name,
                                                            recipeAdded: recipeItemMap[item._id] ? "Yes" : "No",
                                                            action: (<div
                                                                style={{ display: "flex", gap: "20px" }}
                                                            >
                                                               
                                                                <Button
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        console.log(recipeItemMap[item._id])
                                                                        setViewRecipe({
                                                                            item,
                                                                            status:true,
                                                                            recipe:recipeItemMap[item._id]
                                                                        })
                                                                    }}
                                                                >
                                                                    <BsEye />
                                                                </Button>
                                                            </div>),

                                                        };
                                                    }),
                                            }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    )}
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>

            </div>
            : <PreparationRecipeView setRecipeItemMap={setRecipeItemMap} setViewRecipe={setViewRecipe} viewRecipe={viewRecipe}/>
}
        </DashboardLayout>
    );
}

export default PreparationRecipe;
