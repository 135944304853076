import axios from "axios";
import { createContext, useContext, useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";
import { admin } from "./AdminProvider";

const inventory = createContext({});
const { Provider } = inventory;

const InventoryProvider = ({ children }) => {
  const [inventoryItems, setStateInventoryItems] = useState(
    JSON.parse(localStorage.getItem("inventoryItems")) || []
  );
  const { setLoading } = useContext(admin);
  const [consumptionBills, setConsumptionBills] = useState([]);
  const [additionBills, setAdditionBills] = useState([]);

  const [recipes, setStateRecipes] = useState(
    JSON.parse(localStorage.getItem("recipes")) || []
  );

  const [recipesMap, setStateRecipesMap] = useState(
    JSON.parse(localStorage.getItem("recipesMap")) || {}
  );
  const setRecipes = (recipes) => {
    localStorage.setItem("recipes", JSON.stringify(recipes));
    setStateRecipes(recipes);
  };

  const setInventoryItems = (items) => {
    localStorage.setItem("inventoryItems", JSON.stringify(items));
    setStateInventoryItems(items);
  };

  const [inventoryItemsMap, setStateInventoryItemsMap] = useState(
    JSON.parse(localStorage.getItem("inventoryItemsMap")) || {}
  );

  const setInventoryItemsMap = () => {
    const inventoryItemsMapCopy = {};
    inventoryItems?.forEach((item) => {
      inventoryItemsMapCopy[item._id] = item;
    });
    localStorage.setItem(
      "inventoryItemsMap",
      JSON.stringify(inventoryItemsMapCopy)
    );
    setStateInventoryItemsMap(inventoryItemsMapCopy);
  };

  const fetchInventoryItems = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/inventory`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res, "inventoryItems");
      if (res.status === 200) {
          setInventoryItems(res.data);
      }
      return res;
    } catch (error) {
      setLoading(false)
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const fetchRecipes = async () => {
    try {
        console.log("recipedata")
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/recipe`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger("recipedata", res.data);
      setRecipes(res.data);
      return res;
    } catch (error) {
      setLoading(false)
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const fetchConsumptionBills = async (startDate, endDate) => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem(
          "restaurant"
        )}/inventory/consumption-bills?from=${startDate.getTime()}&to=${endDate.getTime()}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      
      logger("resData", res.data);
      
      setConsumptionBills(res.data.consumptionBills);
      return res;
    } catch (err) {
      setLoading(false)
      logger(err);
      toast.error(err?.response?.data?.message ?? "Network connection failed");
    }
  };
  const fetchAdditionBill = async (startDate, endDate) => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem(
          "restaurant"
        )}/inventory/addition-bills?from=${startDate.getTime()}&to=${endDate.getTime()}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );

      logger("resData", res.data);

      setAdditionBills(res.data.additionBills);
      return res
    } catch (err) {
      logger(err);
      setLoading(false)
      toast.error(err?.response?.data?.message ?? "Network connection failed");
    }
  };

  const setRecipesMap = () => {
    const recipesMapCopy = {};
    for (let i = 0; i < recipes.length; i++) {
      recipesMapCopy[recipes[i].item] = recipes[i];
    }
    localStorage.setItem("recipesMap", JSON.stringify(recipesMapCopy));
    setStateRecipesMap(recipesMapCopy);
  };

  return (
    <Provider
      value={{
        inventoryItems,
        setInventoryItems,
        setStateInventoryItems,
        fetchInventoryItems,
        setInventoryItemsMap,
        inventoryItemsMap,
        recipes,
        setRecipes,
        fetchRecipes,
        recipesMap,
        setRecipesMap,
        setStateRecipesMap,
        additionBills,
        consumptionBills,
        fetchConsumptionBills,
        fetchAdditionBill,
      }}
    >
      {children}
    </Provider>
  );
};

export { inventory, InventoryProvider };
