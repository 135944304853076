import { createContext, useState } from "react";

const settings = createContext();

const { Provider } = settings;

const SettingsProvider = ({ children }) => {
	const [settings, setStateSettings] = useState(
		JSON.parse(localStorage.getItem("settings")) || {}
	);

	const setSettings = (settings) => {
		localStorage.setItem("settings", JSON.stringify(settings));
		setStateSettings(settings);
	};

	const setSectionVisibility = (section, visible) => {
		const newSettings = {
			...settings,
			sectionVisibility: {
				...settings.sectionVisibility,
				[section]: visible,
			},
		};
		setSettings(newSettings);
	};

	const setTableFilter = (type, value) => {
		const newSettings = {
			...settings,
			tableFilters: {
				...settings.tableFilters,
				[type]: value,
			},
		};
		setSettings(newSettings);
	};

	const clearSettings = () => {
		setStateSettings({});
	};

	return (
		<Provider
			value={{
				settings,
				setSettings,
				setSectionVisibility,
				clearSettings,
				setTableFilter,
			}}
		>
			{children}
		</Provider>
	);
};

export { SettingsProvider, settings };
