/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import styles from "./rawMaterial.module.css";

// Authentication layout components

// Images
import {
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import { ClipLoader } from "react-spinners";
import { inventory } from "providers/InventoryProvider";
// import BasicLayout from "../../../components/";

function AddInventoryItemDialog({ open, setOpen }) {
    const [isLoading, setIsLoading] = useState(false)
    const inventoryContext = useContext(inventory);
    const [availability, setAvailability] = useState(false);
    const handleAvailabilityChange = (e) => {
        setAvailability(e.target.checked);
    };
    const [units, setUnits] = useState(["kg", "gram", "litre", "ml", 'nos']);
    const [name, setName] = useState("");
    const [currentStock, setCurrentStock] = useState(0);
    const [cost, setCost] = useState(0);
    const [unit, setUnit] = useState('');


    const saveHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)
        try {
            let data = {
                name: name,
                unit,
                currentStock: currentStock,
                cost: cost,
                available: availability,
            };
            if (data.name.trim() === "") {
                setIsLoading(false)
                return;
            }

            const res = await axios.post(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/inventory/addInventoryItem`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );

            logger(res);
            if (res.status === 200) {
                toast.success("Successfully added", {
                    autoClose: 2000,
                });
                setOpen(false);
                let itemCopy = [...inventoryContext.inventoryItems];
                itemCopy.push(res.data);
                inventoryContext.setInventoryItems(itemCopy);
                let mapCopy = inventoryContext.inventoryItemsMap;
                mapCopy[res.data._id] = res.data;
                inventoryContext.setInventoryItemsMap(mapCopy);
            }
            setIsLoading(false)
            return res;
        } catch (error) {
            logger(error);
            setIsLoading(false)
            toast.error(
                error?.response?.data?.message ?? "Network connection failed",
                {
                    autoClose: 2000,
                }
                );
                throw new Error("");
        }
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "600px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={0}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Add Inventory Raw Material
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    type="text"
                                    label="Name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />

                            </MDBox>

                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    name="stock"
                                    type="number"
                                    value={currentStock}
                                    label="Stock"
                                    onChange={(e) => {
                                        setCurrentStock(e.target.value < 0 ? 0 : e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Choose Unit
                                    </InputLabel>
                                    <Select
                                        // labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        sx={{ width: "100%", height: "29px" }}
                                        name="unit"
                                        variant="standard"
                                        placeholder="Unit"
                                        value={unit}
                                        onChange={(e) => setUnit(e.target.value)}
                                    >
                                        {units.map((unit) => (
                                            <MenuItem key={unit} value={unit}>
                                                {unit}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <MDInput
                                    name="stock"
                                    label="Or Enter Custom Unit"
                                    type="text"
                                    value={unit}
                                    onChange={(e) => setUnit(e.target.value.toString().length > 10 ? unit : e.target.value)}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    name="cost"
                                    type="number"
                                    value={cost}
                                    onChange={(e) => {
                                        setCost(e.target.value < 0 ? 0 : e.target.value);
                                    }}
                                    label="Cost per unit"
                                    variant="standard"
                                    fullWidth
                                />


                            </MDBox>
                            <MDBox mb={2} className={styles.add_user_form_row}>

                                <input
                                    type="checkbox"
                                    checked={availability}
                                    onChange={handleAvailabilityChange}
                                    variant="standard"
                                    id="availability"
                                />
                                <label htmlFor="availability">Availability</label>
                            </MDBox>


                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={(e) => {
                                        const callFunction = saveHandler(e);
                                        toast.promise(callFunction, {
                                            loading: "Adding Inventory Item...",
                                            error: "Adding Inventory Item Failed",
                                            success: "Inventory Item Added Successfully"
                                        });
                                    }}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default AddInventoryItemDialog;
