import React, { useEffect, useState, useContext } from "react";
import { admin } from "providers/AdminProvider";
import {
	Card,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import styles from "./RunningTableDetails.module.css";
import DataTable from "examples/Tables/DataTable";
import commonStyles from "styles.module.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";


function RunningTableDetails({ currrentTable }) {
	const context = useContext(admin);

	const [total, setTotal] = useState(0);
	useEffect(() => {
		let temp = 0;
		currrentTable["kots"]
			.map((kotID) => context.runningKOTs[kotID])
			.forEach((k) => {
				temp = temp + k.total;
			});
		setTotal(temp);
	}, [currrentTable]);

	const tableRows=[]
	currrentTable["kots"]
	.map((kotID) => context.runningKOTs[kotID])
	.map((k) =>k.items.map((i) =>{
		tableRows.push({name:i.item.name, qty:i.quantity, price:i.item.price, total:i.item.price * i.quantity})
	}))
	
	return (
		<div className={styles.mainContainer}>
			
			<Card sx={{marginTop:"30px"}}>
			<MDBox
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
					Table No. {currrentTable.table.name} 
					<br/>
					(Total :{total})
					</MDTypography>
					
				</MDBox>
				<DataTable
					table={{
						columns: [
							{
								Header: "Item Name",
								accessor: "name",
							},
							{
								Header: "Qty",
								accessor: "qty",
							},
							{
								Header: "Price",
								accessor: "price",
							},
							{
								Header: "Total",
								accessor: "total",
							}
						],
						rows: tableRows
					}}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={false}
					noEndBorder
				/>
			</Card>
		</div>
	);
}

export default RunningTableDetails;
