import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import {
    Card,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { restaurant } from "providers/RestaurantProvider";

const SwiggyOrderDetails = ({ order }) => {
    console.log(order)
    return (
        <div>

            <Card sx={{ marginTop: "50px", padding:"5px" }}>
                <MDBox
                    // mx={1}
                    // mt={-3}
                    py={2}
                    px={2}
                    variant="gradient"
                    className="primary-bg"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MDTypography variant="h6" color="white">
                        Order Details
                    </MDTypography>

                </MDBox>
                <DataTable
                    table={{
                        columns: [
                            {
                                Header: "Order ID",
                                accessor: "orderId",
                            },
                            {
                                Header: "Status",
                                accessor: "status",
                            },
                            {
                                Header: "Bill",
                                accessor: "bill",
                            },
                            {
                                Header: "Items",
                                accessor: "items",
                            },
                        ],
                        rows: [{
                            orderId: order?.orderId,
                            status: order.status,
                            bill: order.bill,
                            items: order.items?.length,
                        }]
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                />
            </Card>
        

            <Card sx={{ marginTop: "40px", padding:"5px" }}>
                <MDBox
                    // mx={1}
                    // mt={-3}
                    py={2}
                    px={2}
                    variant="gradient"
                    className="primary-bg"
                    borderRadius="lg"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <MDTypography variant="h6" color="white">
                        Order Items
                    </MDTypography>

                </MDBox>
                <DataTable
                    table={{
                        columns: [
                            {
                                Header: "Item Name",
                                accessor: "name",
                            },
                            {
                                Header: "Quantity",
                                accessor: "quantity",
                            },
                            {
                                Header: "Price",
                                accessor: "price",
                            },
                            {
                                Header: "Total",
                                accessor: "total",
                            },

                        ],
                        rows: order.items.map(item=>{
                            return {name:item.name, quantity:item.quantity, price:item.sub_total/item.quantity, total:item.total}
                        })
                    }}
                    isSorted={false}
                    entriesPerPage={false}
                    showTotalEntries={false}
                    noEndBorder
                />
                <MDBox
                    maxWidth={"99%"}
                    p={2}
                    style={{
                        overflowX: "scroll"
                    }}
                    bgColor="white"
                    borderRadius="lg"
                    color="dark"
                    display="flex"
                    justifyContent="space-between"
                    flexDirection="column"

                >
                    <MDTypography variant="b" color="dark">
                        Grand Total : {(order.bill).toFixed(2)}
                    </MDTypography>
                </MDBox>
            </Card>
        </div>
    );
};

export default SwiggyOrderDetails;
