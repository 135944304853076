import React from "react";
import ReactDOM from "react-dom/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { SettingsProvider } from "./providers/SettingsProvider.js";
import { RestaurantProvider } from "./providers/RestaurantProvider.js";
import { CartProvider } from "./providers/CartProvider.js";
import { AdminProvider } from "./providers/AdminProvider.js";
import {  ThemeProvider } from "@mui/material";
// import { ToastContainer } from "react-toastify";
import toast, { ToastBar, Toaster } from "react-hot-toast";
import "react-toastify/dist/ReactToastify.css";
import LiveView from "./pages/LiveView/LiveView.jsx";
import OrderSummary from "./pages/OrderSummary/OrderSummary.jsx";
import PastOrderSummary from "./pages/OrderSummary/PastOrderSummary.jsx";
import OrderSummaryDetails from "./pages/OrderSummary/OrderSummaryDetails.jsx";
import MenuView from "./pages/MenuView/MenuView.jsx";
import UserManagement from "./pages/UserManagement/BillerApp/UserManagement.jsx";
import InventoryDashboard from "./pages/Inventory/InventoryDashboard/InventoryDashboard.jsx";
import { InventoryProvider } from "./providers/InventoryProvider.js";
import { MaterialUIControllerProvider } from "context";
import axios from "axios";
import SplashScreen from "./pages/SplashScreen/SplashScreen";
import theme from "assets/theme";
import SignIn from "layouts/authentication/sign-in";
import Settings from "pages/Settings/Settings";
import { ConfigProvider } from "antd";
import "./index.css";
import RawMaterialss from "pages/Inventory/RawMaterials/RawMaterialss";
import RecipeManagements from "pages/Inventory/RecipeManagement/RecipeManagements";
import ProfitMarginCalculator from "pages/Inventory/ExpenseManagement/ProfitMarginCalculator";
import DashboardMain from "pages/Dashboard/DashboardMain";
import BlobContainer from "components/BlobContainer/BlobContainer";
import ProtectedComponent from "components/ProtectedComponent/ProtectedComponent";
import PreparationRecipe from "pages/Inventory/PreparationRecipe/PreparationRecipe";
import { SidebarProvider } from "providers/SidebarProvider";
import LoaderComponent from "components/LoaderComponent/LoaderComponent";
import OrderReport from "pages/OrderReport/OrderReport";
import ModifiedBills from "pages/OrderSummary/ModifiedBills";
import CancelledOrders from "pages/OrderSummary/CancelledOrders";
import WaivedOffOrders from "pages/OrderSummary/WaivedOffOrders";
import ExpenseTracker from "pages/ExpenseTracker/ExpenseTracker";
import { IoClose } from "react-icons/io5";
import OnlineOrders from "pages/OnlineOrders/OnlineOrders";
import SwiggyOrders from "pages/OnlineOrders/SwiggyOrders";

const router = createBrowserRouter([
  {
    path: "/",
    element: <SplashScreen />,
  },
  {
    path: "/dashboard",
    element: <ProtectedComponent Component={DashboardMain} />,
  },
  {
    path: "/auth/login",
    element: <SignIn />,
  },
  {
    path: "/live-view",
    element: <ProtectedComponent Component={LiveView} />,
  },
  {
    path: "/order-report",
    element: <ProtectedComponent Component={OrderReport} />,
  },
  {
    path: "/order-summary",
    element: <ProtectedComponent Component={OrderSummary} />,
  },
  {
    path: "/order-summary/modified",
    element: <ProtectedComponent Component={ModifiedBills} />,
  },
  {
    path: "/order-summary/past",
    element: <ProtectedComponent Component={PastOrderSummary} />,
  },
  {
    path: "/order-summary/cancelled",
    element: <ProtectedComponent Component={CancelledOrders} />,
  },
  {
    path: "/order-summary/waivedoff",
    element: <ProtectedComponent Component={WaivedOffOrders} />,
  },
  {
    path: "/order-summary/:id",
    element: <ProtectedComponent Component={OrderSummaryDetails} />,
  },
  {
    path: "/menu-management",
    element: <ProtectedComponent Component={MenuView} />,
  },
  {
    path: "/user-management/:role",
    element: <ProtectedComponent Component={UserManagement} />,
  },

  {
    path: "/settings",
    element: <ProtectedComponent Component={Settings} />,
  },
  {
    path: "/inventory",
    element: <ProtectedComponent Component={InventoryDashboard} />,
  },
  {
    path: "/expense-tracker",
    element: <ProtectedComponent Component={ExpenseTracker} />,
  },
  {
    path: "/inventory/raw-materials",
    element: <ProtectedComponent Component={RawMaterialss} />,
  },
  {
    path: "/inventory/recipe-management",
    element: <ProtectedComponent Component={RecipeManagements} />,
  },
  {
    path: "/preperation-recipe",
    element: <ProtectedComponent Component={PreparationRecipe} />,
  },
  {
    path: "/inventory/profit-margin",
    element: <ProtectedComponent Component={ProfitMarginCalculator} />,
  },
  {
    path: "/online-orders",
    element: <ProtectedComponent Component={OnlineOrders} />,
  },
  {
    path: "/online-orders/swiggy",
    element: <ProtectedComponent Component={SwiggyOrders} />,
  },
]);
console.log("v.0.0.1")
axios.defaults.baseURL = process.env.REACT_APP_API_URL;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RestaurantProvider>
      <AdminProvider>
        <InventoryProvider>
          <SettingsProvider>
            <CartProvider>
              <SidebarProvider>
                <MaterialUIControllerProvider>
                  <ConfigProvider
                    theme={{
                      token: {
                        // colorPrimary: "#9E5E28",
                        colorPrimary: "#8A005C",
                        borderRadius: 5,
                      },
                    }}
                  >
                    <ThemeProvider theme={theme}>
                      <BlobContainer />
                      <LoaderComponent />
                      <RouterProvider router={router} />
                    </ThemeProvider>
                  </ConfigProvider>
                </MaterialUIControllerProvider>
              </SidebarProvider>
            </CartProvider>
          </SettingsProvider>
        </InventoryProvider>
      </AdminProvider>
    </RestaurantProvider>
    <Toaster
      position={window.innerWidth <= 912 ? "top-center" : "top-right"}
      reverseOrder={false}
      
      toastOptions={{
        success: {
          style: {
            background: "#22B23A",
            color: "white",
          },
          iconTheme: {
            primary: "white",
            secondary: "#22B23A",
          },
        },
        loading: {
          style: {
            background: "#E0F4FF",
            color: "#39A7FF",
          },
          iconTheme: {
            primary: "white",
            secondary: "#39A7FF",
          },
        },
        error: {
          iconTheme: {
            primary: "white",
            secondary: "#F70000",
          },
          style: {
            background: "#F70000",
            color: "white",
          },
        },
      }}
    >
      {(t) => (
        <ToastBar toast={t}>
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              {t.type !== 'loading' && (
                <IoClose color="inherit" onClick={() => toast.dismiss(t.id)}/>
              )}
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    const waitingServiceWorker = registration.waiting;

    if (waitingServiceWorker) {
      waitingServiceWorker.addEventListener("statechange", (event) => {
        if (event.target.state === "activated") {
          window.location.reload();
        }
      });
      waitingServiceWorker.postMessage({ type: "SKIP_WAITING" });
    }
  },
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
