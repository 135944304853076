import React, { useContext, useEffect } from "react";
import { useState } from "react";
import styles from "./MenuView.module.css";
import { Card, Grid } from "@mui/material";
import { restaurant } from "../../providers/RestaurantProvider.js";
import AddIcon from "@mui/icons-material/Add";
import UpdateCategory from "./UpdateCategory/UpdateCategory.jsx";
import { admin } from "../../providers/AdminProvider.js";
import logger from "../../utils/logger.js";
import commonStyles from "../../styles.module.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout/index.js";
import Sidenav from "examples/Sidenav/index.js";
import routes from "routes.js";
import DashboardNavbar from "examples/Navbars/DashboardNavbar/index.js";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { QuestionCircleOutlined } from '@ant-design/icons';
import AddCategoryDialog from "./AddCategory/AddCategoryDialog";
import CategoryImageUploadModal from "./UpdateCategory/CategoryImageModel";
import { CameraAltRounded, Delete } from "@mui/icons-material";
import { Popconfirm } from "antd";

function MenuView() {
	const adminContext = useContext(admin);
	const {
		categoryToUpdate,
		setCategoryToUpdate,
		updateOne,
		globalMenu,
		setGlobalMenu,
		backStack,
		setBackStack,
		deleteCategory
	} = adminContext;
	const [confirmDialogOpenAdd, setConfirmDialogOpenAdd] = useState(false);
	const restaurantContext = useContext(restaurant);
	const [updateCategory, setUpdateCategory] = useState({});
	const [categoryImageModalOpen, setCategoryImageModalOpen] = useState(false);
	const [currentMenu, setCurrentMenu] = useState(
		restaurantContext.restaurant.menu || {}
	);
	const [isMainMenu, setIsMainMenu] = useState(true);

	useEffect(() => {
		logger("hii");
	}, [categoryToUpdate, globalMenu]);

	useEffect(() => {
		setGlobalMenu(currentMenu);
	}, [currentMenu, restaurantContext.restaurant]);

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.main} />
			<DashboardNavbar />
			{isMainMenu ? (
				<div
					style={{

					}}
					className={styles.body}
				>
					<div>
						<div>
							<div
								style={{
									display: "flex",
									justifyContent: "end",
									alignItems: "center",
									// padding: "10px",
								}}
							>
								<button
									// className={styles.button}
									style={{ padding: "15px", width: "200px" }}
									className={commonStyles.primary_button}
									onClick={() => {
										setIsMainMenu(true)
										setConfirmDialogOpenAdd(true)
									}}
								>
									Add New Category
									<AddIcon style={{ height: "15px" }} />
								</button>
							</div>

							<MDBox pt={6} pb={3}>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<Card>
											<MDBox
												mx={2}
												mt={-3}
												py={3}
												px={2}
												variant="gradient"
												bgColor="info"
												borderRadius="lg"
												coloredShadow="info"
											>
												<MDTypography variant="h6" color="white">
													Main Menu
												</MDTypography>
											</MDBox>
											<MDBox pt={3}>
												<DataTable
													table={{
														columns: [
															{
																Header: "Category Name",
																accessor: "name",
															},
															{
																Header: "Image",
																accessor: "image",
																align: "center",
															},
															{
																Header: "Sub-Categories",
																accessor: "subcategories",
															},
															{
																Header: "Items",
																accessor: "items",
															},
															{
																Header: "Actions",
																accessor: "actions",
															},
														],
														rows: globalMenu.map((cat) => {
															return {
																name: (
																	<span
																		onClick={() => {
																			setCategoryToUpdate(cat)
																			updateOne(cat, backStack, setBackStack);
																			setIsMainMenu(false);

																		}}
																	>
																		{cat?.name}
																	</span>
																),
																subcategories: cat?.subCategories?.length ?? 0,
																items: cat?.items?.length ?? 0,
																image: (
																	<div
																		style={{
																			height: "50px",
																			width: "50px",
																			borderRadius: "50%",
																			overflow: "hidden",
																			display: "flex",
																			justifyContent: "center",
																			alignItems: "center",
																		}}
																		onClick={() => {
																			setUpdateCategory(cat);
																			setCategoryImageModalOpen(true);
																		}}
																	>
																		{cat?.image && cat?.image != "" ? (
																			<img
																				src={cat.image}
																				alt={cat.name}
																				style={{
																					height: "100%",
																					width: "100%",
																					objectFit: "cover",
																				}}
																			/>
																		) : (
																			<CameraAltRounded fontSize="medium" />
																		)}
																	</div>
																),
																actions: (
																	<Popconfirm
																		title="Delete this Category"
																		description="Please note that every items in the category also be deleted?"
																		onConfirm={()=>deleteCategory(true, cat._id, null)}
																		icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
																	>
																			<Delete
																				style={{
																					cursor: "pointer",
																					height: "18px",
																					color: "#9E5E28",
																				}}
																			/>
																	</Popconfirm>
																),
															};
														}),
													}}
													isSorted={false}
													entriesPerPage={30}
													showTotalEntries={false}
													noEndBorder
													
												/>
											</MDBox>
										</Card>
									</Grid>
								</Grid>
							</MDBox>
						</div>
					</div>
					<AddCategoryDialog
						open={confirmDialogOpenAdd}
						setOpen={setConfirmDialogOpenAdd}
						mainMenu={true}
					/>

				</div>
			) : (
				<UpdateCategory setIsMainMenu={setIsMainMenu} />
			)}
			<CategoryImageUploadModal
				open={categoryImageModalOpen}
				setOpen={setCategoryImageModalOpen}
				image={updateCategory.image}
				categoryId={updateCategory._id}
			/>
		</DashboardLayout>
	);
}

export default MenuView;
