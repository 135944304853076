/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React examples
import DataTable from "examples/Tables/DataTable";

import { DoneRounded, RoomServiceRounded } from "@mui/icons-material";
import logger from "utils/logger";
import { Tab, Tabs } from "@mui/material";

function PopularItems({ items }) {
	// logger("PopularItems.js", items);
	const [menu, setMenu] = useState(null);
	const [isMostSelected, setIsMostSelected] = useState(true);

	const openMenu = ({ currentTarget }) => setMenu(currentTarget);
	const closeMenu = () => setMenu(null);

	const renderMenu = (
		<Menu
			id="simple-menu"
			anchorEl={menu}
			anchorOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			
			open={Boolean(menu)}
			onClose={closeMenu}
		>
			<MenuItem onClick={closeMenu}>Action</MenuItem>
			<MenuItem onClick={closeMenu}>Another action</MenuItem>
			<MenuItem onClick={closeMenu}>Something else</MenuItem>
		</Menu>
	);

	return (
		<Card>
			<MDBox
				display="flex"
				justifyContent="space-between"
				alignItems="center"
				p={3}
			>
				<MDBox>
					<MDTypography variant="h6" gutterBottom>
						Popular Items
					</MDTypography>
				</MDBox>
				<MDBox color="text" px={2} >
					<Tabs
						value={isMostSelected ? 1 : 0}
						onChange={() => setIsMostSelected(!isMostSelected)}
						indicatorColor="primary"
						textColor="primary"
					>
						<Tab
							label="Least Sold"
							value={0}
							sx={{
								marginRight: "4px",
							}}
						/>
						<Tab label="Most Sold" value={1} />
					</Tabs>
				</MDBox>
				{renderMenu}
			</MDBox>
			<MDBox >
				<DataTable
					table={{
						columns: [
							{
								Header: "Name",
								accessor: "name",
							},
							{
								Header: "Total Quantity",
								accessor: "quantity",
							},
							{
								Header: "Price",
								accessor: "price",
							},
							{
								Header: "Total Sold",
								accessor: "sold",
							},
						],
						rows: isMostSelected
							? items.slice(0, 5)
							: items.slice(0).reverse().slice(0, 5),
					}}
					showTotalEntries={false}
					isSorted={false}
					noEndBorder
					entriesPerPage={false}
				/>
			</MDBox>
		</Card>
	);
}

export default PopularItems;
