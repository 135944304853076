import React, { useEffect, useState } from "react";
import styles from "./PurchaseSummary.module.css";
import {
  Button,
  Card,
  Dialog,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import logger from "../../../../utils/logger";
import toast from 'utils/toast';

import axios from "axios";
import { useContext } from "react";
import { inventory } from "../../../../providers/InventoryProvider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

function PurchaseSummary({startDate, endDate}) {
  const inventoryContext = useContext(inventory);



   const bills={}
  inventoryContext.additionBills.forEach(bill=>{
    bills[bill.item._id]={
      quantity: (bills?.[bill.item._id]?.quantity ?? 0) + bill.quantity,
      cost:Number(bills[bill.item._id]?.cost ?? 0) + Number((bill?.cost ?? 0)*(bill?.quantity ?? 0)),
      name:bill.item.name,
      unit:bill.item.unit
    }
  })


  return (
    <MDBox pt={6} pb={3} >
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" display="flex" justifyContent="space-between" color="white">
                <div>
                  Purchase Summary report
                </div>
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {(
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Added Stock",
                        accessor: "addedStock",
                      },
                      {
                        Header: "Unit",
                        accessor: "unit",
                      },
                      {
                        Header: "Cost",
                        accessor: "cost",
                      }
                    ],
                    rows: ((Object.values(bills)) ?? []).map((mat, index) => {
                      return {
                        name: mat?.name ?? "--",
                        addedStock:( mat?.quantity).toFixed(2),
                        unit: mat?.unit,
                        cost: (mat?.cost).toFixed(2)
                      };
                    }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>
  );
}
function PurchaseSummaryOld() {
  const [selectedMaterial, setSelectedMaterial] = useState("");
  const [inventoryBills, setInventoryBills] = useState([]);
  const inventoryContext = useContext(inventory);

  const [billMap, setBillMap] = useState({});

  const fetchInventoryBills = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem(
          "restaurant"
        )}/inventory/inventory-bill/all`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res.data.inventoryBills, "in");
      if (res.status === 200) {
        setInventoryBills(res.data.inventoryBills);
        let billMapCopy = {};
        for (let i = 0; i < res.data.inventoryBills.length; i++) {
          if (billMapCopy[res.data.inventoryBills[i].inventoryItem]) {
            billMapCopy[res.data.inventoryBills[i].inventoryItem].push(
              res.data.inventoryBills[i]
            );
          } else {
            billMapCopy[res.data.inventoryBills[i].inventoryItem] = [
              res.data.inventoryBills[i],
            ];
          }
        }
        setBillMap(billMapCopy);
        // logger(billMapCopy);
      }
    } catch (error) {
      logger("Error in fetching inventoryBills");
      toast.error(error?.response?.data?.message ?? "Network connection failed");
    }
  };

  useEffect(() => { }, [billMap]);

  useEffect(() => { }, [inventoryBills]);

  useEffect(() => {
    fetchInventoryBills();
  }, []);

  const handleMaterialChange = (event) => {
    setSelectedMaterial(event.target.value);
  };

  const materialOptions = ["Material A", "Material B", "Material C"];
  return (
    <div className={styles.mainContainer}>
      <div
        style={{
          width: "100%",
          padding: "10px 10px",
          borderBottom: "1px solid gray",
          fontWeight: "600",
        }}
      >
        Purchase Summary Report
      </div>
      <div
        style={{
          width: "100%",
          padding: "10px 10px",
          display: "flex",
          gap: "10px",
          borderBottom: "1px solid gray",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div>
          <select
            style={{ padding: "5px 10px", fontWeight: "600" }}
            id="material"
            value={selectedMaterial}
            onChange={handleMaterialChange}
          >
            <option value="">Select Raw Material</option>
            {inventoryContext.inventoryItems.map((item, index) => (
              <option key={index} value={item?._id}>
                {inventoryContext.inventoryItemsMap[item?._id]?.name}
              </option>
            ))}
          </select>
        </div>

      </div>
      <div
        style={{
          width: "100%",
          padding: "10px 10px",
          maxHeight: "600px",
          overflowY: "auto",
        }}
      >
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableRow
                sx={{
                  "& .MuiTableCell-root": {
                    backgroundColor: "#cccccc",
                    fontWeight: "bold",
                  },
                }}
              >
                <TableCell>Date</TableCell>
                <TableCell>Added Stock</TableCell>
                <TableCell>Unit</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {billMap[selectedMaterial] &&
                billMap[selectedMaterial].map((mat, index) => {
                  return (
                    <TableRow key={index}>
                      <TableCell>{new Date(mat.createdAt).toLocaleString()}</TableCell>
                      <TableCell>{mat.addedStock}</TableCell>
                      <TableCell>{mat.unit}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default PurchaseSummary;
