import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logger from "../../utils/logger";
import toast from "../../utils/toast";
import {
	Card,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from "@mui/material";
import styles from "./OrderSummaryDetails.module.css";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { restaurant } from "providers/RestaurantProvider";

const OrderSummaryDetails = ({ order }) => {
	const restaurantContext = useContext(restaurant)
	const [modifiedOrder, setModifiedOrder] = useState({})
	useEffect(() => {
		if (order?.billModifiedBy) {
			let modifiedKotsObj = JSON.parse(JSON.stringify(order.kots));
			modifiedKotsObj.forEach((kot, index) => {
				console.log(kot, "myKOT")
				if (kot.oldKotItems) {
					kot.items = kot.oldKotItems?.items ?? [];
					kot.total = kot.oldKotItems?.total ?? 0;
				}
			})
			setModifiedOrder({ ...order, kots: modifiedKotsObj });
		}
	}, [order])
	if (!order) {
		return null;
	}
	const subTotal =
		order.kots?.reduce((acc, kot) => {
			return acc + kot.total;
		}, 0) || 0;
	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);

	const orderItemsRow = [];
	(order?.kots ?? []).map((kot) => kot.items?.map((item) => {
		const properties = item.properties?.map((property) => {
			if (property.options[0]) {
				return (property.name + " :  " + property?.options?.join(", ") ?? "")
			}
			else {
				return (property.name + " :  " + property?.option)
			}
		})
		logger("properties,", properties)
		orderItemsRow.push({
			itemName: item.item.name + (properties?.[0] ? " (" + properties.join(", ") + ")" : ""),
			specialNote: item.note || '--',
			quantity: item.quantity,
			price: ((item.total ?? 0) / item.quantity).toFixed(2),
			total: ((item.total ?? 0)).toFixed(2)
		})
	}))
	const modifiedOrderItemsRow = [];
	(modifiedOrder?.kots ?? []).map((kot) => kot.items?.map((item) => {
		const properties = item.properties?.map((property) => {
			if (property.options[0]) {
				return (property.name + " :  " + property?.options?.join(", ") ?? "")
			}
			else {
				return (property.name + " :  " + property?.option)
			}
		})
		logger("properties,", properties)
		modifiedOrderItemsRow.push({
			itemName: item.item.name + (properties?.[0] ? " (" + properties.join(", ") + ")" : ""),
			specialNote: item.note || '--',
			quantity: item.quantity,
			price: ((item.total ?? 0) / item.quantity).toFixed(2),
			total: ((item.total ?? 0)).toFixed(2)
		})
	}))





	return (
		<div>

			<Card sx={{ marginTop: "50px" }}>
				<MDBox
					// mx={1}
					// mt={-3}
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
						Order Details
					</MDTypography>

				</MDBox>
				<DataTable
					table={{
						columns: [
							{
								Header: "Order No",
								accessor: "orderNo",
							},
							{
								Header: "Date",
								accessor: "created",
							},
							{
								Header: "Order type",
								accessor: "orderType",
							},
							{
								Header: "Status",
								accessor: "status",
							},
							{
								Header: "Bill Settled By",
								accessor: "billSettledBy",
							},
							{
								Header: "Bill Modified By",
								accessor: "billModifiedBy",
							},
							{
								Header: "Payment Modified By",
								accessor: "paymentModifiedBy",
							},
							{
								Header: "Customer Name",
								accessor: "customerName",
							},
							{
								Header: "Customer phone",
								accessor: "customerPhone",
							},
							{
								Header: "No of Person",
								accessor: "noOfPerson",
							},
							{
								Header: "Payment Type",
								accessor: "paymenttype",
							},
							{
								Header: "My Amount",
								accessor: "myAmount",
							},
							{
								Header: "Tax",
								accessor: "tax",
							},
							{
								Header: "Discount",
								accessor: "discout",
							},
							{
								Header: "Total",
								accessor: "total",
							},
						],
						rows: [{
							orderNo: order.orderNumber,
							created: new Date(order.createdAt).toLocaleString(),
							orderType: ((order?.table?.type)?.toUpperCase() ?? "DINEIN") + " - " + (order.table?.name ?? "Temporary"),
							status: order.status,
							billSettledBy: order.billSettledBy?.name,
							billModifiedBy: order.billModifiedBy?.name,
							paymentModifiedBy: order.paymentModifiedBy?.name,
							customerName: order.user?.name,
							customerPhone: order.user?.phone,
							noOfPerson: order.personCount,
							paymenttype: order.payment?.method || "Due",
							myAmount: subTotal.toFixed(2),
							tax: (subTotal * (totalTaxValue / 100)).toFixed(2),
							discount: (order.discount?.amount ?? 0).toFixed(2),
							total: (subTotal * (1 + (totalTaxValue / 100))).toFixed(2),
						}]
					}}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={false}
					noEndBorder
				/>
			</Card>
			{
				order?.payment
					? <Card sx={{ marginTop: "40px" }}>
						<MDBox
					// mx={1}
					// mt={-3}
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
								Payment
							</MDTypography>

						</MDBox>
						<DataTable
							table={{
								columns: [
									{
										Header: "Payment Method",
										accessor: "paymentMethod",
									},
									{
										Header: "Discount",
										accessor: "discount",
									},
									{
										Header: "Tip",
										accessor: "tip",
									},
									{
										Header: "Customer Paid",
										accessor: "customerPaid",
									},

								],
								rows: [
									{
										paymentMethod: order?.payment?.method,
										discount: order?.discount?.amount,
										tip: (order?.payment?.tip ?? 0),
										customerPaid: order?.payment?.customerPaid
									}
								]
							}}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={false}
							noEndBorder
						/>
					</Card> : null
			}
			{
				order?.oldPayment
					? <Card sx={{ marginTop: "40px" }}>
						<MDBox
					// mx={1}
					// mt={-3}
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
								Old Payment
							</MDTypography>

						</MDBox>
						<DataTable
							table={{
								columns: [
									{
										Header: "Payment Method",
										accessor: "paymentMethod",
									},
									{
										Header: "Discount",
										accessor: "discount",
									},
									{
										Header: "Tip",
										accessor: "tip",
									},
									{
										Header: "Customer Paid",
										accessor: "customerPaid",
									},

								],
								rows: [
									{
										paymentMethod: order?.oldPayment?.method,
										discount: (order?.oldPayment?.amount ?? order?.discount?.amount),
										tip: (order?.oldPayment?.tip ?? 0),
										customerPaid: order?.oldPayment?.customerPaid
									}
								]
							}}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={false}
							noEndBorder
						/>
						<MDBox
							maxWidth={"99%"}
							p={2}
							style={{
								overflowX:"scroll",
							}}
							bgColor="white"
							borderRadius="lg"
							color="dark"
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
							
						>
							<MDTypography variant="b" color="dark">
								Payment Modified By : {order?.paymentModifiedBy?.name}
							</MDTypography>
							<MDTypography variant="b"  color="dark">
								Payment Modification Reason : {order?.paymentModificationReason}
							</MDTypography>

						</MDBox>
					</Card> : null
			}

			<Card sx={{ marginTop: "40px" }}>
			<MDBox
					// mx={1}
					// mt={-3}
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
						Bill Items
					</MDTypography>

				</MDBox>
				<DataTable
					table={{
						columns: [
							{
								Header: "Item Name",
								accessor: "itemName",
							},
							{
								Header: "Special Note",
								accessor: "specialNote",
							},
							{
								Header: "Quantity",
								accessor: "quantity",
							},
							{
								Header: "Price",
								accessor: "price",
							},
							{
								Header: "Total",
								accessor: "total",
							},

						],
						rows: orderItemsRow
					}}
					isSorted={false}
					entriesPerPage={false}
					showTotalEntries={false}
					noEndBorder
				/>
				<MDBox
					maxWidth={"99%"}
					p={2}
					style={{
						overflowX:"scroll"
					}}
					bgColor="white"
					borderRadius="lg"
					color="dark"
					display="flex"
					justifyContent="space-between"
					flexDirection="column"

				>
					<MDTypography variant="b" color="dark">
						Grand Total : {(subTotal * (1 + totalTaxValue / 100)).toFixed(2)}
					</MDTypography>
					{order?.cancelReason ? <MDTypography variant="b" color="dark">
						Cancel Reason : {order?.cancelReason}
					</MDTypography> : null}
					{order?.waivedOffReason ? <MDTypography variant="b" color="dark">
						WaivedOff Reason : {order?.waivedOffReason}
					</MDTypography> : null}

				</MDBox>
			</Card>
			{
				order?.billModifiedBy
					? <Card sx={{ marginTop: "40px" }}>
						<MDBox
					// mx={1}
					// mt={-3}
					py={2}
					px={2}
					variant="gradient"
					className="primary-bg"
					borderRadius="lg"
					display="flex"
					justifyContent="space-between"
					alignItems="center"
				>
					<MDTypography variant="h6" color="white">
								Old Bill
							</MDTypography>

						</MDBox>
						<DataTable
							table={{
								columns: [
									{
										Header: "Item Name",
										accessor: "itemName",
									},
									{
										Header: "Special Note",
										accessor: "specialNote",
									},
									{
										Header: "Quantity",
										accessor: "quantity",
									},
									{
										Header: "Price",
										accessor: "price",
									},
									{
										Header: "Total",
										accessor: "total",
									},

								],
								rows: modifiedOrderItemsRow
							}}
							isSorted={false}
							entriesPerPage={false}
							showTotalEntries={false}
							noEndBorder
						/>
						<MDBox
							maxWidth={"99%"}
							p={2}
							style={{
								overflowX:"scroll"
							}}
							bgColor="white"
							borderRadius="lg"
							color="dark"
							display="flex"
							flexDirection="column"
							justifyContent="space-between"
						>
							<MDTypography variant="b" color="dark">
								Grand Total : {((
									(modifiedOrder?.kots ?? []).reduce((acc, kot) => {
										return acc + (kot.total ?? 0)
									}, 0)
								) * (1 + totalTaxValue / 100)).toFixed(2)}
							</MDTypography>
							<MDTypography variant="b" color="dark">
								Bill Modified By : {order?.billModifiedBy?.name}
							</MDTypography>
							<MDTypography variant="b" color="dark">
								Bill Modification Reason : {order?.billModificationReason}
							</MDTypography>

						</MDBox>
					</Card> : null
			}


		</div>
	);
};

export default OrderSummaryDetails;
