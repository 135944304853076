import React, { useEffect, useState, useContext } from "react";
import { admin } from "providers/AdminProvider";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RiUserLine } from "react-icons/ri";

function RunningTableCard({
	tableOrder,
	showPersons,
	showTableDetail,
	showOrderDetail,
	color = "info",
	title,
	description,
	value,
}) {
	const currentTime = new Date();
	const timeDifference = currentTime - new Date(tableOrder.createdAt);
	const minutesDifference = Math.floor(timeDifference / (1000 * 60));
	let [total, setTotal] = useState(0);

	const context = useContext(admin);

	const getDuration = () => {
		const utcNow = new Date().getTime();
		const utcOrder = new Date(tableOrder.createdAt).getTime();
		const diff = utcNow - utcOrder;
		const minutes = Math.max(0, Math.floor(diff / 1000 / 60));
		const hours = Math.floor(minutes / 60);

		if (!minutes) {
			return "Just Updated";
		}

		if (minutes === 0) {
			return "Just Started";
		}
		if (hours > 0) {
			return `${hours}h:${minutes % 60}m`;
		} else {
			return `${minutes}m`;
		}
	};

	const getTotal = () => {
		let temp = 0;
		tableOrder.kots
			.map((kotID) => context.runningKOTs[kotID])
			.forEach((k) => {
				temp = temp + k.total;
			});
		setTotal(temp);
	};

	useEffect(() => {
		getTotal();
	}, [tableOrder]);

	return (
		<Card
			sx={{
				margin: "4px",
			}}
		>
			<MDBox
				p={2}
				mx={3}
				display="flex"
				justifyContent="center"
				style={{cursor:"pointer"}}
				onClick={() => {
					showTableDetail(tableOrder);
				}}
			>
				<MDBox
					display="grid"
					justifyContent="center"
					alignItems="center"
					bgColor={color}
					color="white"
					width="5rem"
					height="5rem"
					shadow="md"
					borderRadius="lg"
					variant="gradient"
					
				>
					<MDTypography color="white" textAlign="center">
						{tableOrder.table.name}
					</MDTypography>
					<MDTypography color="white" textAlign="center" fontSize="13px">
						{getDuration()}
					</MDTypography>
				</MDBox>
			</MDBox>
			<MDBox pb={2} px={2} textAlign="center" lineHeight={1.25}>
				<MDTypography
					variant="h6"
					fontWeight="medium"
					textTransform="capitalize"
				>
					KOT(s) : {tableOrder.kots?.length ?? 0}
				</MDTypography>

				<MDTypography
					variant="body2"
					color="text"
					fontWeight="regular"
					display="flex"
					alignItems="center"
					cursor="pointer"
					
				>
					PAX : {tableOrder.personCount}
				</MDTypography>
				<div style={{ display: "flex", justifyContent:"space-between" }}>

					<MDTypography
						variant="body2"
						color="text"
						fontWeight="regular"
						display="flex"
						alignItems="center"
						cursor="pointer"
						
					>
						Assigned : {tableOrder.captain?.name ?? "--"}
					</MDTypography>
					<RiUserLine fontWeight={600} curso	r={"pointer"} onClick={() => {
						showPersons(tableOrder);
					}} />

				</div>
				{description && !value ? null : <Divider />}
				<MDTypography variant="h5" fontWeight="medium">
					₹ {total.toFixed(2)}
				</MDTypography>
			</MDBox>
		</Card>
	);
}

export default RunningTableCard;
