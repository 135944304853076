import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import { Card, Grid, IconButton } from "@mui/material";
import styles from "./onlineOrders.module.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { RiEdit2Fill } from "react-icons/ri";
import EditAggregator from "./EditAggregator";
import AddAggregator from "./AddAggregator";
import commonStyles from '../../styles.module.css'
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { Delete } from "@mui/icons-material";

const OnlineOrders = () => {
	const [aggregators, setAggregators] = useState([]);
	const [aggregator, setAggregator] = useState({})
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	const fetchData = async () => {
		return axios.get(
			`/restaurants/${localStorage.getItem(
				"restaurant"
			)}/onlineOrders`,
			{
				headers: {
					"x-auth-token": localStorage.getItem("token"),
				},
			}
		).then(res => {
			console.log(res.data)
			setAggregators(res.data);
		}).catch(err => {
			console.log(err)
		})
	}

	const deleteAggregator=async (id)=>{
		try{
			const res = await axios.delete(
				`/restaurants/${localStorage.getItem("restaurant")}/onlineOrders/${id}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			)
			fetchData()
			return res;
		}catch(err){
			throw new Error(err)
		}
	}

	useEffect(() => {
		const callFunction = fetchData();
		toast.promise(callFunction, {
			loading: "Fetching Aggregators",
			error: "Fetching Aggregators Failed",
			success: "Fetched Successfully"
		});
	}, [startDate, endDate]);

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.onlineOrders} />
			<DashboardNavbar
				showDateFilter
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startDate={startDate}
				endDate={endDate}
			/>
			<div
			>
				<div className={styles.topHeadingContainer}>
					<button
						className={commonStyles.primary_button}
						onClick={() => {
							setIsAddDialogOpen(true);
						}}
					>
						Add Aggregator
					</button>
				</div>
				<div className={styles.mainContainer}>
					<MDBox pt={6} pb={3}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card>
									<MDBox
										mx={2}
										mt={-3}
										py={3}
										px={2}
										variant="gradient"
										className="primary-bg"
										borderRadius="lg"
									>
										<MDTypography variant="h6" color="white">
											Food Aggregators
										</MDTypography>
									</MDBox>
									<MDBox pt={3}>
										{aggregators.length > 0 && (
											<DataTable
												table={{
													columns: [
														{
															Header: "No",
															accessor: "no",
														},
														{
															Header: "Aggregator",
															accessor: "aggregator",
														},
														{
															Header: "Restaurant ID",
															accessor: "restaurantID",
														},
														{
															Header: "Commission",
															accessor: "commission",
														},
														{
															Header: "Actions",
															accessor: "actions",
														},
													],
													rows: (aggregators ?? []).map((ag, index) => {
														return {
															no: index + 1,
															aggregator: ag?.type,
															restaurantID: ag.restaurantID,
															commission: ag.commission ?? 0,
															actions: (
																<div className={styles.actions} style={{display:"flex", gap:"10px"}}>
																	<RiEdit2Fill
																		style={{ cursor: "pointer" }}
																		onClick={() => {
																			setAggregator(ag);
																			setIsDialogOpen(true);
																		}}
																	></RiEdit2Fill>
																	<Popconfirm
																		title="Delete this Category"
																		description="Please note that every items in the category also be deleted?"
																		onConfirm={()=>deleteAggregator(ag._id)}
																		icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
																	>
																			<Delete
																				style={{
																					cursor: "pointer",
																				}}
																			/>
																	</Popconfirm>
																</div>
															),
														};
													}),
												}}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												noEndBorder
												canSearch
											/>
										)}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
					<EditAggregator
						aggregator={aggregator}
						open={isDialogOpen}
						setOpen={setIsDialogOpen}
						refresh={fetchData}
					/>
					<AddAggregator
						open={isAddDialogOpen}
						setOpen={setIsAddDialogOpen}
						refresh={fetchData}
					/>
				</div>
			</div>
		</DashboardLayout>
	);
}

export default OnlineOrders;
