import React, { useEffect, useState } from "react";
import styles from "./ConsumptionReport.module.css";
import {
  Button,
  Card,
  Dialog,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import toast from 'utils/toast';

import logger from "../../../../utils/logger";
import { useContext } from "react";
import { inventory } from "../../../../providers/InventoryProvider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";

function ConsumptionReport({ startDate, endDate }) {
  const inventoryContext = useContext(inventory);

  const bills = {}
  inventoryContext.consumptionBills.forEach(bill => {
      bills[bill.item._id] = {
        quantity: (bills?.[bill.item._id]?.quantity ?? 0) + bill.quantity,
        cost: bill.cost ?? 0,
        name: bill.item.name,
        unit: bill.item.unit
      }
  })

  return (

    <MDBox pt={6} pb={3}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card>
            <MDBox
              mx={2}
              mt={-3}
              py={3}
              px={2}
              variant="gradient"
              bgColor="info"
              borderRadius="lg"
              coloredShadow="info"
            >
              <MDTypography variant="h6" color="white">
                Consumption Report (<i>Raw Material Wise</i>)
              </MDTypography>
            </MDBox>
            <MDBox pt={3}>
              {(
                <DataTable
                  table={{
                    columns: [
                      {
                        Header: "Item",
                        accessor: "item",
                      },
                      {
                        Header: "Quantity",
                        accessor: "quantity",
                      },
                      {
                        Header: "Unit",
                        accessor: "unit",
                      },
                      {
                        Header: "Cost",
                        accessor: "cost",
                      }

                    ],
                    rows: (Object.values(bills) ?? []).map((bill, key) => {
                      return {
                        item: bill?.name,
                        quantity: (bill?.quantity).toFixed(2),
                        unit: bill?.unit,
                        cost: ((bill?.cost ?? 0) * (bill.quantity ?? 0)).toFixed(2),

                      };
                    }),
                  }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries={false}
                  noEndBorder
                />
              )}
            </MDBox>
          </Card>
        </Grid>
      </Grid>
    </MDBox>

  );
}

export default ConsumptionReport;
