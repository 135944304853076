/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import styles from "./rawMaterial.module.css";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import {
    Dialog,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { admin } from "providers/AdminProvider";
import axios from "axios";
import { restaurant as restaurantData } from "../../../providers/RestaurantProvider";
import logger from "utils/logger";
import toast from "utils/toast";
import { ClipLoader } from "react-spinners";
import { CheckBox } from "@mui/icons-material";
import { inventory } from "providers/InventoryProvider";
// import BasicLayout from "../../../components/";

function EditInventoryItemDialog({ open, item, setOpen }) {
    const [isLoading, setIsLoading] = useState(false)
    const navigate = useNavigate();
    const inventoryContext = useContext(inventory);
    const [availability, setAvailability] = useState();
    const handleAvailabilityChange = (e) => {
        setAvailability(e.target.checked);
    };
    // const [units, setUnits] = useState(["kg", "gram", "litre"]);
    const [name, setName] = useState(item?.name);

    const [cost, setCost] = useState(item?.cost);



    const saveHandler = async (e) => {
        e.preventDefault();
        try {
            let data = {
                name: name,
                cost: cost,
                available: availability,
            };
            const res = await axios.put(
                `/restaurants/${localStorage.getItem("restaurant")}/inventory/${item?._id
                }`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            logger(res);
            if (res.status === 200) {
                toast.success("Successfully Updated", {
                    autoClose: 2000,
                });
                let itemCopy = [...inventoryContext.inventoryItems];
                for (let i = 0; i < itemCopy.length; i++) {
                    if (itemCopy[i]._id === item?._id) {
                        itemCopy[i] = res.data;
                        break;
                    }
                }
                inventoryContext.setInventoryItems(itemCopy);
                setOpen(false);
            }
            return res
        } catch (error) {
            logger(error);
            toast.error(error?.response?.data?.message ?? "Network connection failed", {
                autoClose: 2000,
            });
        }
    };
    useEffect(() => {
        if (item) {
            setName(item.name);
            setAvailability(item.available)
            setCost(item.cost)
        }
    }, [item])

    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "600px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={0}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Edit Inventory Item
                        </MDTypography>

                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    type="text"
                                    label="Name"
                                    name="name"
                                    value={name}
                                    onChange={(e) => {
                                        setName(e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />

                            </MDBox>

                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    name="cost"
                                    type="number"
                                    value={cost}
                                    onChange={(e) => {
                                        setCost(e.target.value < 0 ? 0 : e.target.value);
                                    }}
                                    label="Cost per unit"
                                    variant="standard"
                                    fullWidth
                                />

                            </MDBox>
                            <MDBox mb={2} className={styles.add_user_form_row}>

                                <input
                                    type="checkbox"
                                    checked={availability}
                                    onChange={handleAvailabilityChange}
                                    variant="standard"
                                    id="availability"
                                />
                                <label htmlFor="availability">Availability</label>
                            </MDBox>


                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={(e) => {
                                        const callFunction = saveHandler(e);
                                        toast.promise(callFunction, {
                                            loading: "Updating Inventory Item...",
                                            error: "Updating Inventory Item Failed",
                                            success: "Inventory Item Updated Successfully"
                                        });
                                    }}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default EditInventoryItemDialog;
