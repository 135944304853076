import React, { useState } from 'react'
import styles from './Settings.module.css'
import { Button, TextField } from '@mui/material'
import toast from 'utils/toast';
import axios from 'axios';
import logger from 'utils/logger';
import { ClipLoader } from 'react-spinners';

export default function ResetPassword() {
    const [oldPassword, setOldPassword] = useState("");
	const [newPassword, setNewPassword] = useState("");
	const [confirmPassword, setConfirmPassword] = useState("");
    const [isLoading, setIsLoading] = useState(false)
    
        const handleReset = async () => {
            try {
                setIsLoading(true)
                if (newPassword.trim()==="") {
                    toast.error("All fields are required");
                    setIsLoading(false)
                    return;
                }
                if (newPassword !== confirmPassword) {
                    toast.error("Passwords do not match");
                    setIsLoading(false)
                    return;
                }
    
                const res = await axios.post(
                    "/restaurants/staff/reset-password",
                    {
                        oldPassword,
                        newPassword,
                    },
                    {
                        headers: {
                            "x-auth-token": localStorage.getItem("token"),
                        },
                    }
                );
    
                toast.success("Password reset successfully");
                setIsLoading(false)
                
                setConfirmPassword("");
                setNewPassword("");
                setOldPassword("");
            } catch (error) {
                setIsLoading(false)
                logger(error);
                toast.error(error.response?.data?.message ?? "Network connection failed");
            }
        };
    return (
        <div>
            <div className={styles.settings_header}>
                <h4>Reset password</h4>
            </div>
            <div className={styles.reset_container}>
                
                <TextField
                    type='password'
                    label={"Current Password"}
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
                <TextField
                    type='password'
                    label={"New Password"}
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
                <TextField
                    type='password'
                    label={"Confirm New Password"}
                    className={styles.reset_input}
                    variant='outlined'
                ></TextField>
            </div>
            <div className={styles.settings_footer}>
                <Button onClick={handleReset} variant='contained' className="primary-bg" sx={{width:"150px", marginTop:"10px", color:"white"}}>
                    Save
                    <span style={{marginLeft:"10px"}}>
                    <ClipLoader loading={isLoading} size={10} color='white'></ClipLoader>
                    </span>
                </Button>
            </div>

        </div>
    )
}
