import React, { useContext } from 'react'
import styles from './Settings.module.css'
import { Button, TextField } from '@mui/material'
import { restaurant } from 'providers/RestaurantProvider'

export default function TwoFactorAuth() {
    const restaurantContext = useContext(restaurant)
    const admin= restaurantContext.restaurant.staff.find((user)=>user.role==="ADMIN")
    return (
        <div>
            <div className={styles.settings_header}>
                <h4>Two Factor Authentication</h4>
            </div>
            <div className={styles.reset_container}>
                
                coming soon...
            </div>
            <div className={styles.settings_footer}>
                {/* <Button variant='contained' sx={{width:"150px", marginTop:"10px", color:"white"}}>Save</Button> */}
            </div>

        </div>
    )
}
