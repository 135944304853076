import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { restaurant } from "../../providers/RestaurantProvider";
import { DatePicker } from "antd";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import styles from './OrderReport.module.css'
import DataTable from "examples/Tables/DataTable";
import toast from "utils/toast";
import axios from "axios";
import exportToExcel from "utils/exportToExcel";
import { RiShareBoxFill, RiUpload2Fill } from "react-icons/ri";


function OrderReport() {
    const restaurantContext = useContext(restaurant)
    const [todayOrders, setTodayOrders] = useState([])
    // const { departments, refreshDepartments } = useContext(printer)
    const [startDate, setStartDate] = useState(
        new Date(new Date().setHours(0, 0, 0, 0))
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setHours(23, 59, 59, 999))
    );
    const categoryMapItem = {}
    const departmentMapItem = {}
    const itemWiseReport = {}
    const checkMenuRecursively = (menuItem, menuName) => {
        (menuItem.items ?? []).forEach((item) => {
            categoryMapItem[item] = menuName
        })
        if (menuItem.subCategories) {
            (menuItem.subCategories ?? []).forEach((subC) => {
                checkMenuRecursively(subC, menuName)
            })
        }
    }
    const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
        (acc, item) => {
            return acc + item.value;
        },
        0
    );
    let myTotalAmount = 0, totalOrders = 0, totalContainerCharge = 0, totalDeliveryCharge = 0, totalDiscount = 0, totalCustomerPaid = 0, totalTip = 0;
    let categoryWiseReport = {}, departmentWiseReport = {}, paymentWiseReport = {}, orderTotalAmount = 0, groupWiseReport = {
        Food: 0,
        Drink: 0
    };

    restaurantContext.restaurant.menu.forEach(menuItem => {
        console.log(menuItem, "menuItem")
        checkMenuRecursively(menuItem, menuItem.name)
    })

    // Object.values(departments ?? {}).forEach((department) => {
    // 	department.items.forEach(item => {
    // 		if (!departmentMapItem[item]) {
    // 			departmentMapItem[item] = new Set([department.name])
    // 		}
    // 		departmentMapItem[item].add(department.name)
    // 	})
    // })
    let paymentTotal = 0;

    for (let order of todayOrders ?? []) {
        if (order.status === "settled") {
            totalContainerCharge += order?.containerCharge ?? 0;
            totalOrders++;
            totalDeliveryCharge += order?.deliveryCharge ?? 0;
            totalDiscount += order?.discount?.amount ?? 0;
            totalCustomerPaid += order?.payment?.customerPaid ?? 0;
            paymentTotal += order?.payment?.customerPaid ?? 0;
            totalTip += order?.payment?.tip ?? 0;

            if (order.payment?.method === "Cash") {
                paymentWiseReport['Cash'] = (paymentWiseReport['Cash'] ?? 0) + order.payment.customerPaid;
            } else if (order.payment?.method === "Card") {
                paymentWiseReport['Card'] = (paymentWiseReport['Card'] ?? 0) + order.payment.customerPaid;
            } else if (order.payment?.method === "Part") {
                order.payment.parts.forEach((payment) => {
                    if (payment?.method === "Cash") {
                        paymentWiseReport['Cash'] = (paymentWiseReport['Cash'] ?? 0) + payment.amount ?? 0;
                    } else if (payment?.method === "Card") {
                        paymentWiseReport['Card'] = (paymentWiseReport['Card'] ?? 0) + payment.amount ?? 0;
                    } else if (payment?.method === "Other") {
                        const keyName = 'Other (' + (payment.otherMethod ?? "UPI") + ")"
                        paymentWiseReport[keyName] = (paymentWiseReport[keyName] ?? 0) + payment.amount ?? 0;
                    }
                })
            } else if (order.payment?.method === "Other") {
                const keyName = 'Other (' + (order.payment.otherMethod ?? "UPI") + ")"
                paymentWiseReport[keyName] = (paymentWiseReport[keyName] ?? 0) + order.payment.customerPaid;
            }
            // }
            // if (order.status !== "cancelled") {
            for (let kot of order?.kots ?? []) {
                myTotalAmount += kot.total ?? 0
                orderTotalAmount += (kot.total ?? 0) * (1 + totalTaxValue / 100)
                kot?.items?.forEach((item) => {
                    let additionalPrice = 0;
                    if(item?.item?._id){

                        if (item.properties) {
                            item.properties.forEach((property) => {
                            let selectedProperty = item.item.properties.find(
                                (p) => p.name === property.name
                            );
                            let optPrice = 0;
                            if (selectedProperty?.hasMultipleSelection) {
                                selectedProperty.options.forEach((option) => {
                                    if (option.name === property.option) {
                                        optPrice += option.price ?? 0
                                    }
                                })
                            } else {
                                const opt = selectedProperty?.options.find((opt) => opt.name === property.option);
                                optPrice = opt?.price ?? 0
                            }
                            additionalPrice += optPrice ?? 0;
                        });
                    }
                    let itemTotalPrice = (item?.item?.price ?? 0) + additionalPrice;
                    itemTotalPrice = (itemTotalPrice * item.quantity) * (1 + totalTaxValue / 100);
                    if (item?.item?.isBar) {
                        groupWiseReport.Drink += itemTotalPrice;
                    } else {
                        groupWiseReport.Food += itemTotalPrice;
                    }
                    if (categoryMapItem[item.item._id]) {
                        categoryWiseReport[categoryMapItem[item.item._id]] = {
                            total: (categoryWiseReport[categoryMapItem[item.item._id]]?.total ?? 0) + itemTotalPrice,
                            quantity: (categoryWiseReport[categoryMapItem[item.item._id]]?.quantity ?? 0) + item.quantity
                        };
                    } else {
                        categoryWiseReport["Others"] = {
                            total: (categoryWiseReport["Others"]?.total ?? 0) + itemTotalPrice,
                            quantity: (categoryWiseReport["Others"]?.quantity ?? 0) + item.quantity
                        };

                    }
                    if (departmentMapItem?.[item?.item?._id]) {
                        (departmentMapItem?.[item?.item?._id] ?? []).forEach(departmentName => {
                            departmentWiseReport[departmentName] = {
                                total: (departmentWiseReport[departmentName]?.total ?? 0) + itemTotalPrice,
                                quantity: (departmentWiseReport[departmentName]?.quantity ?? 0) + item.quantity
                            }
                        })
                    } else {
                        departmentWiseReport["Others"] = {
                            total: (departmentWiseReport["Others"]?.total ?? 0) + itemTotalPrice,
                            quantity: (departmentWiseReport["Others"]?.quantity ?? 0) + item.quantity
                        }
                    }
                    itemWiseReport[item?.item?._id] = {
                        total: (itemWiseReport[item?.item?._id]?.total ?? 0) + itemTotalPrice,
                        quantity: (itemWiseReport[item?.item?._id]?.quantity ?? 0) + item.quantity,
                        name: item?.item?.name ?? "--"
                    }
                }
                });
            }
        }
    }
    const grandTotal = (((myTotalAmount - totalDiscount) * (1 + totalTaxValue / 100)) + totalContainerCharge + totalDeliveryCharge)
    const DownloadItemReport = () => {
		exportToExcel(
			"Itemwise report",
			[
				[
					"Item Name",
					"Quatity",
					"Total"
				],
				...(Object.values(itemWiseReport ?? {}).map(
                    (item) => {
                        return [item?.name,item?.quantity, item?.total?.toFixed(2)]
                    }
                ) ?? []),
			],
			startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
		);
	};

    const fetchOrders = async () => {
        const todayTimestamp = new Date().setHours(0, 0, 0, 0);

        try {
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );


            const orders = res.data;
            setTodayOrders([...orders ?? []])
            return res


        } catch (error) {
            console.log(error);
            toast.error(error.response?.data?.message ?? "Error fetching orders", {
                autoClose: 2000,
            });
        }
    };
    const { RangePicker } = DatePicker;

    useEffect(() => {
        const callFunction = fetchOrders();
        toast.promise(callFunction, {
            loading: "Fetching Reports",
            error: "Fetching Reports Failed",
            success: "Reports Refreshed Successfully"
        });
    }, [startDate, endDate]);

    const handleRangeChange = (dateArr) => {
        const start = new Date(new Date(dateArr[0]))
        const end = new Date(new Date(dateArr[1]))
        setStartDate(start);
        setEndDate(end);
    };

    return (
        <DashboardLayout>
            <Sidenav className="animate" routes={routes.main} />
            <DashboardNavbar
            showDateFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            />
            <div className={styles.mainReport}>

                <div className={styles.inputHead}>
                    <div className={styles.inputItem}>
                        <h3>Order Reports</h3>
                        {/* <RangePicker
                            className={styles.myRangePicker}
                            onChange={handleRangeChange}
                            style={{ border: "none", outline: "none" }}
                            size="large"
                            showTime
                        /> */}
                    </div>
                </div>
                <Accordion className={styles.accordion}>
                    <AccordionSummary >Sales Wise Report</AccordionSummary>
                    <AccordionDetails >
                        <DataTable
                            table={{
                                columns: [
                                    {
                                        Header: "Type",
                                        accessor: "type",
                                    },
                                    {
                                        Header: "Value",
                                        accessor: "value",
                                    }
                                ],
                                rows: [
                                    {
                                        type: "Date",
                                        value: <div style={{display:"flex", flexDirection:"column"}}>
                                            <span>
                                            {new Date(startDate).toLocaleDateString()} 
                                            -
                                            </span>
                                            <span>
                                            {new Date(endDate).toLocaleDateString()}
                                            </span>

                                        </div>
                                    },
                                    {
                                        type: "Orders",
                                        value: totalOrders
                                    },
                                    {
                                        type: "My Amount",
                                        value: myTotalAmount.toFixed(2)
                                    },
                                    {
                                        type: "Discount",
                                        value: totalDiscount
                                    },
                                    {
                                        type: "Delivery Charge",
                                        value: totalDeliveryCharge
                                    },
                                    {
                                        type: "Container Charge",
                                        value: totalContainerCharge
                                    },
                                    {
                                        type: "Tax",
                                        value: (myTotalAmount * (totalTaxValue / 100)).toFixed(2)
                                    },
                                    {
                                        type: "Grand Total",
                                        value: grandTotal.toFixed(2)
                                    },
                                    {
                                        type: "Customer Paid",
                                        value: totalCustomerPaid?.toFixed(2)
                                    },
                                    {
                                        type: "Tip",
                                        value: totalTip
                                    },
                                ],
                            }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>Payment Wise Report</AccordionSummary>
                    <AccordionDetails>
                    <DataTable
                            table={{
                                columns: [
                                    {
                                        Header: "Payment Type",
                                        accessor: "paymentName",
                                    },
                                    {
                                        Header: "Total",
                                        accessor: "total",
                                    }
                                ],
                                rows: Object.keys(paymentWiseReport ?? {}).map(
                                    (payment) => {
                                        return {paymentName:payment, total:paymentWiseReport[payment]?.toFixed(2)}
                                    }
                                ),
                            }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>Category Wise Report</AccordionSummary>
                    <AccordionDetails>
                    <DataTable
                            table={{
                                columns: [
                                    {
                                        Header: "Category Name",
                                        accessor: "name",
                                    },
                                    {
                                        Header: "Quantity",
                                        accessor: "quantity",
                                    },
                                    {
                                        Header: "Total",
                                        accessor: "total",
                                    }
                                ],
                                rows: Object.keys(categoryWiseReport ?? {}).map(
                                    (categoryName) => {
                                        return {name:categoryName,quantity:categoryWiseReport[categoryName]?.quantity, total:categoryWiseReport[categoryName]?.total?.toFixed(2)}
                                    }
                                ),
                            }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>Group Wise Report</AccordionSummary>
                    <AccordionDetails>
                    <DataTable
                            table={{
                                columns: [
                                    {
                                        Header: "Group Name",
                                        accessor: "name",
                                    },
                                    {
                                        Header: "Total",
                                        accessor: "total",
                                    }
                                ],
                                rows: Object.keys(groupWiseReport ?? {}).map(
                                    (name) => {
                                        return {name:name, total:groupWiseReport[name]?.toFixed(2)}
                                    }
                                ),
                            }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            noEndBorder
                        />
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary>
                    <div style={{display:"flex",width:"100%", justifyContent:"space-between"}}>
                        <span>Itemwise Report</span>
                            <RiUpload2Fill onClick={DownloadItemReport}></RiUpload2Fill>
                    </div>
                    </AccordionSummary>
                    <AccordionDetails>
                    <DataTable
                            table={{
                                columns: [
                                    {
                                        Header: "Item Name",
                                        accessor: "name",
                                    },
                                    {
                                        Header: "Quantity",
                                        accessor: "quantity",
                                    },
                                    {
                                        Header: "Total",
                                        accessor: "total",
                                    }
                                ],
                                rows: Object.values(itemWiseReport ?? {}).map(
                                    (item) => {
                                        console.log("itemWiseReport", item)
                                        return {name:item?.name,quantity:item?.quantity, total:item?.total?.toFixed(2)}
                                    }
                                ),
                            }}
                            isSorted={false}
                            entriesPerPage={false}
                            showTotalEntries={false}
                            canSearch
                            noEndBorder
                        />
                    </AccordionDetails>
                </Accordion>
            </div>
        </DashboardLayout>
    );
}

export default OrderReport;
