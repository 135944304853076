import { LinearProgress } from '@mui/material'
import { admin } from 'providers/AdminProvider'
import React, { useContext } from 'react'

export default function LoaderComponent() {
    const {loading} = useContext(admin)
    return (
        <div style={{maxWidth:"100vw",width:"100%", overflow:"hidden", position:"fixed", top:"0px", zIndex:"999"}}>
        {
            loading && <></>
            // <LinearProgress/>
        }
        </div>
    )
}
