/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useContext } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";

import toast from "utils/toast";
import axios from "axios";
import logger from "utils/logger";
import vibrator from "utils/vibrator";
import { restaurant } from "providers/RestaurantProvider";
import { inventory } from "providers/InventoryProvider";

import shakespeareLogo from "assets/images/shakespeare_logo.png";
import { Checkbox, IconButton, Input, TextField } from "@mui/material";
import { VisibilityOffRounded, VisibilityRounded } from "@mui/icons-material";
import { ClipLoader } from "react-spinners";

function Basic() {
	const [isLoading, setIsLoading] = useState(false)

  const [rememberMe, setRememberMe] = useState(false);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const navigate = useNavigate();

  const restaurantContext = useContext(restaurant);
  const inventoryContext = useContext(inventory);

  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (e) => {
    e.preventDefault();
    vibrator.tap();
    if (!email || !password) {
      toast.error("Please enter Email and Password");
      return;
    }
    try {
		setIsLoading(true)
      const res = await axios.post("/restaurants/staff/login", {
        email,
        password,
        role: "ADMIN",
      });
      localStorage.setItem("token", res.data.token);
      localStorage.setItem("email", email);
      axios.defaults.headers.common["x-auth-token"] = res.data.token;
    //   if (!res.data.staff.restaurant) {
    //     // toast.error(
    //     //   "You have not been assigned to any restaurant yet, please contact your admin"
    //     // );
    //     navigate("/new-restaurant", { replace: true });
    //     return;
    //   }
      localStorage.setItem("restaurant", res.data.staff.restaurant);
      const restaurantRes = await axios.get(
        `/restaurants/${res.data.staff.restaurant}/unmorphed`
      );
      restaurantContext.setRestaurant(restaurantRes.data.restaurant);
      restaurantContext.setItems(restaurantRes.data.items);
      restaurantContext.SetcategoryWiseItem(restaurantRes.data.restaurant);
      inventoryContext.fetchInventoryItems();
      inventoryContext.setInventoryItemsMap();
	  setIsLoading(false)
      navigate("/dashboard", { replace: true });
    } catch (error) {
      logger(error);
	  setIsLoading(false)
      if (error.response?.status === 400) {
        toast.error("Invalid Email or Password");
      } else {
        toast.error(
          error?.response?.data?.message ?? "Network connection failed"
        );
      }
    }
  };

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  return (
    <BasicLayout image={bgImage}>
      <Card
        sx={{
          "&.MuiPaper-root": {
            background: "transparent",
            boxShadow: "none",
            backdropFilter: "blur(8px)",
            border: "2px solid #ffffffaa",
          },
        }}
      >
        <img
          src={shakespeareLogo}
          alt="Shakespeare Logo"
          style={{
            width: "60px",
            position: "relative",
            left: "50%",
            transform: "translateX(-50%)",
            marginTop: "20px",
          }}
        />
        <MDTypography
          variant="h4"
          fontWeight="medium"
          color="white"
          mt={1}
          style={{
            position: "relative",
            width: "100%",
            zIndex: "1",
            textAlign: "center",
            color: "white",
            textShadow: "white 0px 1px 6px",
            fontWeight: "100",
            fontFamily: "Poppins, sans-serif",
            fontSize: "32px",
          }}
        >
          Welcome
        </MDTypography>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput
                type="email"
                label="Email"
                className="signinInputBox"
                style={{color:"white"}}
                fullWidth
                onChange={(e) => setEmail(e.target.value)}
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput
                type={showPassword ? "text" : "password"}
                label="Password"
                style={{color:"white"}}
                className="signinInputBox"
                fullWidth
                onChange={(e) => setPassword(e.target.value)}
              />
              <IconButton
                size="small"
                disableRipple
                style={{
                  position: "absolute",
                  right: "30px",
                  marginTop: "8px",
                }}
              >
                {showPassword ? (
                  <VisibilityOffRounded
                    color="white"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                ) : (
                  <VisibilityRounded
                    color="white"
                    onClick={() => setShowPassword(!showPassword)}
                  />
                )}
              </IconButton>
            </MDBox>
            <MDBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <MDBox
                display="flex"
                alignItems="center"
                ml={-1}
                onClick={() => {
                  handleSetRememberMe();
                }}
              >
                <Checkbox
                  checked={rememberMe}
                  onChange={handleSetRememberMe}
                  sx={{
                    transform: "scale(0.75)",
                  }}
                />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  onClick={handleSetRememberMe}
                  sx={{
                    cursor: "pointer",
                    color:"rgb(145, 144, 144)",
                    userSelect: "none",
                    ml: -1,
                    fontSize: "13px",
                  }}
                >
                  &nbsp;Remember me
                </MDTypography>
              </MDBox>
              {/* <MDTypography
                color="text"
                variant="button"
                sx={{
                  fontSize: "13px",
                  fontWeight: "400",
                  color:"rgb(145, 144, 144)",
                  cursor: "pointer",
                }}
              >
                Forgot Password?
              </MDTypography> */}
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                style={{
                  backgroundImage:
                    "linear-gradient(90deg,rgba(196,187,34,.3) 0,rgba(25,201,255,.3))",
                  backgroundColor: "white",
                  display: "block",
                  borderRadius: "100px",
                  padding: "10px 40px",
                  color: "black",
                  boxShadow: "0 0 8px #fff",
                  fontSize: "16px",
                  fontWeight: "400",
                  fontFamily: "Poppins, sans-serif",
                  textTransform: "none",
                  position: "relative",
                  left: "50%",
                  transform: "translateX(-50%)",
                }}
                onClick={handleLogin}
              >
                Sign In
                <ClipLoader
                  className="ms-1"
                  loading={isLoading}
                  size={10}
                  color="black"
                />
              </MDButton>
            </MDBox>
            {/* <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" style={{color:'rgb(145, 144, 144)'}}>
                Don&apos;t have an account?{" "}
                <MDTypography
                  component={Link}
                  to="/auth/register"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MDTypography>
              </MDTypography>
            </MDBox> */}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default Basic;
