import React, { useEffect } from "react";
import styles from "./StaffUser.module.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import logger from "../../../utils/logger";
import commonStyles from "styles.module.css";

import {
	Button,
	Card,
	Dialog,
	Grid,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import { useContext } from "react";
import { admin } from "../../../providers/AdminProvider";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import AddUserDialog from "./AddUserDialog";
import EditUserDialog from "./EditUserDialog";
import ViewUserDialog from "./ViewUserDialog";
function StaffUser({ staff }) {
	const navigate = useNavigate();
	const params = useParams();

	const adminContext = useContext(admin);
	const { setEditUser, editUser, rightsOptions } = adminContext;
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [user, setUser] = useState(null);
	const [addUserDialogOpen, setAddUserDialogOpen] = useState(false)
	const [editUserDialogOpen, setEditUserDialogOpen] = useState(false)
	const [viewUserDialogOpen, setViewUserDialogOpen] = useState(false)

	useEffect(() => {
		logger(user);
	}, [user]);
	const ConfirmDialog = () => {
		return (
			user && (
				<Dialog
					open={confirmDialogOpen}
					onClose={() => setConfirmDialogOpen(false)}
					sx={{
						"& .MuiDialog-paper": {
							backgroundColor: "transparent",
							boxShadow: "none",
						},
						"& .MuiModal-backdrop": {
							backdropFilter: "blur(8px)",
						},
					}}
				>
					<GradientBorder
						gradient="linear-gradient(270.69deg, rgba(255, 255, 255, 0.77) -6.58%, #9E5E28 153.04%)"
						width="1px"
						className={styles.dialog_wrapper}
					>
						<div>
							<Typography
								variant="body1"
								component="div"
								color="#9E5E28"
								sx={{
									marginBottom: "12px",
								}}
								style={{ fontWeight: "bold" }}
							>
								User Information
							</Typography>
							<div className={styles.topContainer}>
								<div className={styles.card}>
									<span style={{ fontWeight: "bold" }}>Name</span>:{user?.name}
								</div>
								<div className={styles.card}>
									<span style={{ fontWeight: "bold" }}>Email.</span>:
									{user?.email}
								</div>
								<div className={styles.card}>
									<span style={{ fontWeight: "bold" }}>Role</span>:{user?.role}
								</div>
							</div>
							<Typography
								variant="body1"
								component="div"
								color="#9E5E28"
								sx={{
									marginBottom: "12px",
								}}
								style={{ fontWeight: "bold" }}
							>
								Rights
							</Typography>
							<div>
								{rightsOptions.map((r, index) => {
									let flag = false;
									r.fields.map((f) => {
										if (user.rights.includes(f.value)) {
											flag = true;
										}
									});
									if (flag) {
										return (
											<TableRow key={index} style={{ width: "100%" }}>
												<TableCell>
													<label style={{ fontWeight: "600" }} htmlFor="name">
														{r.label}
													</label>
												</TableCell>

												{r.fields.map((f) => {
													if (user.rights.includes(f.value)) {
														return (
															<TableCell>
																<label>{f.label}</label>
															</TableCell>
														);
													}
												})}
											</TableRow>
										);
									}
								})}
							</div>
						</div>
					</GradientBorder>
				</Dialog>
			)
		);
	};

	return (
		<div className={styles.summaryContainer}>
			<MDBox pt={6} pb={3}>
				<Grid container spacing={6}>
					<Grid item xs={12}>
						<Card>
							<MDBox
								mx={2}
								mt={-3}
								py={3}
								px={2}
								variant="gradient"
								bgColor="info"
								borderRadius="lg"
								coloredShadow="info"
								display="flex"
								justifyContent="space-between"
								alignItems="center"
							>
								<MDTypography variant="h6" color="white">
									{params.role[0].toUpperCase() + params.role.slice(1)}
								</MDTypography>
								<button
									className={commonStyles.secondary_button}
									// onClick={() => {
									// 	navigate("/add-user");
									// }}
									onClick={() => {
										setAddUserDialogOpen(true)
									}}
								>
									Add New
								</button>
							</MDBox>
							<MDBox pt={3}>
								<DataTable
									table={{
										columns: [
											{
												Header: "Name",
												accessor: "name",
											},
											{
												Header: "Email",
												accessor: "email",
											},
											{
												Header: "Active?",
												accessor: "active",
											},
											{
												Header: "User Rights",
												accessor: "rights",
											},
											{
												Header: "Actions",
												accessor: "actions",
											},
										],
										rows: staff.map((s) => {
											return {
												name: s.name,
												email: s.email,
												active: "Active",
												rights: (
													<button
														disabled={s.role==="ADMIN"}
														className={commonStyles.primary_button}
														onClick={() => {
															setEditUser(s);
															// navigate("/edit-user");
															setEditUserDialogOpen(true);
														}}
													>
														Edit User Rights
													</button>
												),
												actions: (
													<span
														onClick={() => {
															// setConfirmDialogOpen(true);
															setUser(s);
															setViewUserDialogOpen(true)
														}}
													>
														<RemoveRedEyeIcon />
													</span>
												),
											};
										}),
									}}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							</MDBox>
						</Card>
					</Grid>
				</Grid>
			</MDBox>
			<ConfirmDialog />
			<AddUserDialog open={addUserDialogOpen} setOpen={setAddUserDialogOpen} ></AddUserDialog>
			<EditUserDialog open={editUserDialogOpen} editUser={editUser} setOpen={setEditUserDialogOpen} ></EditUserDialog>
			<ViewUserDialog open={viewUserDialogOpen} userDetails={user} setOpen={setViewUserDialogOpen} ></ViewUserDialog>
		</div>
	);
}

export default StaffUser;
