import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import Header from "../../components/Header/Header";
import { Card, Grid, IconButton } from "@mui/material";
import vibrator from "../../utils/vibrator";
import CloseIcon from "@mui/icons-material/Close";
import OrderSummaryDetails from "./OrderSummaryDetails";
import MuiDrawer from "@mui/material/Drawer";
import commonStyles from "../../styles.module.css";
import {
	Button,
	Dialog,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import styles from "./OrderSummary.module.css";
import { FilterListRounded } from "@mui/icons-material";
import {
	CancelRounded,
	PrintRounded,
	Search,
	VisibilityRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import { admin } from "providers/AdminProvider";
import { RiEdit2Fill } from "react-icons/ri";
import EditBillDialog from "./EditBillDialog";
import regexSearch from "utils/regexSearch";
import { restaurant } from "providers/RestaurantProvider";

const ModifiedBills = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [orders, setOrders] = useState([]);
	const [searchQuery, setSearchQuery] = useState("");
	const [order, setOrder] = useState({});
	const { setLoading } = useContext(admin)
	const restaurantContext = useContext(restaurant)
    const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);


	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);

	const fetchOrders = async () => {
		setLoading(true)
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}&billModified=true`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setLoading(false)
			setOrders(res.data);
			return res;
		} catch (error) {
			setLoading(false)
			logger(error);
			toast.error(error?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	useEffect(() => {
		const callFunction = fetchOrders();
		toast.promise(callFunction, {
			loading: "Fetching Modfied Bills",
			error: "Fetching Modified Bills Failed",
			success: "Modified Bills refreshed Successfully"
		});
	}, [startDate, endDate]);

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.orderSummary} />
			<DashboardNavbar
            showDateFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            />
			<div
			>
				<div className={styles.mainContainer}>
					<MDBox pt={6} pb={3}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card>
									<MDBox
										mx={2}
										mt={-3}
										py={3}
										px={2}
										variant="gradient"
										className="primary-bg"
										borderRadius="lg"
									>
										<MDTypography variant="h6" color="white">
											Modified Bills
										</MDTypography>
									</MDBox>
									<MDBox pt={3}>
										{orders.length > 0 && (
											<DataTable
												onChange={(e) => {
												}} table={{
													columns: [
														{
															Header: "Order No",
															accessor: "orderNo",
														},
														{
															Header: "Order Type",
															accessor: "orderType",
														},
                                                        {
                                                            Header: "Status",
                                                            accessor: "status",
                                                        },
														{
															Header: "Bill Settled By",
															accessor: "billSettledBy",
														},
														{
															Header: "Bill Modified By",
															accessor: "billModifiedBy",
														},
														{
															Header: "Payment Modified By",
															accessor: "paymentModifiedBy",
														},
														{
															Header: "Payment Type",
															accessor: "paymentMethod",
														},
														{
															Header: "My Amount",
															accessor: "myAmount",
														},
														{
															Header: "Tax",
															accessor: "tax",
														},
														{
															Header: "Discount",
															accessor: "discount",
														},
														{
															Header: "Grand Total",
															accessor: "grandTotal",
														},
														{
															Header: "Customer Paid",
															accessor: "customerPaid",
														},
														{
															Header: "Tip",
															accessor: "tip",
														},
														{
															Header: "Created",
															accessor: "createdAt",
														},
														{
															Header: "Actions",
															accessor: "actions",
														},
													],
													rows: orders.filter((order) => {
														return (regexSearch(order?.orderNumber, searchQuery))
													}
													).map((ord) => {
														let myAmount = ord.kots.reduce((acc, kot) => {
															return acc + kot.total;
														}, 0);
														return {
															orderNo: ord.orderNumber,
															orderType: (ord?.table?.type?.toUpperCase() ?? "DINEIN") + "(" + ord?.table?.name + ")",
															status: ord.status,
															billSettledBy: ord.billSettledBy?.name,
															billModifiedBy: ord.billModifiedBy?.name,
															paymentModifiedBy: ord.paymentModifiedBy?.name,
															paymentMethod: ord.payment?.method || "Due",
															myAmount: myAmount,
															tax: (myAmount * (totalTaxValue / 100)).toFixed(2),
															discount: (ord.discount?.amount ?? 0).toFixed(2),
															grandTotal: (myAmount * 1.05).toFixed(2),
															customerPaid: ord.payment?.customerPaid ?? 0,
															tip: ord.payment?.tip ?? 0,
															createdAt: new Date(
																ord.createdAt
															).toLocaleString(),
															actions: (
																<div className={styles.actions}>
																	<VisibilityRounded
																		onClick={() => {
																			vibrator.tap();
																			setIsDrawerOpen(true);
																			setOrder({ ...ord });
																		}}
																		style={{ cursor: "pointer" }}
																	/>
																</div>
															),
														};
													}),
												}}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												noEndBorder
                                                canSearch
											/>
										)}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
					<React.Fragment>
						<MuiDrawer
							variant="temporary"
							anchor="right"
							open={isDrawerOpen}
							onClose={() => setIsDrawerOpen(false)}
							sx={{
								"& .MuiDrawer-paper": {
									width: "85%",
									maxWidth: "100%",
								},
								"& .MuiModal-backdrop": {
									backdropFilter: "blur(5px)",
								},
							}}
						>
							<div
								className={styles.details}
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										marginRight: "10px",
									}}
								></div>
								<OrderSummaryDetails order={order} />
							</div>
						</MuiDrawer>
					</React.Fragment>
				</div>
			</div>
		</DashboardLayout>
	);
};

export default ModifiedBills;
