import axios from "axios";
import { createContext, useEffect, useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";
const restaurant = createContext({});
const { Provider } = restaurant;

const RestaurantProvider = ({ children }) => {
	const [restaurant, setStateRestaurant] = useState(
		JSON.parse(localStorage.getItem("restaurantData")) || {}
	);
	const [items, setStateItems] = useState(
		JSON.parse(localStorage.getItem("restaurantItems")) || {}
	);
	const setItems = (items) => {
		localStorage.setItem("restaurantItems", JSON.stringify(items));
		setStateItems(items);
	};
	const setRestaurant = (restaurant) => {
		localStorage.setItem("restaurantData", JSON.stringify(restaurant));
		setStateRestaurant(restaurant);
	};
	const [categoryWiseItem, setStateCategoryWiseItem] = useState(JSON.parse(localStorage.getItem("categoryWiseItem")) || {});

	const setItem = (itemInCategory, category, parent) => {
		category.items.forEach((i) => {
			if (itemInCategory[parent]) {
				itemInCategory[parent].push(i);
			}
			else {
				itemInCategory[parent]=[i];
			}
		})
		category.subCategories.forEach((cat) => {
			setItem(itemInCategory, cat, parent);
		})
	}

	const SetcategoryWiseItem = (restaurant) => {
		let itemInCategory = {};
		restaurant.menu.forEach((category) => {
			setItem(itemInCategory, category, category.name);
		})
		localStorage.setItem("categoryWiseItem", JSON.stringify(itemInCategory));
		setStateCategoryWiseItem(itemInCategory);
	}

	const updateItem = (itemID, item) => {
		setStateItems((prevItems) => {
			const newItems = { ...prevItems, [itemID]: item };
			localStorage.setItem("restaurantItems", JSON.stringify(newItems));
			return newItems;
		});
	};

	const refreshRestaurant = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem("restaurant")}/unmorphed`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setRestaurant(res.data.restaurant);
			setItems(res.data.items)
			
			toast.success("Restaurant refreshed", {
				autoClose: 2000,
			});
			return res
		} catch (err) {
			logger(err);
			toast.error(err?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	// useEffect(()=>{
	// 	refreshRestaurant()
	// },[])

	const updateAvailability = async (
		itemId,
		available,
		unavailableReason = undefined
	) => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/items/${itemId}`,
				{
					available,
					unavailableReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			updateItem(itemId, res.data);
		} catch (err) {
			logger(err);
			toast.error(err?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	const clearRestaurant = () => {
		setStateRestaurant({});
	};

	return (
		<Provider
			value={{
				restaurant,
				setRestaurant,
				refreshRestaurant,
				clearRestaurant,
				items,
				updateAvailability,
				setItems,
				setStateItems,
				updateItem,
				SetcategoryWiseItem,
				categoryWiseItem
			}}
		>
			{children}
		</Provider>
	);
};

export { RestaurantProvider, restaurant };
