/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import {
	Dashboard,
	HistoryRounded,
	MenuBookRounded,
	PeopleRounded,
	ReceiptLongRounded,
	ReceiptRounded,
	SettingsRounded,
	ShoppingCartRounded,
	TableViewRounded,
} from "@mui/icons-material";
import { MdOutlineCancel, MdOutlineTableRestaurant } from "react-icons/md";
import {  RiCashFill, RiMoneyDollarBoxFill, RiScales3Fill, RiStickyNoteFill } from "react-icons/ri";
import { TbBasketCancel } from "react-icons/tb";
import { LuClipboardEdit } from "react-icons/lu";

const main = [
	{
		type: "collapse",
		name: "Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "Live View",
		key: "live-view",
		icon: <MdOutlineTableRestaurant />,
		href: "/live-view",
	},
	{
		type: "collapse",
		name: "Order Report",
		key: "order-report",
		icon: <TableViewRounded />,
		href: "/order-report",
	},
	{
		type: "collapse",
		name: "Online Orders",
		key: "online-orders",
		icon: <ReceiptLongRounded />,
		href: "/online-orders",
	},
	{
		type: "collapse",
		name: "Order Summary",
		key: "order-summary",
		icon: <ReceiptLongRounded />,
		href: "/order-summary",
	},
	{
		type: "collapse",
		name: "Settings",
		key: "settings",
		icon: <SettingsRounded />,
		href: "/settings",
	},
	{
		type: "collapse",
		name: "User Management",
		key: "user-management",
		icon: <PeopleRounded />,
		href: "/user-management/admin",
	},
	{
		type: "collapse",
		name: "Menu Management",
		key: "menu-management",
		icon: <MenuBookRounded />,
		href: "/menu-management",
	},
	{
		type: "collapse",
		name: "Inventory",
		key: "inventory",
		icon: <ShoppingCartRounded />,
		href: "/inventory",
	},
	{
		type: "collapse",
		name: "Preparation Recipe",
		key: "preperation-recipe",
		icon: <RiStickyNoteFill />,
		href: "/preperation-recipe",
	},
	{
		type: "collapse",
		name: "Expense Tracker",
		key: "expense-tracker",
		icon: <RiCashFill />,
		href: "/expense-tracker",
	},
];

const orderSummary = [
	{
		type: "collapse",
		name: "Back to Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "Order Summary",
		key: "order-summary",
		icon: <ReceiptLongRounded />,
		href: "/order-summary",
	},
	{
		type: "collapse",
		name: "Modified Bills",
		key: "order-summary/modified",
		icon: <LuClipboardEdit />,
		href: "/order-summary/modified",
	},
	{
		type: "collapse",
		name: "Cancelled Orders",
		key: "order-summary/cancelled",
		icon: <MdOutlineCancel />,
		href: "/order-summary/cancelled",
	},
	{
		type: "collapse",
		name: "Waived Off Orders",
		key: "order-summary/waivedoff",
		icon: <TbBasketCancel />,
		href: "/order-summary/waivedoff",
	},
];
const onlineOrders = [
	{
		type: "collapse",
		name: "Back to Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "Food Aggregators",
		key: "online-orders",
		icon: <ReceiptLongRounded />,
		href: "/online-orders",
	},
	{
		type: "collapse",
		name: "Swiggy",
		key: "online-orders/swiggy",
		icon: <LuClipboardEdit />,
		href: "/online-orders/swiggy",
	},
];

const kotSummary = [
	{
		type: "collapse",
		name: "Back to Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "KOT Summary",
		key: "kot-summary",
		icon: <ReceiptRounded />,
		href: "/kot-summary",
	},
	{
		type: "collapse",
		name: "Past KOT Summary",
		key: "kot-summary/past",
		icon: <HistoryRounded />,
		href: "/kot-summary/past",
	},
];

const userManagement = [
	{
		type: "collapse",
		name: "Back to Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "Admin",
		key: "user-management/admin",
		icon: <PeopleRounded />,
		href: "/user-management/admin",
	},
	{
		type: "collapse",
		name: "Cashier",
		key: "user-management/cashier",
		icon: <PeopleRounded />,
		href: "/user-management/cashier",
	},
	{
		type: "collapse",
		name: "Kitchen",
		key: "user-management/kitchen",
		icon: <PeopleRounded />,
		href: "/user-management/kitchen",
	},
	{
		type: "collapse",
		name: "Captain",
		key: "user-management/captain",
		icon: <PeopleRounded />,
		href: "/user-management/captain",
	},
];

const inventory = [
	{
		type: "collapse",
		name: "Back to Dashboard",
		key: "dashboard",
		icon: <Dashboard />,
		href: "/dashboard",
	},
	{
		type: "collapse",
		name: "Inventory",
		key: "inventory",
		icon: <ReceiptRounded />,
		href: "/inventory",
	},
	{
		type: "collapse",
		name: "Raw Materials",
		key: "inventory/raw-materials",
		icon: <RiScales3Fill />,
		href: "/inventory/raw-materials",
	},
	{
		type: "collapse",
		name: "Recipe Management",
		key: "inventory/recipe-management",
		icon: <RiStickyNoteFill />,
		href: "/inventory/recipe-management",
	},
	{
		type: "collapse",
		name: "Profit Margin calculator",
		key: "inventory/profit-margin",
		icon: <RiMoneyDollarBoxFill />,
		href: "/inventory/profit-margin",
	},
	
];

export default { main, orderSummary, kotSummary, inventory, userManagement, onlineOrders };
