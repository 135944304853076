/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "./Footer";
import ComplexStatisticsCard from "./Cards/StatisticsCards/ComplexStatisticsCard";
import OrdersOverview from "./components/OrdersOverview";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { useState, useEffect, useContext } from "react";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import {
	BarChartRounded,
	BookOnlineRounded,
	CurrencyExchangeRounded,
	GroupRounded,
	LocalAtmRounded,
	MonetizationOnRounded,
	StoreRounded,
	TrendingUpRounded,
} from "@mui/icons-material";
import PopularItems from "./components/PopularItems";
import exportToExcel from "utils/exportToExcel";
import { restaurant } from "providers/RestaurantProvider";
import { RiBarChart2Line, RiCashLine, RiLineChartFill, RiPieChart2Line } from "react-icons/ri";
import OnlineOrdersOverview from "./components/OnlineOrdersOverview";
import OutletStatistics from "./components/OutletStatistics";
import RevenueLeakage from "./components/RevenueLeakage";
import ordinal from "ordinal";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import PolarChart from "examples/Charts/PolarChart";
import styles from './Dashboard.module.css'


function Dashboard() {
	const restaurantContext = useContext(restaurant);
	const [loading, setLoading] = useState(false)

	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);

	const [orders, setOrders] = useState([]);
	const [expenses, setExpenses] = useState([])
	const fetchOrders = async () => {
		setLoading(true)
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setLoading(false)

			logger("resData", res.data);

			setOrders(res.data);
			return res;
		} catch (err) {
			setLoading(false)
			logger(err);
			toast.error(
				err?.response?.data?.message ?? "Network connection failed"
			);
			throw new Error("Failed")

		} finally {
			setLoading(false)
		}
	};
	const fetchExpense = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/expense/byCategory?from=${startDate}&to=${endDate}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			setExpenses(res.data)
			return res;
		} catch (err) {
			logger(err);
			toast.error(
				err?.response?.data?.message ?? "Network connection failed"
			);
			throw new Error("Failed")
		} finally {
			setLoading(false)
		}
	};

	console.log(expenses)

	useEffect(() => {
		const callFunction1 = fetchOrders();
		const callFunction2 = fetchExpense();
		toast.promise(Promise.all([callFunction1, callFunction2]), {
			loading: "Fetching Dashboard Data",
			error: "Fetching Data Failed",
			success: "Dashboard Refreshed Successfully"
		});
	}, [startDate, endDate]);
	let totalCashCollected = 0
	let otherPayments = {};
	const renderPaymentMethodBarChart = () => {
		totalCashCollected = 0;
		const paymentMethodBarChartData = {
			labels: ["Cash", "Card", "Part", "Others", "Due"],
			datasets: [{
				label: "Payment Method",
				data: [0, 0, 0, 0, 0],
			}]
		};
		otherPayments = {}

		for (let ord of orders) {
			if (ord.payment?.method === "Cash") {
				paymentMethodBarChartData.datasets[0].data[0] += ord?.payment?.customerPaid ?? 0;
			} else if (ord.payment?.method === "Card") {
				paymentMethodBarChartData.datasets[0].data[1] += ord?.payment?.customerPaid ?? 0;
			} else if (ord.payment?.method === "Part") {
				paymentMethodBarChartData.datasets[0].data[2] += ord?.payment?.customerPaid ?? 0;
				ord.payment.parts.forEach((payment) => {
					if (payment?.method === "Cash") {
						paymentMethodBarChartData.datasets[0].data[0] += payment.amount ?? 0;
					} else if (payment?.method === "Card") {
						paymentMethodBarChartData.datasets[0].data[0] += payment.amount ?? 0;
					} else if (payment?.method === "Other") {
						const keyName = payment.otherMethod ?? "UPI";
						otherPayments[keyName] = (otherPayments[keyName] ?? 0) + payment.amount ?? 0;
					}
				})
			} else if (ord.payment?.method === "Other") {
				paymentMethodBarChartData.datasets[0].data[3] += ord?.payment?.customerPaid ?? 0;
				otherPayments[ord?.payment?.otherMethod ?? "UPI"] = (otherPayments[ord?.payment?.otherMethod ?? "UPI"] ?? 0) + ord?.payment?.customerPaid ?? 0;
			} else if (ord.payment?.method === "Due") {
				paymentMethodBarChartData.datasets[0].data[4] += ord?.payment?.customerPaid ?? 0;
			}
			totalCashCollected += ord?.payment?.customerPaid ?? 0;
		}

		logger(paymentMethodBarChartData);
		return paymentMethodBarChartData;
	};


	const getOrdersOverview = () => {
		let ordersOverview = {
			completed: 0,
			cancelled: 0,
			settled: 0,
			running: 0,
		};
		for (let ord of orders) {
			ordersOverview[ord.status]++;
		}
		return ordersOverview;
	};

	const getSortedItemsBySoldPrice = () => {
		const items = {};

		for (let ord of orders) {
			if(ord.status!="settled"){
                continue;
            }
			for (let kot of ord.kots) {
				for (let item of kot.items) {
					if (!item?.item?._id) {
						continue;
					}
					if (items[item.item._id]) {
						items[item.item._id].sold += item.item.price * item.quantity;
						items[item.item._id].quantity += item.quantity;
					} else {
						items[item.item._id] = {
							name: item.item.name,
							price: item.item.price,
							quantity: item.quantity,
							sold: item.item.price * item.quantity,
						};
					}
				}
			}
		}

		logger("sort items", items);

		const sortedItems = Object.values(items).sort((a, b) => {
			return b.sold - a.sold;
		});

		logger(sortedItems);

		return sortedItems;
	};

	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);

	const totalSales =
		orders.reduce((acc, ord) => {
			if (ord.status === "settled") {
				return (
					acc +
					ord.kots?.reduce((acc, kot) => {
						return acc + kot?.total;
					}, 0) ?? 0
				);
			} else {
				return (
					acc + 0
				);
			}
		}, 0) *
		(1 + totalTaxValue / 100);
	const netSales = orders.reduce((acc, ord) => {
		if (ord.status === "settled") {
			return (
				acc +
				ord.kots?.reduce((acc, kot) => {
					return acc + kot?.total;
				}, 0) ?? 0
			);
		} else {
			return (
				acc + 0
			);
		}
	}, 0);
	const totalOrders = orders.length;
	const totalCustomers = orders.reduce((acc, ord) => {
		const personCount = ord.personCount ?? 1;
		const kotPersonCount = new Set();
		ord.kots?.forEach((kot) => {
			const key = kot?.userData?.phone
				? `${kot.userData.name}-${kot.userData.phone}`
				: kot.user;
			kotPersonCount.add(key);
		});
		return acc + Math.max(personCount, kotPersonCount.size);
	}, 0);

	const totalCashCollection =
		orders
			.filter((ord) => ord.payment?.method === "Cash")
			.reduce((acc, ord) => {
				return (
					acc +
					ord.kots?.reduce((acc, kot) => {
						return acc + kot?.total;
					}, 0) ?? 0
				);
			}, 0) * (1 + (totalTaxValue / 100));

	const totalTax = netSales * (totalTaxValue / 100);



	const takeAwayTables = [], dineInTables = [];
	restaurantContext.restaurant?.sections
		.map(section => {
			if (section.type === 'takeaway') {
				section?.tables.map((table) => {
					takeAwayTables.push(table.name)
				});
			} else if (section.type === "dinein") {
				section?.tables.map((table) => {
					dineInTables.push(table.name)
				});
			}
		})



	let takeAwayOrders = [],
		dineInOrders = [];

	let minDineInOrder = null,
		maxDineInOrder = null,
		minTakeAwayOrder = null,
		maxTakeAwayOrder = null,
		totalRoundOff = 0,
		dineInTotal = 0,
		takeAwayTotal = 0,
		billsRePrinted = 0,
		kotsModified = 0,
		totalWaivedOff = 0,
		cancelledOrders = 0,
		billsModified = 0,
		totalCharges = 0,
		totalDiscount = 0,
		totalSettledOrders = 0,
		orderTotal = 0;

	let discountByDay = {};
	let ordersByDay = {}, ordersByTime = {};

	function daysBetweenDates(date1, date2) {
		// Convert both dates to milliseconds
		const date1Ms = date1.getTime();
		const date2Ms = date2.getTime();

		// Calculate the difference in milliseconds
		const differenceMs = Math.abs(date1Ms - date2Ms);

		// Convert the difference to days
		const daysDifference = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

		return daysDifference;
	}

	let discounts = [];
	orders.forEach((ord) => {
		if (ord.billPrinted > 1) {
			billsRePrinted += ord.billPrinted - 1;
		}
		if (ord.status === "cancelled") {
			cancelledOrders++;
		}
		if (ord.status === "waivedOff") {
			totalWaivedOff++;
		}
		let isBillModified = false;
		if (ord.status === "settled") {
			totalSettledOrders++;
			totalCharges += (ord.deliveryCharge ?? 0) + (ord.containerCharge ?? 0)
			if (ord.discount?.amount) {
				discounts.push({ ...ord.discount, date: ord.createdAt });
				if (!discountByDay[new Date(ord.createdAt).toLocaleDateString()]) {
					discountByDay[new Date(ord.createdAt).toLocaleDateString()] = {
						amount: 0,
						date: new Date(ord.createdAt),
					};
				}
				discountByDay[new Date(ord.createdAt).toLocaleDateString()] = {
					amount:
						discountByDay[new Date(ord.createdAt).toLocaleDateString()].amount +
						ord.discount.amount,
					date: new Date(ord.createdAt),
				};
				totalDiscount += ord.discount.amount ?? 0;
			}
			if (ord.billModifiedBy) {
				billsModified++;
			}
			orderTotal =
				ord.kots?.reduce((acc, kot) => {
					if (kot?.kotModifiedBy) {
						kotsModified++;
					}
					return acc + kot?.total;
				}, 0) ?? 0;

			if (!ordersByDay[new Date(ord.createdAt).toLocaleDateString()]) {
				ordersByDay[new Date(ord.createdAt).toLocaleDateString()] = {
					amount: 0,
					date: new Date(ord.createdAt),
				};
			}
			ordersByDay[new Date(ord.createdAt).toLocaleDateString()] = {
				amount:
					ordersByDay[new Date(ord.createdAt).toLocaleDateString()].amount +
					orderTotal,
				date: new Date(ord.createdAt),
			};
			if (!ordersByTime[new Date(ord.createdAt).getHours()]) {
				ordersByTime[new Date(ord.createdAt).getHours()] = {
					amount: 0,
					date: new Date(ord.createdAt),
				};
			}

			ordersByTime[new Date(ord.createdAt).getHours()] = {
				amount:
					ordersByTime[new Date(ord.createdAt).getHours()].amount +
					orderTotal,
				date: new Date(ord.createdAt),
			};
			logger("roundoff", ord.payment?.method)
			if (ord.payment?.customerPaid < orderTotal) {
				totalRoundOff +=
					orderTotal - (ord.discount?.amount ?? 0) - ord.payment.customerPaid;
			}
			if (ord?.table?.type==="takeaway") {
				if (minTakeAwayOrder == null) {
					minTakeAwayOrder = orderTotal;
				} else {
					minTakeAwayOrder = Math.min(minTakeAwayOrder, orderTotal);
				}
				if (maxTakeAwayOrder == null) {
					maxTakeAwayOrder = orderTotal;
				} else {
					maxTakeAwayOrder = Math.max(maxTakeAwayOrder, orderTotal);
				}
				takeAwayTotal += orderTotal;
				takeAwayOrders.push({ ...ord, total: orderTotal });
			} else {
				if (minDineInOrder == null) {
					minDineInOrder = orderTotal;
				} else {
					minDineInOrder = Math.min(minDineInOrder, orderTotal);
				}
				if (maxDineInOrder == null) {
					maxDineInOrder = orderTotal;
				} else {
					maxDineInOrder = Math.max(maxDineInOrder, orderTotal);
				}
				dineInTotal += orderTotal;
				dineInOrders.push({ ...ord, total: orderTotal });
			}
		}
	});

	const renderDailySalesLineChart = () => {

		let labels = [],
			data = [];
		if (daysBetweenDates(startDate, endDate) > 1) {
			labels = Object.values(ordersByDay)
				.sort((a, b) => {
					return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
				})
				.map((item) => ordinal(item.date.getDate()));
			data = Object.values(ordersByDay).sort((a, b) => {
				return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
			}).map((item) => item.amount);
		} else {
			labels = [
				"12am-3am",
				"3am-6am",
				"6am-9am",
				"9am-12pm",
				"12pm-3pm",
				"3pm-6pm",
				"6pm-9pm",
				"9pm-12am",
			];
			data = [0, 0, 0, 0, 0, 0, 0, 0];
			for (let d of Object.values(ordersByTime)) {
				const date = new Date(d.date);
				logger(date.getHours(), date, 'timeAndDate')
				if (date.getHours() < 3) {
					data[0] += d.amount;
				} else if (date.getHours() < 6) {
					data[1] += d.amount;
				} else if (date.getHours() < 9) {
					data[2] += d.amount;
				} else if (date.getHours() < 12) {
					data[3] += d.amount;
				} else if (date.getHours() < 15) {
					data[4] += d.amount;
				} else if (date.getHours() < 18) {
					data[5] += d.amount;
				} else if (date.getHours() < 21) {
					data[6] += d.amount;
				} else {
					data[7] += d.amount;
				}
			}

		}
		const dailySalesLineChartData = {
			labels,
			datasets: [
				{
					label: "Today",
					color: "dark",
					data,
				}
			]
		};
		return dailySalesLineChartData;
	};

	const getDiscountChartData = () => {
		let labels = [],
			data = [];

		if (daysBetweenDates(startDate, endDate) > 1) {
			labels = Object.values(discountByDay)
				.sort((a, b) => {
					return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
				})
				.map((item) => ordinal(item.date.getDate()));
			data = Object.values(discountByDay).sort((a, b) => {
				return a.date < b.date ? -1 : a.date > b.date ? 1 : 0;
			}).map((item) => item.amount);
		} else {
			labels = [
				"12am-3am",
				"3am-6am",
				"6am-9am",
				"9am-12pm",
				"12pm-3pm",
				"3pm-6pm",
				"6pm-9pm",
				"9pm-12am",
			];
			data = [0, 0, 0, 0, 0, 0, 0, 0];
			for (let d of Object.values(discountByDay)) {
				const date = new Date(d.date);
				if (date.getHours() < 3) {
					data[0] += d.amount;
				} else if (date.getHours() < 6) {
					data[1] += d.amount;
				} else if (date.getHours() < 9) {
					data[2] += d.amount;
				} else if (date.getHours() < 12) {
					data[3] += d.amount;
				} else if (date.getHours() < 15) {
					data[4] += d.amount;
				} else if (date.getHours() < 18) {
					data[5] += d.amount;
				} else if (date.getHours() < 21) {
					data[6] += d.amount;
				} else {
					data[7] += d.amount;
				}
			}
		}
		const chartData = {
			labels: labels,
			datasets: [{
				label: "Today",
				data: data,
			}],
		};
		return chartData;
	};

	const handleExport = () => {
		exportToExcel(
			"Outlet details",
			[
				[
					"Name",
					"Orders",
					"Sales",
					"Net Sales",
					"Tax",
					"Discount",
					"Modified",
					"Re-Printed",
					"Waived Off",
					"Round Off",
					"Charges",
				],
				[
					restaurantContext.restaurant.name,
					orders.length,
					totalSales,
					netSales,
					totalTax,
					totalDiscount,
					billsModified,
					billsRePrinted,
					totalWaivedOff,
					totalRoundOff,
					totalCharges,
				],
			],
			startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
		);
	};
	const [swiggyOrders, setSwiggyOrders]= useState({
        orders:[],
        total:0,
        minOrder:0,
        maxOrder:0,
        orderAvg:0
        
    })
	const fetchSwiggyOrders=async ()=>{
        return axios.get(
            `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/onlineOrders/swiggy?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            ).then(res=>{
				toast.success("Swiggy Orders Refreshed")
                console.log(res.data)
                setSwiggyOrders(res.data);
            }).catch(err=>{
				toast.info("Swiggy connection failed")
                console.log(err)
            })
    }

    useEffect(()=>{
        fetchSwiggyOrders()
    }, [startDate, endDate])

	return (
		<>
			<Sidenav className="animate" routes={routes.main} />
			<DashboardLayout>
				<DashboardNavbar
					showExport
					handleExport={handleExport}
					showDateFilter
					setStartDate={setStartDate}
					setEndDate={setEndDate}
					startDate={startDate}
					endDate={endDate}
				/>
				<MDBox py={3}>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									showRs
									loading={loading}
									color="dark"
									icon={<TrendingUpRounded fontSize="medium" />}
									title="Total Sales"
									count={totalSales.toFixed(2).toString()}
								// percentage={{
								// 	color: "success",
								// 	amount: "+55%",
								// 	label: "than lask week",
								// }}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									showRs
									loading={loading}
									icon={<BarChartRounded fontSize="medium" />}
									title="Net Sales"
									count={netSales.toFixed(2).toString()}
								// percentage={{
								// 	color: "success",
								// 	amount: "+3%",
								// 	label: "than last month",
								// }}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									loading={loading}
									color="success"
									icon={<StoreRounded fontSize="medium" />}
									title="Orders"
									count={totalOrders}
								// percentage={{
								// 	color: "success",
								// 	amount: "+1%",
								// 	label: "than yesterday",
								// }}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									loading={loading}
									color="primary"
									icon={<GroupRounded fontSize="medium" />}
									title="Customers"
									count={totalCustomers}
								// percentage={{
								// 	color: "success",
								// 	amount: "",
								// 	label: "Just updated",
								// }}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<Grid container spacing={3} marginTop="8px">
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									loading={loading}
									color="dark"
									showRs
									icon={<LocalAtmRounded fontSize="medium" />}
									title="Takeaways"
									showOption
									orderDetails={{
										noOfOrders: takeAwayOrders.length,
										min: minTakeAwayOrder,
										max: maxTakeAwayOrder,
										avg: (takeAwayTotal / takeAwayOrders.length).toFixed(2),
										discount: 0,
										tax: (takeAwayTotal * (totalTaxValue / 100)).toFixed(2),
										total: (takeAwayTotal + (takeAwayTotal * (totalTaxValue / 100))).toFixed(2),
									}}
									count={
										(takeAwayTotal + (takeAwayTotal * (totalTaxValue / 100))).toFixed(2)
									}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									showRs
									loading={loading}
									color="success"
									icon={<CurrencyExchangeRounded fontSize="medium" />}
									title="Taxes"
									count={totalTax.toFixed(2).toString()}
									showOption={true}
									netSales={netSales}
									totalTax={totalTax}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									showRs
									loading={loading}
									icon={<BookOnlineRounded fontSize="medium" />}
									title="Online Sales"
									count="₹ 0.00"
									showOption
									orderDetails={{
										noOfOrders: 0,
										min: 0,
										max: 0,
										avg: 0,
										discount: 0,
										tax: 0,
										total: 0,
									}}
								// percentage={{
								// 	color: "success",
								// 	amount: "+3%",
								// 	label: "than last month",
								// }}
								/>
							</MDBox>
						</Grid>
						<Grid item xs={12} md={6} lg={3}>
							<MDBox mb={1.5}>
								<ComplexStatisticsCard
									showRs
									loading={loading}
									color="primary"
									icon={<MonetizationOnRounded fontSize="medium" />}
									title="Dine In"
									showOption
									orderDetails={{
										noOfOrders: dineInOrders.length,
										min: minDineInOrder,
										max: maxDineInOrder,
										avg: (dineInTotal / dineInOrders.length).toFixed(2),
										discount: 0,
										tax: (dineInTotal * (totalTaxValue / 100)).toFixed(2),
										total: dineInTotal + (dineInTotal * (totalTaxValue / 100)),
									}}
									count={
										(dineInTotal + dineInTotal * (totalTaxValue / 100))
									}
								/>
							</MDBox>
						</Grid>
					</Grid>
					<MDBox mt={4.5}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={8}>
								<MDBox mb={3}>

									<DefaultLineChart
										loading={loading}
										icon={{ color: "info", component: <RiLineChartFill /> }}
										title="Time wise Sale"
										description=""
										chart={renderDailySalesLineChart()}
									/>

								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<MDBox mb={3} >
									<HorizontalBarChart
										icon={{ color: "info", component: <RiCashLine /> }}
										title="Payment Method"
										description=""
										chart={renderPaymentMethodBarChart()}
									/>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>
					<MDBox mt={4.5}>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={7}>
								<MDBox mb={3}>
									<VerticalBarChart
										icon={{ color: "info", component: <RiBarChart2Line /> }}
										title="Discounts"
										description=""
										chart={getDiscountChartData()}
									/>

								</MDBox>
							</Grid>
							<Grid item xs={12} md={6} lg={5}>
								<MDBox mb={3} >
									<div className={styles.expense_tracker_container}>
										<PolarChart
											icon={{ color: "info", component: <RiPieChart2Line /> }}
											title="Expense Tracker"
											description=""
											height={310}
											chart={{
												labels: expenses.map(exp => exp._id),
												datasets: {
													label: "Expense",
													data: expenses.map(exp => exp.total),
													backgroundColors: ["info", "primary", "dark", "secondary", "success"],
												},
											}}
										/>
										<div className={styles.expense_tracker_items}>
											{
												expenses.map((exp, key) => {
													return <div className={styles.expense_tracker_item}>
														{exp._id} : <b>{exp.total}</b>
													</div>
												}
												)
											}
										</div>

									</div>
								</MDBox>
							</Grid>
						</Grid>
					</MDBox>

					<MDBox>
						<Grid container spacing={3}>
							<Grid item xs={12} md={6} lg={8}>
								<OnlineOrdersOverview
								swiggyOrders={swiggyOrders}
								startDate={startDate}
								endDate={endDate}
									items={getSortedItemsBySoldPrice() || []}
								/>
							</Grid>
							<Grid item xs={12} md={6} lg={4}>
								<OrdersOverview data={getOrdersOverview()} />
								<RevenueLeakage
									data={{
										billsRePrinted,
										kotsModified,
										billsModified,
										cancelledOrders,
										totalWaivedOff,
									}}
								/>
							</Grid>
						</Grid>
					</MDBox>
					<MDBox>
						<Grid container spacing={3} marginTop={"20px"}>
							<Grid item xs={12}>
								<PopularItems items={getSortedItemsBySoldPrice() || []} />
							</Grid>
						</Grid>
					</MDBox>
					<MDBox marginTop={"20px"}>
						<OutletStatistics
							orderDetails={{
								orders: orders.length,
								netSales,
								totalSales,
								totalTax,
								roundOff: totalRoundOff,
								billsRePrinted,
								kotsModified,
								totalWaivedOff,
								totalCharges,
								totalDiscount
							}}
							items={getSortedItemsBySoldPrice() || []}
						/>
					</MDBox>
				</MDBox>
				<Footer />
			</DashboardLayout>
		</>
	);
}

export default Dashboard;
