
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import styles from './AddUserDialog.module.css'

// Authentication layout components

// Images
import {
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useContext, useState } from "react";
import { ClipLoader } from "react-spinners";
import { admin } from "providers/AdminProvider";
import { restaurant } from "providers/RestaurantProvider";
import toast from "utils/toast";
// import BasicLayout from "../../../components/";

function AddCategoryDialog({
    open,
    setOpen,
    categoryToUpdate,
    setCategoryToUpdate,
    mainMenu,
    parentCategoryId
}) {
    const [isLoading, setIsLoading] = useState(false);
    const [name, setName] = useState("");
    const adminContext = useContext(admin);
    const restaurantContext = useContext(restaurant)
    const { updateMenu, globalMenu, setGlobalMenu } = adminContext;
    const saveHandler = async (e) => {
        try{

            e.preventDefault();
            setIsLoading(true)
        let flag = false;
        for (let i = 0; i < name.length; i++) {
            if (name[i] !== " ") {
                flag = true;
            }
        }
        if (flag === false) {
            setIsLoading(false)
            return;
        }
        const res = await updateMenu(setOpen, mainMenu, parentCategoryId, name);
        if (mainMenu === false) {
            let temp = { ...categoryToUpdate };
            temp.subCategories.push(res.data);
            setCategoryToUpdate(temp);
            restaurantContext.refreshRestaurant()
        } else {
            setGlobalMenu([...globalMenu, res.data])
        }
        setIsLoading(false)
        return res
    }catch(err){
        toast.error("Category Adding failed")
    }

    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "400px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Add Category
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox role="form">
                            <MDBox mb={2}>
                                <MDInput
                                    type="text"
                                    label="Name"
                                    name="name"
                                    // value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>

                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={(e) => {
                                        const callFunction = saveHandler(e);
                                        toast.promise(callFunction, {
                                            loading: "Adding New Category",
                                            error: "Category adding failed.",
                                            success: "Category Added Successfully"
                                        });
                                    }}
                                    disabled={name.trim() === "" || isLoading}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default AddCategoryDialog;
