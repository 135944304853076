
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import styles from './AddUserDialog.module.css'

// Authentication layout components

// Images
import {
    Dialog,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { restaurant } from "providers/RestaurantProvider";
import { Add, Delete } from "@mui/icons-material";
import styles from './EditBill.module.css'
import toast from "utils/toast";
import axios from "axios";

function EditBillDialog({
    open,
    setOpen,
    order,
    fetchOrders
}) {

    const restaurantContext = useContext(restaurant)

    const [method, setMethod] = useState(null);
    const [isLoading, setIsLoading] = useState(false)
    const [otherMethod, setOtherMethod] = useState("");
    const [customerPaid, setCustomerPaid] = useState(0);
    const [tip, setTip] = useState(0);
    const [paymentModificationReason, setPaymentModificationReason] = useState("");
    const [partPayments, setPartPayments] = useState([
        {
            method: null,
            amount: 0,
            otherMethod: "",
        },
    ]);


    useEffect(() => {
        if (order) {
            setMethod(order?.payment?.method ?? "");
            setOtherMethod(order?.payment?.otherMethod ?? "")
            setCustomerPaid(order?.payment?.customerPaid ?? 0)
            setTip(order?.payment?.tip ?? 0)
            setPartPayments(order?.payment?.parts ?? [])
        }

    }, [order])

    const saveHandler = async () => {

        try {
            if (!method) {
                setIsLoading(false)
                return toast.error("Please select a payment method", {
                    autoClose: 2000,
                });
            }

            if (!paymentModificationReason) {
                setIsLoading(false)
                return toast.error("Please Enter reason", {
                    autoClose: 2000,
                });
            }
            if (method === "Other" && !otherMethod) {
                setIsLoading(false)
                return toast.error("Please enter a payment method", {
                    autoClose: 2000,
                });
            }
            const res = await axios.put(
                `/restaurants/${localStorage.getItem("restaurant")}/orders/${order._id
                }/settlement`,
                {
                    paymentModificationReason,
                    payment: {
                        method: method,
                        otherMethod: otherMethod,
                        customerPaid:
                            method === "Due"
                                ? 0
                                : method === "Part"
                                    ? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
                                    : customerPaid,
                        tip,
                        parts: method === "Part" ? partPayments : [],
                    },
                },
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );

            fetchOrders()
            setIsLoading(false)

            setOpen(false);
            return res
        } catch (error) {
            setIsLoading(false)
            console.log(error);
            toast.error(error.response?.data?.message ?? "Updating settlement failed")
        } finally {
            setIsLoading(false)
        }

    }


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "400px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Edit Settlement
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox role="form">
                            <MDBox >
                                <MDTypography
                                    variant="h6"
                                    component="h6"
                                    fontWeight={500}
                                >
                                    OrderNo : ({order?.orderNumber})
                                </MDTypography>
                            </MDBox>
                            <MDBox >
                                <MDTypography
                                    variant="h6"
                                    component="h6"
                                    fontWeight={500}
                                >
                                    Payment Type
                                </MDTypography>
                                <RadioGroup
                                    row
                                    name="payment-method"

                                    value={method}
                                    onChange={(e) => {
                                        setMethod(e.target.value);
                                    }}
                                >
                                    <FormControlLabel value="Cash" control={<Radio />} label="Cash" />
                                    <FormControlLabel value="Card" control={<Radio />} label="Card" />
                                    <FormControlLabel value="Due" control={<Radio />} label="Due" />
                                    <FormControlLabel
                                        value="Other"
                                        control={<Radio />}
                                        label="Other"
                                    />
                                    <FormControlLabel value="Part" control={<Radio />} label="Part" />
                                </RadioGroup>
                            </MDBox>
                            {method === "Other" && (
                                <MDBox mb={1}>
                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                        <InputLabel id="demo-simple-select-standard-label">
                                            Other
                                        </InputLabel>
                                        <Select
                                            // labelId="demo-simple-select-standard-label"
                                            id="demo-simple-select-standard"
                                            sx={{ width: "100%", height: "29px" }}
                                            value={otherMethod}
                                            onChange={(e) => {
                                                setOtherMethod(e.target.value);
                                            }}
                                            name="isVeg"
                                            variant="standard"
                                        >
                                            <MenuItem value={"UPI"}>UPI</MenuItem>
                                            <MenuItem value={"Paytm"}>Paytm</MenuItem>
                                            <MenuItem value={"GPay"}>GPay</MenuItem>
                                            <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                                            <MenuItem value={"forestfoods"}>forestfoods</MenuItem>
                                            <MenuItem value={"Zomato Pay"}>Zomato Pay</MenuItem>
                                            <MenuItem value={"Swiggy Pay"}>Swiggy Pay</MenuItem>
                                        </Select>
                                    </FormControl>
                                </MDBox>
                            )}
                            {method === "Part" && (
                                <MDBox mb={2}>
                                    <MDTypography
                                        variant="h6"
                                        component="h6"
                                        color="#824500"
                                        fontWeight={500}

                                    >
                                        Part Payments
                                    </MDTypography>
                                    {partPayments.map((partPayment, index) => {
                                        return (
                                            <div
                                                className={styles.partPaymentBox} >
                                                <div
                                                    style={{
                                                        padding: "8px 0",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        justifyContent: "space-between",
                                                    }}
                                                >
                                                    <label
                                                        htmlFor={"payment-method-" + index.toString()}
                                                        style={{
                                                            fontWeight: 500,
                                                        }}
                                                    >
                                                        Method
                                                    </label>

                                                    <Delete
                                                        color="error"
                                                        fontSize="small"
                                                        onClick={() => {
                                                            const newPartPayments = [...partPayments];
                                                            newPartPayments.splice(index, 1);
                                                            setPartPayments(newPartPayments);
                                                        }}
                                                    />
                                                </div>
                                                <RadioGroup
                                                    row
                                                    name={"payment-method-" + index.toString()}

                                                    value={partPayment.method}
                                                    onChange={(e) => {
                                                        const newPartPayments = [...partPayments];
                                                        newPartPayments[index].method = e.target.value;
                                                        setPartPayments(newPartPayments);
                                                    }}
                                                >
                                                    <FormControlLabel
                                                        value="Cash"
                                                        control={<Radio />}
                                                        label="Cash"
                                                    />
                                                    <FormControlLabel
                                                        value="Card"
                                                        control={<Radio />}
                                                        label="Card"
                                                    />
                                                    <FormControlLabel
                                                        value="Other"
                                                        control={<Radio />}
                                                        label="Other"
                                                    />
                                                </RadioGroup>
                                                {partPayment.method === "Other" && (
                                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">
                                                            Other
                                                        </InputLabel>
                                                        <Select
                                                            // labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            sx={{ width: "100%", height: "45px", marginBottom: "10px" }}
                                                            value={partPayment.otherMethod}
                                                            onChange={(e) => {
                                                                const newPartPayments = [...partPayments];
                                                                newPartPayments[index].otherMethod =
                                                                    e.target.value;
                                                                setPartPayments(newPartPayments);
                                                            }}
                                                        >
                                                            <MenuItem value={"UPI"}>UPI</MenuItem>
                                                            <MenuItem value={"Paytm"}>Paytm</MenuItem>
                                                            <MenuItem value={"GPay"}>GPay</MenuItem>
                                                            <MenuItem value={"PhonePe"}>PhonePe</MenuItem>
                                                            <MenuItem value={"forestfoods"}>forestfoods</MenuItem>
                                                            <MenuItem value={"Zomato Pay"}>Zomato Pay</MenuItem>
                                                            <MenuItem value={"Swiggy Pay"}>Swiggy Pay</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                )}
                                                <MDInput
                                                    type="number"
                                                    label="Amount"
                                                    name={"part_payment_amount_" + index}
                                                    id={"part_payment_amount_" + index}
                                                    value={partPayment.amount}
                                                    onChange={(e) => {
                                                        const newPartPayments = [...partPayments];
                                                        newPartPayments[index].amount = parseInt(
                                                            e.target.value
                                                        ) < 0 ? 0 : parseInt(e.target.value);
                                                        setPartPayments(newPartPayments);
                                                    }}
                                                    variant="standard"
                                                    fullWidth
                                                />
                                            </div>
                                        );
                                    })}
                                    <div className={styles.addPartBtn}>

                                        <Fab
                                            variant="extended"
                                            color="primary"
                                            size="small"
                                            onClick={() => {
                                                const newPartPayments = [...partPayments];
                                                newPartPayments.push({
                                                    method: "Cash",
                                                    amount: 0,
                                                });
                                                setPartPayments(newPartPayments);
                                            }}
                                        >
                                            <Add />
                                            Add Part Payment
                                        </Fab>
                                    </div>
                                </MDBox>
                            )}

                            <MDBox mb={1}>
                                <MDInput
                                    type="number"
                                    label="Customer Paid"
                                    name="customer_paid"
                                    id="customer_paid"
                                    value={
                                        method === "Due"
                                            ? 0
                                            : method === "Part"
                                                ? partPayments.reduce((a, b) => a + parseInt(b.amount), 0)
                                                : customerPaid
                                    }
                                    disabled={method === "Due" || method === "Part"}
                                    onChange={(e) => {
                                        setCustomerPaid(e.target.value < 0 ? 0 : e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={1}>
                                <MDInput
                                    type="number"
                                    label="Tip"
                                    name="tip"
                                    id="tip"
                                    value={tip}
                                    onChange={(e) => {
                                        setTip(e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={1}>
                                <MDInput
                                    type="text"
                                    label="Payment Modification reason"
                                    name="paymentModificationReason"
                                    id="paymentModificationReason"
                                    value={paymentModificationReason}
                                    onChange={(e) => {
                                        setPaymentModificationReason(e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>


                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={() => {
                                        const callFunction = saveHandler();
                                        toast.promise(callFunction, {
                                            loading: "Updating Order...",
                                            error: "Updating Order Failed",
                                            success: "Order Updated Successfully"
                                        });
                                    }}
                                    disabled={isLoading}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default EditBillDialog;
