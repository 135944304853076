import * as XLSX from "xlsx";

const exportToExcel = (heading,data, date) => {
  const dataToExport = [
    [heading],
    [],
    ...data
  ];

  const worksheet = XLSX.utils.aoa_to_sheet(dataToExport);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  // Generate and download the Excel file
  XLSX.writeFile(workbook, heading+date+".xlsx");
};

export default exportToExcel;
