import axios from "axios";
import React, { useContext, useState } from "react";
import toast from "../../utils/toast";
import logger from "../../utils/logger";
import { Card, Grid } from "@mui/material";
import vibrator from "../../utils/vibrator";
import MuiDrawer from "@mui/material/Drawer";
import commonStyles from "../../styles.module.css";
import {
	Dialog,
	FormLabel,
	InputAdornment,
	TextField,
	Typography,
	Input
} from "@mui/material";
import styles from "./PastOrderSummary.module.css";
import {
	CancelRounded,
	PrintRounded,
	Search,
	VisibilityRounded,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import GradientBorder from "../../components/GradientBorder/GradientBorder";
import OrderSummaryDetails from "./OrderSummaryDetails";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { DatePicker } from "antd";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { ClipLoader } from "react-spinners";
import { admin } from "providers/AdminProvider";
import { RiEdit2Fill } from "react-icons/ri";
import regexSearch from "utils/regexSearch";
import EditBillDialog from "./EditBillDialog";
import { restaurant } from "providers/RestaurantProvider";

const PastOrderSummary = () => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const restaurantContext = useContext(restaurant)

	const [selectedOrder, setSelectedOrder] = useState(null);
	const [editBillDialogOpen, setEditBillDialogOpen] = useState(false)
	const location = useLocation();

	if (!location.state) {
		location.state = {};
	}

	const [orders, setOrders] = useState(location.state?.orders || []);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const { setLoading } = useContext(admin)
	const [isSearching, setIsSearching] = useState(
		location.state?.isSearching || false
	);
	const [searchQuery, setSearchQuery] = useState(
		location.state?.searchQuery || ""
	);
	const [orderID, setOrderID] = useState("");
	const [from, setFrom] = useState(location.state?.from || "");
	const [isLoading, setIsLoading] = useState(false)
	const [to, setTo] = useState(location.state?.to || "");
	const [batchSize, setBatchSize] = useState(location.state?.batchSize || 100);
	let cancelReason = "";
	let password = "";
	const totalTaxValue = restaurantContext.restaurant.taxes.reduce(
		(acc, item) => {
			return acc + item.value;
		},
		0
	);
	const fetchOrders = async () => {
		setIsLoading(true)
		setLoading(true)
		const todayTimestamp = new Date().setHours(0, 0, 0, 0);
		logger(todayTimestamp);
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/orders/timeRange?from=${from}&to=${to}&batchSize=${batchSize}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			setOrders(res.data);
			setIsLoading(false)
			setLoading(false)
			location.state.orders = res.data;
			return res
		} catch (error) {
			logger(error);
			setIsLoading(false)
			setLoading(false)
			toast.error(error?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	const handleCancel = async () => {
		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem("restaurant")}/orders/${orderID}`,
				{
					status: "cancelled",
					cancelReason,
				},
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);

			logger(res.data);

			fetchOrders();
			setConfirmDialogOpen(false);
			return res
		} catch (error) {
			logger(error);
			toast.error(error?.response?.data?.message ?? "Network connection failed", {
				autoClose: 2000,
			});
		}
	};

	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => setConfirmDialogOpen(false)}
				sx={{
					"& .MuiDialog-paper": {
						backgroundColor: "transparent",
						boxShadow: "none",
					},

					"& .MuiModal-backdrop": {
						backdropFilter: "blur(8px)",
					},
				}}
			>
				<Card sx={{ width: "400px", maxWidth: "100%" }}>
					<MDBox
						variant="gradient"
						bgColor="info"
						// borderRadius="lg"
						coloredShadow="success"
						sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
						mx={2}
						mt={-1}
						p={1}
						textAlign="center"
					>
						<MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
							Confirm cancellation
						</MDTypography>
					</MDBox>
					<MDBox pt={2} pb={3} px={3}>
						<MDBox component="form" role="form">
							<MDBox mb={2}>
								<MDInput
									type="password"
									label="Password"
									name="password"
									onChange={(e) => {
										password = e.target.value;
									}}
									variant="standard"
									fullWidth
								/>
							</MDBox>
							<MDBox mb={2}>
								<MDInput
									type="text"
									label="Cancel reason"
									onChange={(e) => {
										cancelReason = e.target.value;
									}}
									variant="standard"
									fullWidth
								/>
							</MDBox>

							<MDBox mt={4} mb={1}>
								<MDButton
									variant="gradient"
									color="info"
									fullWidth
									onClick={() => {
										if (password === "st24632") {
											if (cancelReason === "") {
												toast.error("Please enter a reason", {
													autoClose: 2000,
												});
												return;
											}
											logger(orderID);
											const callFunction = handleCancel();
											toast.promise(callFunction, {
												loading: "Cancelling Order...",
												error: "Cancelling Order Failed.",
												success: "Order Cancelled Successfully"
											});
										} else {
											toast.error("Incorrect Password", {
												autoClose: 2000,
											});
										}
									}}
								>
									Save{" "}
									{/* <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    /> */}
								</MDButton>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>

			</Dialog>
		);
	};
	const { RangePicker } = DatePicker;
	const handleRangeChange = (dateArr) => {
		const start = new Date(new Date(dateArr[0]).setHours(0, 0, 0, 0));
		const end = new Date(new Date(dateArr[1]).setHours(23, 59, 59, 999));
		setFrom(start);
		setTo(end);
	};

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.orderSummary} />
			<DashboardNavbar />
			<div
				style={{

					position: "relative",
				}}
			>
				<div className={styles.topHeadingContainer}>
					<div className={styles.inputs}>
						<div className={styles.input}>

							<RangePicker onChange={handleRangeChange} size="large" />
						</div>
						<div className={styles.input} sx={{ backgroundColor: "white" }}>

							<TextField
								label={"Batch Size"}
								placeholder="Batch Size"
								type="number"
								value={batchSize}
								className="custom-textfield"
								onChange={(e) => {
									setBatchSize(e.target.value);
									location.state.batchSize = e.target.value;
								}} />
						</div>

						<button
							variant="contained"
							onClick={() => {
								const callFunction = fetchOrders();
								toast.promise(callFunction, {
									loading: "Fetching Orders...",
									error: "Fetching Orders Failed",
									success: "Orders Refreshed Successfully"
								});
							}}
							className={commonStyles.primary_button}
						>
							Search
							<ClipLoader
								className="ms-1"
								loading={isLoading}
								size={10}
								color="white"
							/>
						</button>
					</div>
				</div>
				{orders.length > 0 && (
					<div className={styles.mainContainer}>
						<div className={styles.top_bar}>
							<TextField
								placeholder="Search"
								className={styles.search + " custom-textfield"}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<Search />
										</InputAdornment>
									),
								}}
								value={searchQuery}
								onChange={(e) => {
									setSearchQuery(e.target.value);
								}}
							/>
							<div className={styles.legends}>
								<div className={styles.legend}>
									<span className={styles.settled}></span>
									Settled
								</div>
								<div className={styles.legend}>
									<span className={styles.completed}></span>
									Billed
								</div>
								<div className={styles.legend}>
									<span className={styles.cancelled}></span>
									Cancelled
								</div>
								<div className={styles.legend}>
									<span className={styles.running}></span>
									Running
								</div>
							</div>
						</div>

						<MDBox pt={6} pb={3}>
							<Grid container spacing={6}>
								<Grid item xs={12}>
									<Card>
										<MDBox
											mx={2}
											mt={-3}
											py={3}
											px={2}
											variant="gradient"
											bgColor="info"
											borderRadius="lg"
											coloredShadow="info"
										>
											<MDTypography variant="h6" color="white">
												Order List
											</MDTypography>
										</MDBox>
										<MDBox pt={3}>
											{orders.length > 0 && (
												<DataTable
													table={{
														columns: [
															{
																Header: "Order No",
																accessor: "orderNo",
															},
															{
																Header: "Order Type",
																accessor: "orderType",
															},
															{
																Header: "Customer Name",
																accessor: "userName",
															},
															{
																Header: "Customer Phone",
																accessor: "userPhone",
															},
															{
																Header: "Payment Type",
																accessor: "paymentMethod",
															},
															{
																Header: "My Amount",
																accessor: "myAmount",
															},
															{
																Header: "Status",
																accessor: "status",
															},
															{
																Header: "Tax",
																accessor: "tax",
															},
															{
																Header: "Discount",
																accessor: "discount",
															},
															{
																Header: "Grand Total",
																accessor: "grandTotal",
															},
															{
																Header: "Created",
																accessor: "createdAt",
															},
															{
																Header: "Actions",
																accessor: "actions",
															},
														],
														rows: orders.filter((order) => {
															return (regexSearch(order?.orderNumber, searchQuery))
														}
														).map((ord) => {
															let myAmount = ord.kots.reduce((acc, kot) => {
																return acc + kot.total;
															}, 0);
															return {
																orderNo: ord?.orderNumber,
																orderType: "Dine In",
																userName: ord.user?.name,
																userPhone: ord.user?.phone,
																paymentMethod: ord.payment?.method || "Due",
																myAmount: myAmount,
																tax: (myAmount * (totalTaxValue / 100)).toFixed(2),
																discount: (ord.discount?.amount ?? 0).toFixed(2),
																status: ord.status,

																grandTotal: (myAmount * (1 + (totalTaxValue / 100))).toFixed(2),
																createdAt: new Date(
																	ord.createdAt
																).toLocaleString(),
																actions: (
																	<div className={styles.actions}>
																		{
																			ord.status == "settled" ?
																				<RiEdit2Fill
																					style={{ cursor: "pointer" }}
																					onClick={() => {
																						setEditBillDialogOpen(true)
																						setSelectedOrder({ ...ord });
																					}}
																				></RiEdit2Fill> : null
																		}
																		<VisibilityRounded
																			onClick={() => {
																				vibrator.tap();
																				setIsDrawerOpen(true);
																				setSelectedOrder(ord);
																			}}
																			style={{ cursor: "pointer" }}
																		/>
																		{ord.status !== "cancelled" && (
																			<CancelRounded
																				onClick={() => {
																					setOrderID(ord._id);
																					setConfirmDialogOpen(true);
																				}}
																			/>
																		)}
																	</div>
																),
															};
														}),
													}}
													isSorted={false}
													entriesPerPage={false}
													showTotalEntries={false}
													noEndBorder
												/>
											)}
										</MDBox>
									</Card>
								</Grid>
							</Grid>
						</MDBox>
					</div>
				)}
				<ConfirmDialog />
				<React.Fragment>
					<MuiDrawer
						variant="temporary"
						anchor="right"
						open={isDrawerOpen}
						onClose={() => setIsDrawerOpen(false)}
						sx={{
							"& .MuiModal-backdrop": {
								backdropFilter: "blur(5px)",
							},

							"& .MuiDrawer-paper": {
								width: "85%",
								maxWidth: "100%",
							},
						}}
					>
						<div
							className={styles.details}
							style={{
								display: "flex",
								flexDirection: "column",
							}}
						>
							<div
								style={{
									display: "flex",
									justifyContent: "flex-end",
									marginRight: "10px",
								}}
							></div>
							<OrderSummaryDetails order={selectedOrder} />
						</div>
					</MuiDrawer>
				</React.Fragment>
			</div>
			<EditBillDialog
				open={editBillDialogOpen}
				fetchOrders={fetchOrders}
				setOpen={setEditBillDialogOpen}
				order={selectedOrder}
			/>
		</DashboardLayout>
	);
};

export default PastOrderSummary;
