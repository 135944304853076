import React, { useEffect, useState } from "react";
import styles from "./RunningOrders.module.css";
import RunningOrdersCard from "./RunningOrdersCard/RunningOrdersCard"
import CurrencyRupeeOutlinedIcon from "@mui/icons-material/CurrencyRupeeOutlined";
import { useContext } from "react";
import { admin } from "../../../providers/AdminProvider";
import { restaurant } from "providers/RestaurantProvider";

function RunningOrders() {
  const context = useContext(admin);
  const restaurantContext = useContext(restaurant);
  const { runningOrders } = context;
  

  const takeAwayTables = [], dineInTables = [];
  restaurantContext.restaurant?.sections
    .map(section => {
      if (section.type === 'takeaway') {
        section?.tables.map((table) => {
          takeAwayTables.push(table.name)
        });
      } else if (section.type === "dinein") {
        section?.tables.map((table) => {
          dineInTables.push(table.name)
        });
      }

    })
  let takeAwayTotal = 0, takeAwayCount = 0, dineInTotal = 0, dineInCount = 0, total=0, totalRunning=0;
  runningOrders.forEach(ord => {
    if(ord.status==="running"){
      totalRunning++;
      const orderTotal =
      ord.kots?.reduce((acc, kotID) => {
        return acc + context?.runningKOTs[kotID]?.total ?? 0;
      }, 0) ?? 0;
    if (takeAwayTables.includes(ord?.table?.name)) {
      takeAwayTotal += orderTotal;
      takeAwayCount++;
    } else if (dineInTables.includes(ord?.table?.name)) {
      dineInTotal += orderTotal;
      dineInCount++;
    }
    total+=orderTotal
  }

  })
  return (
    <div className={styles.mainContainer}>
      <div className={styles.runningOrderDetails + " " + styles.orderHead}>
        <div className={styles.runningOrderItem}>
          <b>{totalRunning}</b>
          <span>Running Orders</span>
        </div>
        <div className={styles.runningOrderItem}>
          <b>₹{total}</b>
          <span>Estimated Total</span>
        </div>
      </div>
      <div className={styles.runningOrderDetails}>
        <div className={styles.runningOrderItem}>
          <h4>DineIn</h4>
          <span>Orders</span>
          <b>{dineInCount}</b>
          <span>Estimated Total</span>
          <b>₹{dineInTotal}</b>
        </div>
      </div>
      <div className={styles.runningOrderDetails}>
        <div className={styles.runningOrderItem}>
          <h4>Takeaway</h4>
          <span>Orders</span>
          <b>{takeAwayCount}</b>
          <span>Estimated Total</span>
          <b>₹{takeAwayTotal}</b>
        </div>
        <div className={styles.runningOrderItem}>
          <h4>Delivery</h4>
          <span>Orders</span>
          <b>0</b>
          <span>Estimated Total</span>
          <b>₹{0}</b>
        </div>
      </div>
    </div>
  );
}

export default RunningOrders;
