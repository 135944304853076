import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { RiArrowDownSLine, RiLineChartFill } from 'react-icons/ri'
import styles from '../dashboardmobile.module.css'

export default function OnlineAccordion({heading, data}) {
    return (
        <Accordion elevation={0}>
            <AccordionSummary
                expandIcon={<RiArrowDownSLine />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <div className={styles.dashboard_item}>
                    <div className={styles.dashboard_item_left}>
                        <div className={styles.dashboard_item_icon + " " + styles.orange}>
                            <RiLineChartFill />
                        </div>
                        <div className={styles.dashboard_item_head}>
                            <b>{heading}</b>
                            <span>{data?.orders?.length ?? 0} Orders</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{data?.total ?? 0}</b>
                    </div>
                </div>
            </AccordionSummary>
            <AccordionDetails>
                <div className={styles.dashboard_item + " " + styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Minimum</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{data?.minOrder ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + ' '+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Maximum</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{data?.maxOrder ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + ' '+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Average</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{data?.orderAvg ?? 0}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + ' '+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Aggregator Commision 18% </span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{(data?.total ?? 0)*18/100}</b>
                    </div>
                </div>
                <div className={styles.dashboard_item + ' '+ styles.inside}>
                    <div className={styles.dashboard_item_left}>
                        <div className="">
                            <span>Total after Deduction</span>
                        </div>
                    </div>
                    <div className={styles.dashboard_item_right}>
                        <b>₹{(data?.total ?? 0)-((data?.total ?? 0)*18/100)}</b>
                    </div>
                </div>
            
            </AccordionDetails>
        </Accordion>
    )
}
