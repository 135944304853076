import React, { useContext, useEffect, useState } from "react";
import styles from "./ImageUploadModal.module.css";
import commonStyles from "styles.module.css";
import { Dialog, Typography } from "@mui/material";
import GradientBorder from "components/GradientBorder/GradientBorder";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import { restaurant } from "providers/RestaurantProvider";
import { useNavigate } from "react-router-dom";

const CategoryImageUploadModal = ({ open, setOpen, image, categoryId }) => {
	const [imageSrc, setImageSrc] = useState(image);
	const [imageToUpload, setImageToUpload] = useState(null);
    const navigate= useNavigate()
    const restaurantContext= useContext(restaurant)
	const handleUpload = async () => {
		const formData = new FormData();
		formData.append("image", imageToUpload);

		try {
			const res = await axios.put(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/category/image/${categoryId}`,
				formData,
				{
					headers: {
						"Content-Type": "multipart/form-data",
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			
			logger(res);
			
			toast.success("Image uploaded successfully");
			await restaurantContext.refreshRestaurant()
            window.location.reload()

			setOpen(false);
			return res;
		} catch (err) {
			logger(err);
			toast.error(err.response?.data?.message || "Network connection failed");
		}
	};

	useEffect(()=>{
		setImageSrc(image ?? "https://cdn.pixabay.com/photo/2017/02/07/02/16/cloud-2044823_640.png")
	},[image])

	return (
		<Dialog
			open={open}
			onClose={() => setOpen(false)}
			sx={{
				"& .MuiDialog-paper": {
					backgroundColor: "transparent",
					boxShadow: "none",
					borderRadius:"20px"
				},

				"& .MuiModal-backdrop": {
					backdropFilter: "blur(8px)",
				},
			}}
		>

				<div className={styles.container}>
					<input
						type="file"
						hidden
						id="item__image__upload"
						onChange={(e) => {
							setImageToUpload(e.target.files[0]);
							setImageSrc(URL.createObjectURL(e.target.files[0]));
						}}
					/>
					<img
						src={imageSrc}
						alt="item"
						className={styles.image}
						onClick={() => {
							document.getElementById("item__image__upload").click();
						}}
					/>
					<div className={styles.guidelines}>
						<Typography
							variant="body1"
							component="div"
							color="#8A005C"
							sx={{ marginBottom: "12px" }}
							style={{ fontWeight: "bold" }}
						>
							Guidelines
						</Typography>
						<ol>
							<li>Image should be in 1:1 ratio</li>
							<li>Image size should be less than 1MB</li>
							<li>Image should be in .jpg, .jpeg or .png format</li>
						</ol>
					</div>
					<div className={styles.button_container}>
						<button
							className={commonStyles.secondary_button}
							onClick={() => {
								setOpen(false);
							}}
						>
							Cancel
						</button>
						<button
							className={commonStyles.primary_button}
							onClick={()=>{
								toast.promise(handleUpload(),{
									loading: "Updating Image...",
									error: "Updation Failed",
									success: "Updated Successfully"
								})
							}}
						>
							Upload
						</button>
					</div>
				</div>
		</Dialog>
	);
};

export default CategoryImageUploadModal;
