import React, { useContext, useEffect, useState } from "react";
import ConsumptionReport from "./ConsumptionReport/ConsumptionReport";
import PurchaseSummary from "./PurchaseSummary/PurchaseSummary";
import styles from "./InventoryDashboard.module.css";
import WastageReport from "./WastageReport/WastageReport";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { inventory } from "providers/InventoryProvider";
import toast from "utils/toast";


function InventoryDashboard() {
	const inventoryContext = useContext(inventory);
	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	useEffect(() => {
		const callFunction1=inventoryContext.fetchConsumptionBills(startDate, endDate);
		const callFunction2=inventoryContext.fetchAdditionBill(startDate, endDate);
		const callFunction=Promise.all([callFunction1, callFunction2])
        toast.promise(callFunction, {
            loading: "Fetching Inventory Data",
            error: "Fetching Inventory Data Failed",
            success: "Inventory data refreshed Successfully"
        });
	}, [startDate, endDate])
	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.inventory} />
			<DashboardNavbar
				showDateFilter
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startDate={startDate}
				endDate={endDate}
			/>
			<div

			>
				<div className={styles.mainContainer}>
					<div className={styles.inventory_col}>
						<div className={styles.inventoryBox}>
							<ConsumptionReport startDate={startDate} endDate={endDate} />
						</div>
						<div className={styles.inventoryBox}>
							<WastageReport />
						</div>
					</div>
					<div className={styles.inventory_col}>

						<div className={styles.inventoryBox}>
							<PurchaseSummary startDate={startDate} endDate={endDate} />
						</div>
					</div>


				</div>
			</div>
		</DashboardLayout>
	);
}

export default InventoryDashboard;
