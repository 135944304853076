import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./RunningTables.module.css";
import RunningTableCard from "./RunningTableCard/RunningTableCard";
import { admin } from "../../../providers/AdminProvider";
import { useContext } from "react";
import logger from "../../../utils/logger";
import GradientBorder from "../../../components/GradientBorder/GradientBorder";
import toast from "../../../utils/toast";
import { Card, Dialog, Typography } from "@mui/material";
import { Margin } from "@mui/icons-material";
import MuiDrawer from "@mui/material/Drawer";
import RunningTableDetails from "./RunningTableDetails/RunningTableDetails";
import OrderSummaryDetails from "../../OrderSummary/OrderSummaryDetails";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function RunningTables() {
	const [showOrderSummary, setShowOrderSummary] = useState(false);
	const adminConetext = useContext(admin)
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [orderId, setOrderId] = useState();
	const [personsName, setPersonsName] = useState([]);
	const [personsMobile, setPersonsMobile] = useState([]);
	const [currrentTable, setCurrentTable] = useState({});
	const showTableDetail = (tableOrder) => {
		setCurrentTable(tableOrder);
		setIsDrawerOpen(true);
	};

	const context = useContext(admin);
	const { runningOrders, runningKOTs } = context;

	const showPersons = (tableOrder) => {
		let name = [];
		let phone = [];
		tableOrder["kots"]
			.map((kotID) => runningKOTs[kotID])
			.forEach((k) => {
				let flag = false;
				if (k.userData) {
					if (
						name.includes(k["userData"]["name"]) &&
						phone.includes(k["userData"]["phone"])
					) {
						let ind1 = name.findIndex((name) => name === k["userData"]["name"]);
						let ind2 = phone.findIndex(
							(phone) => phone === k["userData"]["phone"]
						);
						if (ind1 === ind2) {
							flag = true;
						}
					}
					if (!flag) {
						name.push(k["userData"]["name"]);
						phone.push(k["userData"]["phone"]);
					}
				}
			});

			setPersonsName(name);
			setPersonsMobile(phone);
			setConfirmDialogOpen(true);
	};

	const showOrderDetail = (id) => {
		setOrderId(id);
		setShowOrderSummary(true);
	};

	let estimatedTotal=0, totalRunning=0;
	runningOrders?.forEach((ord)=>{
		if(ord.status==="running"){
			totalRunning++;
			ord?.kots?.forEach((kotID)=>{
				estimatedTotal+=context?.runningKOTs[kotID]?.total ??0
			}, 0)
		}
	}, 0)

	runningOrders.sort((a, b) => a.table.name - b.table.name);
	const ConfirmDialog = () => {
		return (
			<Dialog
				open={confirmDialogOpen}
				onClose={() => {
					setConfirmDialogOpen(false);
				}}
				// sx={{ zIndex: 200 }}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<Card sx={{ width: "400px", maxWidth: "100%" }}>
					<MDBox
						variant="gradient"
						bgColor="info"
						// borderRadius="lg"
						coloredShadow="success"
						sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
						mx={2}
						mt={-1}
						p={1}
						textAlign="center"
					>
						<MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
							User Information
						</MDTypography>
					</MDBox>
					<MDBox pt={2} pb={3} px={3}>
						<MDBox component="form" role="form">
							<MDBox mb={2}>
								<div style={{ display: "flex", gap: "20px" }}>
									<b>Name &nbsp;: </b>
									<span>{personsName[0] ?? "--"}</span>
								</div>
							</MDBox>
							<MDBox mb={2}>
								<div style={{ display: "flex", gap: "20px" }}>
									<b>Mobile : </b>
									<span>{personsMobile[0] ?? "--"}</span>
								</div>
							</MDBox>
						</MDBox>
					</MDBox>
				</Card>
			</Dialog>
		);

	};

	return (
		<div>
			<div className={styles.runningTableDetails+" "+styles.head}>
				<div className={styles.runningTableItem}>
					<b>{totalRunning}</b>
					<span>Running Tables</span>
				</div>
				<div className={styles.runningTableItem}>
					<b>₹{estimatedTotal}</b>
					<span>Estimated Total</span>
				</div>
				</div>
			<div className={styles.cardContainer} style={{ padding: "0px" }}>
				{runningOrders.filter(ord=>ord.status==="running").map((ro) => {
					return (
						<RunningTableCard
							showTableDetail={showTableDetail}
							key={ro._id}
							tableOrder={ro}
							showPersons={showPersons}
							showOrderDetail={showOrderDetail}
						/>
					);
				})}
			</div>
			<ConfirmDialog />
			<React.Fragment>
				<MuiDrawer
					variant="temporary"
					anchor="right"
					open={isDrawerOpen}
					onClose={() => setIsDrawerOpen(false)}
					sx={{
						"& .MuiModal-backdrop": {
							backdropFilter: "blur(5px)",
						},

						"& .MuiDrawer-paper": {
							width: "80%",
							maxWidth: "100%",
						},
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginRight: "10px",
							}}
						></div>
						<RunningTableDetails currrentTable={currrentTable} />
					</div>
				</MuiDrawer>
			</React.Fragment>
			<React.Fragment>
				<MuiDrawer
					variant="temporary"
					anchor="right"
					open={showOrderSummary}
					onClose={() => setShowOrderSummary(false)}
					sx={{
						"& .MuiModal-backdrop": {
							backdropFilter: "blur(5px)",
						},
					}}
				>
					<div
						style={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "flex-end",
								marginRight: "10px",
							}}
						></div>
						<OrderSummaryDetails orderId={orderId} />
					</div>
				</MuiDrawer>
			</React.Fragment>
			
		</div>
	);
}

export default RunningTables;
