import React, { useContext, useEffect, useState } from "react";
import { Card, Grid, Typography } from "@mui/material";
import styles from "./UpdateCategory.module.css";
import AddIcon from "@mui/icons-material/Add";
import { restaurant } from "../../../providers/RestaurantProvider";
import { admin } from "../../../providers/AdminProvider";
import logger from "../../../utils/logger";
import { CameraAltRounded, Delete, Edit } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import commonStyles from "../../../styles.module.css";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";
import AddCategoryDialog from "../AddCategory/AddCategoryDialog";
import AddItemDialog from "../AddItem/AddItemDialog";
import ImageUploadModal from "./ImageUploadModal";
import CategoryImageUploadModal from "./CategoryImageModel";
import { Popconfirm } from "antd";
import { QuestionCircleOutlined } from '@ant-design/icons';

const UpdateCategory = ({ setIsMainMenu }) => {
	const navigate = useNavigate();

	const [selectedItem, setSelectedItem] = useState([]);
	const [wantDelete, setWantDelete] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const adminContext = useContext(admin);
	const {
		categoryToUpdate,
		updateOne,
		setCategoryToUpdate,
		deleteItem,
		updateMenu,
		backStack,
		setBackStack,
		deleteCategory,
		goBack,
	} = adminContext;
	const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [editDialogData, setEditDialogData] = useState({});
	const [itemAdd, setItemAdd] = useState(false);
	const [catAdd, setCatAdd] = useState(false);
	const [currentCategory, setCurrentCategory] = useState(null);
	const restaurantContext = useContext(restaurant);
	const { items, setItems } = restaurantContext;
	const [updateItem, setUpdateItem] = useState({});
	const [imageModalOpen, setImageModalOpen] = useState(false);
	const [updateCategory, setUpdateCategory] = useState({});
	const [categoryImageModalOpen, setCategoryImageModalOpen] = useState(false);
	useEffect(() => {
		logger(selectedItem);
	}, [selectedItem]);

	useEffect(() => {
		logger(backStack);
	}, [backStack]);

	useEffect(() => {
		if (categoryToUpdate === null) {
			setIsMainMenu(true);
			return;
		}
	}, [categoryToUpdate]);

	const backHandler = (e) => {
		e.preventDefault();
		if (backStack.length > 1) {
			goBack(categoryToUpdate, setCategoryToUpdate, backStack, setBackStack);
		} else {
			setBackStack([]);
			setIsMainMenu(true);
		}
	};

	return (
		categoryToUpdate !== null && (
			<div

				className={styles.body}
			>
				<div>
					<div
						style={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							padding: "10px",
						}}
					>
						<button
							className={commonStyles.primary_button+" "+styles.btn}
							onClick={backHandler}
						>
							Back
						</button>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								alignItems: "center",
							}}
						>
							<button
								className={commonStyles.primary_button +" "+styles.btn}
								onClick={() => setCatAdd(true)}
							>
								<AddIcon style={{ height: "15px" }} />
								Add New Subcategory
							</button>
							<button
								className={commonStyles.primary_button +" "+styles.btn}
								onClick={() => {
									setUpdateItem({});
									setConfirmDialogOpen(true);
								}}
							>
								<AddIcon style={{ height: "15px" }} />
								Add New Item
							</button>
						</div>
					</div>
					{/* <div
						style={{
							display: "flex",
							alignItems: "center",
							width: "fit-content",
							flexDirection: "column",
							margin: "0px auto",
						}}
					> */}
						<h2 style={{ textAlign: "center" }}>{categoryToUpdate.name}</h2>
						{categoryToUpdate.subCategories.length > 0 && (
							<MDBox pt={6} pb={3}>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<Card>
											<MDBox
												mx={2}
												mt={-3}
												py={3}
												px={2}
												variant="gradient"
												bgColor="info"
												borderRadius="lg"
												coloredShadow="info"
											>
												<MDTypography variant="h6" color="white">
													Sub Categories
												</MDTypography>
											</MDBox>
											<MDBox pt={3}>
												<DataTable
													table={{
														columns: [
															{
																Header: "Category Name",
																accessor: "name",
															},
															{
																Header: "Image",
																accessor: "image",
																align: "center",
															},
															{
																Header: "Sub-Categories",
																accessor: "subcategories",
															},
															{
																Header: "Items",
																accessor: "items",
															},
															{
																Header: "Actions",
																accessor: "actions",
															},
														],
														rows: categoryToUpdate.subCategories.map((cat) => {
															return {
																name: (
																	<span
																		onClick={() => {
																			updateOne(cat, backStack, setBackStack);
																		}}
																	>
																		{cat.name}
																	</span>
																),
																subcategories: cat?.subCategories?.length ?? 0,
																image: (
																	<div
																		style={{
																			height: "50px",
																			width: "50px",
																			borderRadius: "50%",
																			overflow: "hidden",
																			display: "flex",
																			justifyContent: "center",
																			alignItems: "center",
																		}}
																		onClick={() => {
																			setUpdateCategory(cat);
																			setCategoryImageModalOpen(true);
																		}}
																	>
																		{cat?.image && cat.image != "" ? (
																			<img
																				src={cat.image}
																				alt={cat.name}
																				style={{
																					height: "100%",
																					width: "100%",
																					objectFit: "cover",
																				}}
																			/>
																		) : (
																			<CameraAltRounded fontSize="medium" />
																		)}
																	</div>
																),
																items: cat?.items?.length ?? 0,
																actions: (
																	<Popconfirm
																		title="Delete this Category"
																		onConfirm={()=>deleteCategory(false, cat?._id, categoryToUpdate?._id)}
																		description="This will also delete all items in this category?"
																		icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
																	>
																			<Delete
																				style={{
																					cursor: "pointer",
																					height: "18px",
																					color: "#9E5E28",
																				}}
																			/>
																	</Popconfirm>

																),
															};
														}),
													}}
													isSorted={false}
													entriesPerPage={30}
													showTotalEntries={false}
													noEndBorder
												/>
											</MDBox>
										</Card>
									</Grid>
								</Grid>
							</MDBox>
						)}

						{categoryToUpdate.items.length > 0 && (
							<MDBox pt={6} pb={3}>
								<Grid container spacing={6}>
									<Grid item xs={12}>
										<Card>
											<MDBox
												mx={2}
												mt={-3}
												py={3}
												px={2}
												variant="gradient"
												bgColor="info"
												borderRadius="lg"
												coloredShadow="info"
											>
												<MDTypography variant="h6" color="white">
													Items
												</MDTypography>
											</MDBox>
											<MDBox pt={3}>
												<DataTable
													table={{
														columns: [
															{
																Header: "Item Name",
																accessor: "name",
															},
															{
																Header: "Image",
																accessor: "image",
																align: "center",
															},
															{
																Header: "Price",
																accessor: "price",
																align: "center",
															},
															{
																Header: "Vegetarian?",
																accessor: "vegetarian",
																align: "center",
															},
															{
																Header: "Bar?",
																accessor: "bar",
																align: "center",
															},
															{
																Header: "Complements?",
																accessor: "complements",
																align: "center",
															},
															{
																Header: "Customizations?",
																accessor: "customizations",
																align: "center",
															},
															{
																Header: "Actions",
																accessor: "actions",
																align: "center",
															},
														],
														rows: categoryToUpdate.items.map((itemID) => {
															const item = restaurantContext?.items?.[itemID];
															return {
																name: <span>{item?.name}</span>,
																image: (
																	<div
																		style={{
																			height: "50px",
																			width: "50px",
																			borderRadius: "50%",
																			overflow: "hidden",
																			display: "flex",
																			justifyContent: "center",
																			alignItems: "center",
																		}}
																		onClick={() => {
																			setUpdateItem(item);
																			setImageModalOpen(true);
																		}}
																	>
																		{item?.image && item?.image != "" ? (
																			<img
																				src={item?.image}
																				alt={item?.name}
																				style={{
																					height: "100%",
																					width: "100%",
																					objectFit: "cover",
																				}}
																			/>
																		) : (
																			<CameraAltRounded fontSize="medium" />
																		)}
																	</div>
																),
																price: item?.price,
																vegetarian: item?.isVeg ? "Yes" : "No",
																bar: item?.isBar ? "Yes" : "No",
																complements:
																	item?.complements?.length ?? 0 ? "Yes" : "No",
																customizations:
																	item?.properties?.length ?? 0 ? "Yes" : "No",
																actions: (
																	<div style={{display:"flex", gap:"20px"}}>
																	<span
																		onClick={() => {
																			setUpdateItem(item);
																			setConfirmDialogOpen(true);
																		}}
																	>
																		<Edit
																			style={{
																				cursor: "pointer",
																				height: "18px",
																				color: "#9E5E28",
																			}}
																		/>
																	</span>
																	<span>
																		<Popconfirm
																		title="Delete this Item"
																		onConfirm={()=>deleteItem(item?._id, categoryToUpdate?._id)}
																		description="Are you sure to delete this Item?"
																		icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
																	>
																			<Delete
																				style={{
																					cursor: "pointer",
																					height: "18px",
																					color: "#9E5E28",
																				}}
																			/>
																	</Popconfirm>
																	</span>
																	</div>

																),
															};
														}),
													}}
													isSorted={false}
													entriesPerPage={30}
													showTotalEntries={false}
													noEndBorder
												/>
											</MDBox>
										</Card>
									</Grid>
								</Grid>
							</MDBox>
						)}
					{/* </div> */}

					<AddCategoryDialog
						open={catAdd}
						setOpen={setCatAdd}
						parentCategoryId={categoryToUpdate._id}
						categoryToUpdate={categoryToUpdate}
						mainMenu={false}
						setCategoryToUpdate={setCategoryToUpdate}
					/>
					<AddItemDialog
						categoryToUpdate={categoryToUpdate}
						setCategoryToUpdate={setCategoryToUpdate}
						confirmDialogOpen={confirmDialogOpen}
						setConfirmDialogOpen={setConfirmDialogOpen}
						item={updateItem}
						isUpdate={updateItem._id ? true : false}
					/>
				</div>
				<ImageUploadModal
					open={imageModalOpen}
					setOpen={setImageModalOpen}
					image={updateItem.image}
					itemID={updateItem._id}
				/>
				<CategoryImageUploadModal
					open={categoryImageModalOpen}
					setOpen={setCategoryImageModalOpen}
					image={updateCategory.image}
					categoryId={updateCategory._id}
				/>
			</div>
		)
	);
};

export default UpdateCategory;
