import React, { useContext, useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { inventory } from "providers/InventoryProvider";
import MDInput from "components/MDInput";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import exportToExcel from "utils/exportToExcel";
import { admin } from "providers/AdminProvider";


function ProfitMarginCalculator() {

    const inventoryContext = useContext(inventory);
    const { consumptionBills, additionBills } = inventoryContext
    const [orders, setOrders] = useState([])
    const [search, setSearch] = useState("");
    const { setLoading } = useContext(admin)
    const [startDate, setStartDate] = useState(
        new Date(new Date().setHours(0, 0, 0, 0))
    );
    const [endDate, setEndDate] = useState(
        new Date(new Date().setHours(23, 59, 59, 999))
    );

    useEffect(() => {
        (async function () {
            const callFunction1 = fetchOrders();
            const callFunction2 = inventoryContext.fetchConsumptionBills(new Date(0), endDate);
            const callFunction3 = inventoryContext.fetchAdditionBill(new Date(0), endDate);
            const callFunction = Promise.all([callFunction1, callFunction2, callFunction3])
            toast.promise(callFunction, {
                loading: "Fetchin Data...",
                error: "Fetching Data Failed",
                success: "Data refreshed Successfully"
            });
            setLoading(false)
        })()
    }, [startDate, endDate]);

    const items = {

    }
    const consumptionBillsMap = {

    }
    for (let bill of consumptionBills) {
        consumptionBillsMap[bill._id] = bill
    }

    let totalPrice = 0

    for (let ord of orders) {
        for (let kot of ord.kots) {
            for (let item of kot.items) {

                if (!item.prepared) {
                    continue;
                }

                if (!items[item.item._id]) {
                    items[item.item._id] = {
                        item: item.item,
                        total: 0,
                        expense: 0,
                        quantity: 0
                    }
                }

                items[item.item._id].total += item.total
                items[item.item._id].quantity += item.quantity
                items[item.item._id].expense += item.consumptionBills.reduce(
                    (acc, billId) => {
                        return (acc + (consumptionBillsMap[billId]?.cost ?? 0))
                    }, 0)
                totalPrice += item.total
            }
        }
    }

    // const orderTotal = orders.reduce((acc1, ord)=>{
    //     return acc1 + ord.kots.reduce((acc2, kot)=>acc2+kot.total, 0)
    // }, 0)
    // const consumptionBillTotal = consumptionBills.reduce((acc1, bill)=>{
    //     return acc1 + ((bill?.item?.cost ?? 0) * bill.quantity)
    // }, 0)
    // const additionBillTotal = additionBills.reduce((acc1, bill)=>{
    //     return acc1 + ((bill?.item?.cost ?? 0) * bill.quantity)
    // }, 0)

    const handleExport = () => {
        let itemsData = (Object.values(items))
            .map((item) => {
                return [
                    item.item.name,
                    item.quantity,
                    item.total,
                    ((item.total / totalPrice) * 100)?.toFixed(2) + "%",
                    item.expense,
                    item.total - item.expense
                ]
            });
        exportToExcel(
            "Profit Margin  Details",
            [
                [
                    "Iten",
                    "Quantity",
                    "Revenue",
                    "Percentage",
                    "Expenditure",
                    "net Profit"
                ],
                ...itemsData
            ],
            startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
        );
    };

    const fetchOrders = async () => {

        try {
            setLoading(true)
            const res = await axios.get(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/orders/timeRange?from=${startDate.getTime()}&to=${endDate.getTime()}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );

            logger("resData", res.data.orders);
            setOrders(res.data);
            return res
        } catch (err) {
            logger(err);
            toast.error(
                err?.response?.data?.message ?? "Network connection failed"
            );
        }
    }


    return (
        <DashboardLayout>
            <Sidenav className="animate" routes={routes.inventory} />
            <DashboardNavbar
                showDateFilter
                showExport
                handleExport={handleExport}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                startDate={startDate}
                endDate={endDate}
            />
            <div
                style={{

                }}
            >
                <MDBox pt={6} pb={3}>
                    <Grid container spacing={6}>
                        <Grid item xs={12}>
                            <Card>
                                <MDBox
                                    mx={2}
                                    mt={-3}
                                    py={3}
                                    px={2}
                                    variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <div>
                                        <MDTypography variant="h6" color="white">
                                            Profit Margin Calculator
                                        </MDTypography>
                                        <MDInput
                                            name="search"
                                            type="text"
                                            size="small"
                                            label="Search"
                                            variant="filled"
                                            value={search}
                                            onChange={(e) => {
                                                setSearch(e.target.value);
                                            }}
                                            style={{
                                                backgroundColor: "white",
                                                borderRadius: "10px",
                                                width: "300px",
                                                maxWidth: "92%"
                                            }}

                                            fullWidth
                                        />
                                    </div>
                                    <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>


                                    </div>
                                </MDBox>
                                <MDBox pt={3}>
                                    {(
                                        <DataTable
                                            table={{
                                                columns: [
                                                    {
                                                        Header: "Item",
                                                        accessor: "item",
                                                    },
                                                    {
                                                        Header: "Quantity",
                                                        accessor: "quantity",
                                                    },
                                                    {
                                                        Header: "Revenue",
                                                        accessor: "revenue",
                                                    },
                                                    {
                                                        Header: "Revenue Percentage",
                                                        accessor: "percentage",
                                                    },
                                                    {
                                                        Header: "Expenditure",
                                                        accessor: "expense",
                                                    },
                                                    {
                                                        Header: "Net Profit",
                                                        accessor: "profit",
                                                    },
                                                    {
                                                        Header: "Profit Percentage",
                                                        accessor: "profitPercentage",
                                                    }

                                                ],
                                                rows: (Object.values(items))
                                                    .filter((item) => item.item.name.match(new RegExp(search?.replace(/\\/g, '\\\\'), 'i')))
                                                    .map((item, index) => {
                                                        return {
                                                            item: item.item.name,
                                                            quantity: (item.quantity).toFixed(2),
                                                            revenue: (item.total).toFixed(2),
                                                            percentage: ((item.total / totalPrice) * 100)?.toFixed(2) + "%",
                                                            expense: (item.expense).toFixed(2),
                                                            profit: (item.total - item.expense).toFixed(2),
                                                            profitPercentage: (((item.total - item.expense) / (item.expense || 1)) * 100)?.toFixed(2) + '%',
                                                        };
                                                    }),
                                            }}
                                            isSorted={false}
                                            entriesPerPage={false}
                                            showTotalEntries={false}
                                            noEndBorder
                                        />
                                    )}
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>

            </div>


        </DashboardLayout>
    );
}

export default ProfitMarginCalculator;
