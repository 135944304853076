import Skeleton from '@mui/material/Skeleton'

export default function AccordionLoader() {
    return (
        <div style={{display:"flex",width:"100%", flexDirection:"column", alignItems:"center", gap:"0px"}}>
            <Skeleton style={{ width: "95%", marginTop:"-15px" }} height={100} />
            <Skeleton style={{ width: "95%", marginTop:"-35px" }} height={100} />
        </div>
    )
}
