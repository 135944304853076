/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { BsInfo } from "react-icons/bs";
import { Dropdown, Space } from "antd";
import { useContext } from "react";
import { restaurant } from "providers/RestaurantProvider";
import NumberLoad from "components/NumberLoad/NumberLoad";

function  ComplexStatisticsCard({
  color,
  title,
  count,
  percentage,
  icon,
  showOption,
  tax,
  orders,
  orderDetails,
  netSales,
  totalTax,
  loading,
  showRs
}) {
  const StatisticsMenu = () => {
    const restaurantContext = useContext(restaurant)
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "5px",
          gap: "5px",
        }}
      > 
        {!orderDetails && (
          <>
            <div
              style={{
                display: "flex",
                width: "200px",
                justifyContent: "space-between",
              }}
            >
         
            </div>
              {
                restaurantContext.restaurant.taxes.map((item, index)=>{
                  return <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <b>{item.name} : </b>
                  <span>{((item.value/100)*netSales).toFixed(2)}</span>
                </div>
                })
              }
            <div
              style={{
                display: "flex",
                borderTop: "1px solid grey",
                paddingTop: "5px",
                marginTop: "5px",
                justifyContent: "space-between",
              }}
            >
              <b>Total : </b>
              <span>{totalTax.toFixed(2)}</span>
            </div>
          </>
        )}
        {orderDetails && (
          <>
            <div
              style={{
                display: "flex",
                width: "200px",
                justifyContent: "space-between",
              }}
            >
              <b>No of Orders : </b>
              <span>{orderDetails.noOfOrders}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>Minimum : </b>
              <span>{orderDetails.min ?? 0}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>Maximum : </b>
              <span>{orderDetails.max ?? 0}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>Average : </b>
              <span>{(isNaN(orderDetails?.avg) ? 0 : orderDetails?.avg ) ?? 0}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>Discount : </b>
              <span>{orderDetails.discount ?? 0}</span>
            </div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <b>Tax : </b>
              <span>{orderDetails.tax ?? 0}</span>
            </div>
            <div
              style={{
                display: "flex",
                borderTop: "1px solid grey",
                marginTop: "5px",
                paddingTop: "5px",
                justifyContent: "space-between",
              }}
            >
              <b>Total : </b>
              <span>{orderDetails.total}</span>
            </div>
          </>
        )}
      </div>
    );
  };
  const items = [
    {
      label: <StatisticsMenu />,
      key: "0",
    },
  ];
  return (
    <Card >
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3} 
        >
          {icon}
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          {/* <MDTypography variant="h4">{count}</MDTypography> */}
          <NumberLoad number={count} loading={loading} variant="h4" showRs={showRs} />
        </MDBox>
      </MDBox>
      <Divider />
     
      <div
        style={{
          padding: "10px",
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Dropdown
          menu={{
            items,
          }}
          trigger={["click"]}
        >
          <Space style={{}}>
            {
              showOption &&
            <div
              style={{
                backgroundColor: "#E6E3DC",
                width: "24px",
                height: "24px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "12px",
                visibility: showOption ? "visible" : "hidden"
              }}
            >
              <BsInfo
              fontSize={"1.3rem"}
                cursor={"pointer"}
              />
            </div>
            }

          </Space>
        </Dropdown>
      </div>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
ComplexStatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
};

// Typechecking props for the ComplexStatisticsCard
ComplexStatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
};

export default ComplexStatisticsCard;
