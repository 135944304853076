import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import toast from "../../utils/toast";
import { Card, Grid, IconButton } from "@mui/material";
import styles from "./onlineOrders.module.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MuiDrawer from "@mui/material/Drawer";
import DataTable from "examples/Tables/DataTable";
import { RiEdit2Fill, RiEye2Fill, RiEye2Line, RiEyeFill } from "react-icons/ri";
import SwiggyOrderDetails from "./swiggyOrderDetails";

const SwiggyOrders = () => {
	const [orders, setOrders] = useState([]);
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);
	const [order, setOrder] = useState({});


	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	const fetchData=async()=>{
		return axios.get(
            `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/onlineOrders/swiggy?startDate=${startDate.getTime()}&endDate=${endDate.getTime()}`,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            ).then(res=>{
                console.log(res.data)
                setOrders(res.data?.orders ?? []);
            }).catch(err=>{
                console.log(err)
            })
	}

	useEffect(() => {
		const callFunction = fetchData();
		toast.promise(callFunction, {
			loading: "Fetching orders",
			error: "Fetching orders Failed",
			success: "Fetched Successfully"
		});
	}, [startDate, endDate]);

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.onlineOrders} />
			<DashboardNavbar
			showDateFilter
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
			/>
			<div
			>
				<div className={styles.topHeadingContainer}></div>
				<div className={styles.mainContainer}>
					<MDBox pt={6} pb={3}>
						<Grid container spacing={6}>
							<Grid item xs={12}>
								<Card>
									<MDBox
										mx={2}
										mt={-3}
										py={3}
										px={2}
										variant="gradient"
										className="primary-bg"
										borderRadius="lg"
									>
										<MDTypography variant="h6" color="white">
											Order List
										</MDTypography>
									</MDBox>
									<MDBox pt={3}>
										{orders.length > 0 && (
											<DataTable
												table={{
													columns: [
														{
															Header: "No",
															accessor: "no",
														},
														{
															Header: "Order ID",
															accessor: "orderId",
														},
														{
															Header: "Status",
															accessor: "status",
														},
														{
															Header: "Bill",
															accessor: "bill",
														},
														{
															Header: "Items",
															accessor: "items",
														},
														{
															Header: "Actions",
															accessor: "actions",
														},
													],
													rows: (orders ?? []).map((order, index) => {
														return {
															no: index+1,
															orderId: order?.orderId,
															status: order.status,
															bill: order.bill,
															items: order.items?.length,
															actions: (
																<div className={styles.actions}>
																			<RiEyeFill
																				style={{ cursor: "pointer" }}
																				onClick={()=>{
																					setOrder(order);
																					setIsDrawerOpen(true)
																				}}
																			></RiEyeFill>
																</div>
															),
														};
													}),
												}}
												isSorted={false}
												entriesPerPage={false}
												showTotalEntries={true}
												noEndBorder
												canSearch
											/>
										)}
									</MDBox>
								</Card>
							</Grid>
						</Grid>
					</MDBox>
					<>
						<MuiDrawer
							variant="temporary"
							anchor="right"
							open={isDrawerOpen}
							onClose={() => setIsDrawerOpen(false)}
							sx={{
								"& .MuiDrawer-paper": {
									width: "85%",
									maxWidth: "100%",
								},
								"& .MuiModal-backdrop": {
									backdropFilter: "blur(5px)",
								},
							}}
						>
							<div
								className={styles.details}
								style={{
									display: "flex",
									flexDirection: "column",
								}}
							>

								<div
									style={{
										display: "flex",
										justifyContent: "flex-end",
										marginRight: "10px",
									}}
								></div>
								<SwiggyOrderDetails order={order} />
							</div>
						</MuiDrawer>
					</>
				</div>
			</div>
		</DashboardLayout>
	);
};

export default SwiggyOrders;
