import React from "react";
import styles from "./WastageReport.module.css";
import { useContext } from "react";
import { inventory } from "../../../../providers/InventoryProvider";
import {
	Button,
	Card,
	Dialog,
	Grid,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import DataTable from "examples/Tables/DataTable";
import MDTypography from "components/MDTypography";

function WastageReport() {
	const inventoryContext = useContext(inventory);
	const bills = {}
	inventoryContext.consumptionBills
		.filter((bill) => {
			return bill.wasted
		})
		.forEach(bill => {
			bills[bill.item._id] = {
				quantity: (bills?.[bill.item._id]?.quantity ?? 0) + bill.quantity,
				cost: bill.cost ?? 0,
				name: bill.item.name,
				unit: bill.item.unit
			}
		})
	return (
		<MDBox pt={6} pb={3}>
			<Grid container spacing={6}>
				<Grid item xs={12}>
					<Card>
						<MDBox
							mx={2}
							mt={-3}
							py={3}
							px={2}
							variant="gradient"
							bgColor="info"
							borderRadius="lg"
							coloredShadow="info"
						>
							<MDTypography variant="h6" color="white">
								Wastage Report (<i>Raw Material wise</i>)
							</MDTypography>
						</MDBox>
						<MDBox pt={3}>
							{(
								<DataTable
									height="100%"
									table={{
										columns: [
											{
												Header: "Item",
												accessor: "item",
											},
											{
												Header: "Quantity",
												accessor: "quantity",
											},
											{
												Header: "Unit",
												accessor: "unit",
											},
											{
												Header: "Cost",
												accessor: "cost",
											}

										],
										rows: (Object.values(bills)).map((bill, index) => {
											return {
												item: bill.name,
												quantity: bill.quantity.toFixed(2),
												unit: bill.unit ?? 0,
												cost: ((bill.quantity * (bill?.cost ?? 0))).toFixed(2),
											};
										}),
									}}
									isSorted={false}
									entriesPerPage={false}
									showTotalEntries={false}
									noEndBorder
								/>
							)}
						</MDBox>
					</Card>
				</Grid>
			</Grid>
		</MDBox>
	);
}

export default WastageReport;
