import React from 'react'
import styles from '../dashboardmobile.module.css'

export default function PaymentMethodItem({name, price}) {
    return (
        <div className={styles.dashboard_item+ " "+styles.outside}>
            <div className={styles.dashboard_item_left}>

                <div className={styles.dashboard_item}>
                    <span>{name}</span>
                </div>
            </div>
          
            <div className={styles.dashboard_item_right}>
                <b>₹{price}</b>
            </div>
        </div>
    )
}
