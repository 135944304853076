import React from 'react'
import './blobcontainer.css'

export default function BlobContainer() {
  return (
  //   <div class="blob-container">
  //   <div class="blob"></div>
  //   <div class="blob one"></div>
  //   <div class="blob two"></div>
  //   <div class="blob three"></div>
  //   <div class="blob four"></div>
  //   <div class="blob five"></div>
  //   <div class="blob six"></div>
  //   <div class="blob seven"></div>
  //   <div class="blob eight"></div>
  //   <div class="blob nine"></div>
  //   <div class="blob ten"></div>
  // </div>
  <></>
  )
}
