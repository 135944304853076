import React, { useEffect } from "react";
import styles from "./LiveView.module.css";
import RunningOrders from "./RunningOrders/RunningOrders";
import RunningTables from "./RunningTables/RunningTables";
import { useState } from "react";
import { useContext } from "react";
import { admin } from "../../providers/AdminProvider";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import { MdOutlineTableRestaurant } from "react-icons/md";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { IoBagCheckOutline } from "react-icons/io5";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import toast from "utils/toast";

function LiveView() {
	const context = useContext(admin);
	const { fetchRunningOrders } = context;
	const [bottomNav, setBottomNav] = useState(0)

	const refresh = () => {
		const callFunction = fetchRunningOrders();
        toast.promise(callFunction, {
            loading: "Refreshing orders",
            error: "Refreshing orders failed",
            success: "Order refreshed successfully"
        });
	};

	useEffect(() => {
		const callFunction = fetchRunningOrders();
        toast.promise(callFunction, {
            loading: "Fetching Running Orders",
            error: "Fetching Running Orders Failed",
            success: "Runnig Orders Refreshed Successfully"
        });
	}, []);

	return (
		<DashboardLayout>
			<DashboardNavbar />
			<Sidenav className="animate" routes={routes.main} />
			<div>
				<div className={styles.mainContainer}>
					{bottomNav==0 && <RunningOrders  />}
					{bottomNav==1 && <RunningTables />}
					
					<BottomNavigation
                    sx={{
                        position: "fixed",
                        bottom: "0px",
                        width: "100%",
                        height: "60px",
						
                    }}
					className={styles.bottomBar}
                    showLabels
                    value={bottomNav}
                    onChange={(event, newValue) => {
                        setBottomNav(newValue);
                    }}
                >
                    <BottomNavigationAction label="Live Order" icon={<IoBagCheckOutline />} />
                    <BottomNavigationAction label="Live Tables" icon={<MdOutlineTableRestaurant />} />
                </BottomNavigation>
				</div>

			</div>
		</DashboardLayout>
	);
}

export default LiveView;
