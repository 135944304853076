import React, { useEffect, useState, useContext } from "react";
import { admin } from "providers/AdminProvider";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { RiUserLine } from "react-icons/ri";
import Edit from "@mui/icons-material/Edit";
import styles from './recipeStyle.module.css'
import logger from "utils/logger";

function RecipeCard({
	recipe,
	setEditRecipe,
	setEditRecipeDialogOpen
}) {

	logger(recipe, "recipes")
	const context = useContext(admin);
	return (
		<Card
			sx={{
				margin: "4px",
				marginTop:"15px"
			}}
			>
			<MDBox mb={2} px={2} textAlign="center" lineHeight={1.25}>

				<div style={{ display: "flex", justifyContent: "space-between" }}>
					<MDTypography
						variant="b"
						color="text"
						fontWeight="600"
						display="flex"
						alignItems="center"
						cursor="pointer"
						marginBottom="10px"
					>
						<b>{recipe?.item?.name}</b>
					</MDTypography>
					<Edit fontWeight={600} cursor={"pointer"} onClick={() => {
						setEditRecipe({...recipe});
						setEditRecipeDialogOpen(true)
					}} />
				</div>
					<Divider />
				<div style={{ display: "flex", marginBottom: "10px", justifyContent: "space-between" }}>
					<div>Ingredient</div>
					<div>Quantity</div>
				</div>
				{
					recipe?.ingredients?.map((item, index) => {
						return <div style={{ display: "flex", justifyContent: "space-between", fontWeight:400, opacity:"70%" }}>
							<div>{item?.ingredient?.name}</div>
							<div>{item.quantity + " "+ item?.ingredient?.unit}</div>
						</div>
					})
				}

			</MDBox>
		</Card>
	);
}

export default RecipeCard;
