
/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// import styles from './AddUserDialog.module.css'

// Authentication layout components

// Images
import {
    Dialog,
    Fab,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { restaurant } from "providers/RestaurantProvider";
import { Add, Delete } from "@mui/icons-material";
import toast from "utils/toast";
import axios from "axios";

function EditAggregator({
    open,
    setOpen,
    aggregator,
    refresh
}) {
    const [data, setData] = useState({
        restaurantID: "",
        mobile: "",
        password: "",
        type: "",
        commission: 0
    })
    const [isLoading, setIsLoading]= useState(false)

    useEffect(()=>{
        if(aggregator){
            console.log(aggregator)
            setData({...aggregator})
        }
    },[aggregator])

    const saveHandler = async () => {
        setIsLoading(true)
        try{
            const res= await axios.put(
            `/restaurants/${localStorage.getItem("restaurant")}/onlineOrders/${aggregator._id
            }`,
            {
                ...data,
            },
            {
                headers: {
                    "x-auth-token": localStorage.getItem("token"),
                },
            }
        )
        setIsLoading(false)
            refresh()
            setOpen(false)
        return res;
        }catch(err){
            setIsLoading(false)
            throw new Error(err)
        }
    }

    const isDisabled=()=>{
        if(data.mobile || data.password){
            if(!data.mobile && !data.password){
                return true
            }
        }
        return false 
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "400px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Edit Aggregator
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox role="form">
                            <MDBox mb={1}>
                                <MDInput
                                    type="text"
                                    label="Restaurant ID"
                                    name="restaurantID"
                                    id="restaurantID"
                                    value={data.restaurantID}
                                    onChange={(e) => {
                                        setData({ ...data, restaurantID: e.target.value });
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>

                            <MDBox mb={1}>
                                <FormControl variant="standard" sx={{ width: "100%" }}>
                                    <InputLabel id="demo-simple-select-standard-label">
                                        Aggregator
                                    </InputLabel>

                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        sx={{ width: "100%", height: "50px" }}
                                        value={data.type}
                                        onChange={(e) => {
                                            setData({ ...data, type: e.target.value });
                                        }}
                                        variant="standard"
                                    >
                                        <MenuItem value={"swiggy"}>Swiggy</MenuItem>
                                        <MenuItem value={"zomato"}>Zomato</MenuItem>
                                    </Select>
                                </FormControl>
                            </MDBox>
                            <MDBox mb={1}>
                                <MDInput
                                    type="number"
                                    label="Commission"
                                    name="commssion"
                                    id="commision"
                                    value={data.commission}
                                    onChange={(e) => {
                                        setData({ ...data, commission: e.target.value });
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={1}>
                                <MDInput
                                    type="number"
                                    label="Mobile"
                                    name="mobile"
                                    id="mobile"
                                    value={data.mobile}
                                    onChange={(e) => {
                                        setData({ ...data, mobile: e.target.value });
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={1}>
                                <MDInput
                                    type="password"
                                    label="Password"
                                    name="password"
                                    id="password"
                                    value={data.password}
                                    onChange={(e) => {
                                        setData({ ...data, password: e.target.value });
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>

                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={() => {
                                        const callFunction = saveHandler();
                                        toast.promise(callFunction, {
                                            loading: "Updating Aggregator...",
                                            error: "Updating Failed",
                                            success: "Updated Successfully"
                                        });
                                    }}
                                    disabled={isLoading || isDisabled()}
                                    color="info"
                                    fullWidth
                                >
                                    Save{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default EditAggregator;
