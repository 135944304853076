/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import DoneAllIcon from "@mui/icons-material/DoneAll";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";
import { Grid } from "@mui/material";
import { Link } from "react-router-dom";

function OrdersOverview({ data }) {
  return (
    <Card >
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Orders overviews
        </MDTypography>
        
      </MDBox>
      <MDBox p={2}>
        <Grid container spacing={3}>
          <Grid item xs={6} md={6} lg={6}>
            <Link to="/live-view" style={{textDecoration:"none"}}>
            <TimelineItem
            showLine
              color="info"
              icon={<AccessTimeIcon />}
              title="Running"
              dateTime={data.running}
            />
            </Link>
            <TimelineItem
            showLine
              color="success"
              icon={<CheckIcon />}
              title="Completed Orders"
              dateTime={data.completed}
            />
          </Grid>
          <Grid item xs={6} md={6} lg={6}>
            <TimelineItem
            showLine
              color="error"
              icon={<DoNotDisturbIcon />}
              title="Cancelled"
              dateTime={data.cancelled}
            />
            <TimelineItem
            showLine
              color="warning"
              icon={<DoneAllIcon />}
              title="Settled"
              dateTime={data.settled}
            />
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
