/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components

// Images
import {
    Button,
    Dialog,
    FormControl,
    InputLabel,
    List,
    MenuItem,
    Select,
    Switch,
    TextField,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { restaurant } from "providers/RestaurantProvider";
import { RiAddCircleLine, RiDeleteBin7Fill } from "react-icons/ri";
import toast from "utils/toast";
import logger from "utils/logger";
import { inventory } from "providers/InventoryProvider";
import axios from "axios";
// import BasicLayout from "../../../components/";

function EditRecipeDialog({ open, setOpen, recipe }) {

    const {items} = useContext(restaurant)
    const { inventoryItems:inventoryItemsData } = useContext(inventory)
    const inventoryItems = inventoryItemsData.filter(item=>item.deleted!==true)
    const inventoryContext= useContext(inventory)
    const [isLoading, setIsLoading] = useState(false)
    const [item, setItem] = useState("")
    const [ingredients, setIngredients] = useState([])
    const [ingredient, setIngredient] = useState({
        id: "",
        quantity: 0,
        name: "",
        unit:""
    })
    const [option, setOption] = useState()
    const [additionalIngredients, setAdditionalIngredients] = useState([])
    const [newAdditionalIngredients, setNewAdditionalIngredients] = useState([])
    const [additionalIngredient, setAdditionalIngridient] = useState({
        id: "",
        quantity: 0,
        name: "",
        unit: ""
    })

    const handleAddAdditionalIngredient = (e) => {
        e.preventDefault();
        if (additionalIngredient.name == "" || additionalIngredient.quantity < 0) {
            return;
        }
        const isIngredientAlreadyAdded = newAdditionalIngredients.find((i) => {
            return additionalIngredient.id === i.id
        })
        if (isIngredientAlreadyAdded) {
            toast.error("Ingredient already added");
            return;
        }
        setNewAdditionalIngredients([
            ...newAdditionalIngredients, additionalIngredient
        ])
        setAdditionalIngridient({
            id: "",
            quantity: 0,
            name: "",
            unit: ""
        })
    };

    const handleSaveNewIngredient = () => {
        setAdditionalIngredients([
            ...additionalIngredients,
            {
                ingredients: newAdditionalIngredients,
                option
            }

        ])
        setAdditionalIngridient({
            id: "",
            quantity: 0,
            name: "",
            unit: ""
        })
        setOption("")
        setNewAdditionalIngredients([])
    }

    const handleRemoveAdditionalIngredient = (index) => {
        const updatedIngredients = [...newAdditionalIngredients];
        updatedIngredients.splice(index, 1);
        setNewAdditionalIngredients([...updatedIngredients]);
    };
    const handleRemoveAdditionalIngredientOption = (index) => {
        const updatedIngredients = [...additionalIngredients];
        updatedIngredients.splice(index, 1);
        setAdditionalIngredients([...updatedIngredients]);
    };

    const handleAddIngredient = (e) => {
        e.preventDefault();
        const isIngredientAlreadyAdded = ingredients.find((i) => {
            return ingredient.id === i.id
        })
        if (isIngredientAlreadyAdded) {
            toast.error("Ingredient already added");
            return;
        }
        setIngredients([
            ...ingredients, ingredient
        ])
        setIngredient({
            id: "",
            quantity: 0,
            name: "",
            unit:""
        })
    };

    const handleRemoveIngredient = (index) => {
        const updatedIngredients = [...ingredients];
        updatedIngredients.splice(index, 1);
        setIngredients([...updatedIngredients]);
    };

    const saveHandler = async(e) => {
        setIsLoading(true)
        if(item?.trim()===""){
            toast.error("item cannot be empty")
            setIsLoading(false)
            return;
        }
        if(!ingredients[0]){
            toast.error("ingredients cannot be empty")
            setIsLoading(false)
            return;
        }
        try{
            const additionalIngredientsData = additionalIngredients.map((adIng)=>{
                const adIngredients= adIng.ingredients.map((ing)=>{
                    return { ingredient: ing.id, quantity: ing.quantity }
                })
                return {ingredients:adIngredients, option:adIng.option}
            })

        const ingredientsData= ingredients.map((item)=>{
            return {ingredient:item.id, quantity:item.quantity}
        })
        const res = await axios.put(
            `/restaurants/${localStorage.getItem(
              "restaurant"
            )}/recipe/${recipe._id}`,
            {ingredients:ingredientsData, additionalIngredients:additionalIngredientsData },
            {
              headers: {
                "x-auth-token": localStorage.getItem("token"),
              },
            }
          );
          if(res.status===200)
          {
              toast.success("Successfully updated",{
                  autoClose:2000
              })
              
              inventoryContext.fetchRecipes();
          }
          setOpen(false)
          setIsLoading(false)

        }catch(err){
            setOpen(false)
            setIsLoading(false)
            toast.error(err?.response?.data?.message ?? "Network connections failed")
            
        }

    };

    useEffect(()=>{
        if(recipe){
            let ingredientsItem= (recipe?.ingredients ?? []).map((it)=>{
                return {
                    id:it?.ingredient?._id ?? "",
                    name:it?.ingredient?.name ?? "",
                    unit:it?.ingredient?.unit ?? "",
                    quantity:it?.quantity ?? 0,
                }
            })
            let additionalIngredientItems=(recipe?.additionalIngredients ?? [])?.map((ing)=>{
                let adIngredients=ing?.ingredients?.map((it)=>{
                    return {
                        id:it?.ingredient?._id ?? "",
                        name:it?.ingredient?.name ?? "",
                        unit:it?.ingredient?.unit ?? "",
                        quantity:it?.quantity ?? 0,
                    }
                })
                return {option:ing.option, ingredients:adIngredients}
            })
            setAdditionalIngredients([...additionalIngredientItems])
            setIngredients([...ingredientsItem])
            logger(recipe.item?._id, 'rgdf')
            setItem(recipe?.item?._id)
        }

    },[recipe])

    const options = []
    items[item]?.properties?.map((prop) => {
        prop?.options?.forEach((option) => {
            const optionFound = additionalIngredients.find((adIngr) => adIngr.option === option.name)
            if (!optionFound) {
                options.push({ ...option, show: true })
            } else {
                options.push({ ...option, show: false })

            }
        })
    })




    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                // sx={{ zIndex: 200 }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "600px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={-1}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Edit recipe
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">

                            <MDBox mb={2}>

                                <div>
                                    
                                </div>
                                <div>
                                    <MDBox mt={2}>
                                        <b>Ingredients</b>
                                    </MDBox>
                                </div>
                                <div>
                                    <MDBox>
                                        <div
                                            style={{
                                                display: "flex",
                                                width: "100%",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <ol style={{ width: "100%" }}>
                                                {ingredients.map((ingredient, index) => (
                                                    <li style={{ width: "100%", marginLeft:"20px" }}>
                                                        <div
                                                            style={{
                                                                width: "100%",
                                                                
                                                                display: "flex",
                                                                justifyContent: "space-between",
                                                                alignItems: "center",
                                                            }}
                                                        >
                                                            <span>{ingredient.name}</span>
                                                            <span>{ingredient.quantity + " " +ingredient.unit}</span>
                                                            <Button color="black">
                                                                <RiDeleteBin7Fill
                                                                    onClick={() =>
                                                                        handleRemoveIngredient(index)
                                                                    }
                                                                />
                                                            </Button>
                                                        </div>
                                                    </li>
                                                ))}
                                            </ol>
                                        </div>
                                    </MDBox>
                                </div>
                                <div>
                                    <MDBox mb={2}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormControl variant="standard" sx={{ width: "100%" }}>
                                                <InputLabel id="demo-simple-select-standard-label">Choose Ingredient</InputLabel>
                                                <Select
                                                    // labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    sx={{ width: "100%", height: "29px" }}
                                                    name="role"
                                                    variant="standard"
                                                    placeholder="Choose Ingredient"
                                                    value={ingredient?.id+"|"+ingredient.name+"|"+ingredient.unit}
                                                    onChange={(e) => { 
                                                   
                                                        setIngredient({
                                                            ...ingredient,
                                                            id:e.target.value.split("|")[0],
                                                            name:e.target.value.split("|")[1],
                                                            unit:e.target.value.split("|")[2],
                                                        })
                                                    }}
                                                >
                                                    {
                                                        inventoryItems.map((it, index)=>{
                                                        return <MenuItem value={it._id+"|"+it.name+"|"+it.unit}>{it.name}</MenuItem>
                                                        })
                                                    }
                                                    
                                                </Select>
                                            </FormControl>
                                            <MDInput
                                                type="number"
                                                label="Quantity"
                                                name="quantity"
                                                value={ingredient.quantity}
                                                onChange={(e) => setIngredient({...ingredient, quantity:e.target.value})}
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        handleAddIngredient(e);
                                                    }
                                                }}
                                                variant="standard"
                                                fullWidth
                                            />
                                            <MDInput
                                                readOnly
                                                type="text"
                                                label="Unit"
                                                name="unit"
                                                value={ingredient.unit}
                                                variant="standard"
                                                fullWidth
                                            />
                                            <Button
                                                variant="contained"
                                                onClick={handleAddIngredient}
                                                disabled={
                                                    !ingredient.id ||
                                                    !ingredient.quantity
                                                }
                                            >
                                                <RiAddCircleLine
                                                    style={{ color: "white", fontSize: "1rem" }}
                                                    color="white"
                                                />
                                            </Button>
                                        </div>
                                    </MDBox>
                                </div>
                                <div>
                                    <MDBox mt={2}>
                                        <b>Additional Ingredients</b>
                                    </MDBox>
                                </div>
                                <div>
                                    {
                                        additionalIngredients.map((adIngr, index) => {
                                            return <div>
                                                <MDBox mt={2}>
                                                    <b style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                                        <div>
                                                            {adIngr.option}
                                                        </div>
                                                        <RiDeleteBin7Fill
                                                            onClick={() =>
                                                                handleRemoveAdditionalIngredientOption(index)
                                                            }
                                                        />
                                                    </b>
                                                </MDBox>
                                                <MDBox>
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            width: "100%",
                                                            justifyContent: "space-between",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <ol style={{ width: "100%" }}>
                                                            {adIngr.ingredients.map((ingredient, index) => (
                                                                <li style={{ width: "100%", marginLeft:"20px"  }}>
                                                                    <div
                                                                        style={{
                                                                            width: "100%",
                                                                            display: "flex",    
                                                                            alignItems: "center",
                                                                            gap: "20px"
                                                                        }}
                                                                    >
                                                                        <span>{ingredient.name}</span>
                                                                        <span>({ingredient.quantity + " " + ingredient.unit})</span>

                                                                    </div>
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </div>
                                                </MDBox>
                                            </div>
                                        })
                                    }
                                </div>
                                {
                                    option &&
                                    <>
                                        <div>
                                            <MDBox mt={2}>
                                                <b>{option}</b>
                                            </MDBox>
                                            <MDBox>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        width: "100%",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >

                                                    <ol style={{ width: "100%" }}>
                                                        {newAdditionalIngredients.map((ingredient, index) => (
                                                            <li style={{ width: "100%", marginLeft:"20px"  }}>
                                                                <div
                                                                    style={{
                                                                        width: "100%",
                                                                        display: "flex",
                                                                        justifyContent: "space-between",
                                                                        alignItems: "center",
                                                                    }}
                                                                >
                                                                    <span>{ingredient.name}</span>
                                                                    <span>{ingredient.quantity + " " + ingredient.unit}</span>
                                                                    <Button color="black">
                                                                        <RiDeleteBin7Fill
                                                                            onClick={() =>
                                                                                handleRemoveAdditionalIngredient(index)
                                                                            }
                                                                        />
                                                                    </Button>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </ol>
                                                </div>
                                            </MDBox>
                                        </div>
                                        <div>
                                            <MDBox mb={2}>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <FormControl variant="standard" sx={{ width: "100%" }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Choose Ingredient</InputLabel>
                                                        <Select
                                                            // labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            sx={{ width: "100%", height: "29px" }}
                                                            name="role"
                                                            variant="standard"
                                                            placeholder="Choose Ingredient"
                                                            value={additionalIngredient?.id + "|" + additionalIngredient.name + "|" + additionalIngredient.unit}
                                                            onChange={(e) => {
                                                                setAdditionalIngridient({
                                                                    ...additionalIngredient,
                                                                    id: e.target.value.split("|")[0],
                                                                    name: e.target.value.split("|")[1],
                                                                    unit: e.target.value.split("|")[2],
                                                                })
                                                            }}
                                                        >
                                                            {
                                                                inventoryItems.map((it, index) => {
                                                                    return <MenuItem value={it._id + "|" + it.name + "|" + it.unit}>{it.name}</MenuItem>
                                                                })
                                                            }

                                                        </Select>
                                                    </FormControl>
                                                    <MDInput
                                                        type="number"
                                                        label="Extra Quantity"
                                                        name="quantity"
                                                        value={additionalIngredient.quantity}
                                                        onChange={(e) => setAdditionalIngridient({ ...additionalIngredient, quantity: e.target.value })}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                handleAddIngredient(e);
                                                            }
                                                        }}
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                    <MDInput
                                                        readOnly
                                                        type="text"
                                                        label="Unit"
                                                        name="unit"
                                                        value={additionalIngredient.unit}
                                                        variant="standard"
                                                        fullWidth
                                                    />
                                                    <Button
                                                        variant="contained"
                                                        disabled={
                                                            !additionalIngredient.id ||
                                                            !additionalIngredient.quantity
                                                        }
                                                        onClick={handleAddAdditionalIngredient}
                                                    >
                                                        <RiAddCircleLine
                                                            style={{ color: "white", fontSize: "1rem" }}
                                                            color="white"
                                                        />
                                                    </Button>
                                                </div>
                                            </MDBox>
                                        </div>
                                    </>
                                }


                                <div>
                                    <MDBox mt={2}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "space-between",
                                                alignItems: "center",
                                            }}
                                        >
                                            <FormControl variant="standard" sx={{ width: "73%" }}>
                                                <InputLabel id="demo-simple-select-standard-label">Choose Options</InputLabel>
                                                <Select
                                                    // labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    sx={{ width: "100%", height: "29px" }}
                                                    name="role"
                                                    variant="standard"
                                                    placeholder="Choose Ingredient"
                                                    value={option}
                                                    onChange={(e) => {
                                                        if(e.target.value){
                                                            setOption(e.target.value);
                                                            setNewAdditionalIngredients([])
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value={""}> </MenuItem>
                                                    {
                                                        options
                                                            .filter(op => op.show)
                                                            .map((option, index) => {
                                                                return <MenuItem value={option.name}>{option.name}</MenuItem>
                                                            })
                                                    }

                                                </Select>
                                            </FormControl>
                                            <Button
                                                variant="contained"
                                                size="small"
                                                sx={{padding:"10px"}}
                                                onClick={handleSaveNewIngredient}
                                                disabled={
                                                    !option || !newAdditionalIngredients[0]
                                                }
                                            >
                                                <div style={{ color: "white" }}>
                                                    save ingredients
                                                </div>
                                            </Button>
                                        </div>
                                    </MDBox>
                                </div>
                            </MDBox>
                            
                            <MDBox mt={4} mb={1}>
                                <MDButton variant="gradient" onClick={saveHandler} color="info" fullWidth>
                                    Save <ClipLoader className="ms-1" loading={isLoading} size={10} color="white" />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
               
            </Dialog>
        </>
    );
}


export default EditRecipeDialog;
