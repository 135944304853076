import axios from "axios";
import React, { useEffect, useState } from "react";
import toast from "../../utils/toast";
import { Card, Grid } from "@mui/material";
import { Delete } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { RiEdit2Fill } from "react-icons/ri";
import commonStyles from '../../styles.module.css'
import AddExpenseDialog from "./AddExpenseDialog";

const ExpenseTracker = () => {
	const [startDate, setStartDate] = useState(
		new Date(new Date().setHours(0, 0, 0, 0))
	);
	const [endDate, setEndDate] = useState(
		new Date(new Date().setHours(23, 59, 59, 999))
	);
	const [expenses, setExpenses] = useState([])
	const [addExpenseDialogOpen, setAddExpenseDialogOpen] = useState(false)

	const fetchExpenses = async () => {
		try {
			const res = await axios.get(
				`/restaurants/${localStorage.getItem(
					"restaurant"
				)}/expense?from=${startDate.getTime()}&to=${endDate.getTime()}`,
				{
					headers: {
						"x-auth-token": localStorage.getItem("token"),
					},
				}
			);
			if (res?.data?.length <= 0) {
				toast.warning("No data Available")
			}
			setExpenses(res.data)
			return res;
		} catch (error) {
			console.log(error);
			toast.error(error?.response?.data?.message ?? "Network Connection failed", {
				autoClose: 2000,
			});
			throw new Error("Failed")
		}
	};

	// const handleCancel = async () => {
	// 	try {
	// 		const res = await axios.put(
	// 			`/restaurants/${localStorage.getItem("restaurant")}/orders/${orderID}`,
	// 			{
	// 				status: "cancelled",
	// 				cancelReason,
	// 			},
	// 			{
	// 				headers: {
	// 					"x-auth-token": localStorage.getItem("token"),
	// 				},
	// 			}
	// 		);

	// 		logger(res.data);


	// 		fetchOrders();
	// 		setConfirmDialogOpen(false);
	// 		return res
	// 	} catch (error) {
	// 		logger(error);
	// 		toast.error(error?.response?.data?.message ?? "Network connection failed", {
	// 			autoClose: 2000,
	// 		});
	// 	}
	// };

	const [expense , setExpense] = useState({})

	useEffect(() => {
		const callFunction = fetchExpenses();
		toast.promise(callFunction, {
			loading: "Fetching Expenses",
			error: "Fetching Failed",
			success: "Successfully Fetched"
		});
	}, [startDate, endDate]);

	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.main} />
			<DashboardNavbar
				showDateFilter
				setStartDate={setStartDate}
				setEndDate={setEndDate}
				startDate={startDate}
				endDate={endDate}
			/>
			<div >
				<MDBox>
					<div
						style={{
							display: "flex",
							justifyContent: "end",
							alignItems: "center",
						}}
					>
						<button
							style={{ padding: "15px", width: "200px" }}
							className={commonStyles.primary_button}
							onClick={() => {
								setAddExpenseDialogOpen(true)
							}}
						>
							Add New Expense
						</button>
					</div>
				</MDBox>
				<MDBox pt={6} pb={3}>
					<Grid container spacing={6}>
						<Grid item xs={12}>
							<Card>
								<MDBox
									mx={2}
									mt={-3}
									py={3}
									px={2}
									variant="gradient"
									className="primary-bg"
									borderRadius="lg"
								>
									<MDTypography variant="h6" color="white">
										Expense Tracker
									</MDTypography>
								</MDBox>
								<MDBox pt={3}>
									{expenses?.length > 0 && (
										<DataTable
											table={{
												columns: [
													{
														Header: "ID",
														accessor: "id",
													},
													{
														Header: "Category",
														accessor: "category",
													},
													{
														Header: "Description",
														accessor: "description",
													},
													{
														Header: "Amount",
														accessor: "amount",
													},
													{
														Header: "Actions",
														accessor: "actions",
													},
												],
												rows: (expenses ?? []).map((expense) => {
													return {
														id: expense._id,
														category: expense.category,
														amount: expense.amount,
														description: expense.description,
														actions: (
															<div>
																<RiEdit2Fill
																	style={{ cursor: "pointer" }}
																	onClick={() => {
																		setExpense(expense);
																		setAddExpenseDialogOpen(true)
																	}}
																></RiEdit2Fill>
																
															</div>
														),
													};
												}),
											}}
											isSorted={false}
											entriesPerPage={false}
											showTotalEntries={false}
											noEndBorder
											canSearch
										/>
									)}
								</MDBox>
							</Card>
						</Grid>
					</Grid>
				</MDBox>
				<AddExpenseDialog
				open={addExpenseDialogOpen}
				setOpen={setAddExpenseDialogOpen}
				fetchExpenses={fetchExpenses}
				expense={expense}
				setExpense={setExpense}
				></AddExpenseDialog>
			</div>
		</DashboardLayout>
	);
};

export default ExpenseTracker;
