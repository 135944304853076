/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Drawer from "@mui/material/Drawer";
import { styled } from "@mui/material/styles";
import { sidebar } from "providers/SidebarProvider";
import { useContext } from "react";

export default styled(Drawer)(({ theme, ownerState }) => {
	const { palette, boxShadows, transitions, breakpoints, functions } = theme;
	const { transparentSidenav, whiteSidenav, miniSidenav, darkMode } =
		ownerState;

	const sidebarWidth = 250;
	const { transparent, gradients, white, background } = palette;
	const { xxl } = boxShadows;
	const { pxToRem, linearGradient } = functions;

	let backgroundValue = darkMode
		? background.sidenav
		: linearGradient(gradients.dark.main, gradients.dark.state);

	// if (transparentSidenav) {
	// 	backgroundValue = transparent.main;
	// } else if (whiteSidenav) {
	// 	backgroundValue = white.main;
	// }

	// styles for the sidenav when miniSidenav={false}
	const drawerOpenStyles = () => ({
		background: backgroundValue,
		// transform: "translateX(0)",
		// transition: transitions.create("transform", {
		// 	easing: transitions.easing.sharp,
		// 	duration: transitions.duration.shorter,
		// }),

		// [breakpoints.up("xl")]: {
		// 	boxShadow: transparentSidenav ? "none" : xxl,
		// 	marginBottom: transparentSidenav ? 0 : "inherit",
		// 	left: "0",
		// 	width: sidebarWidth,
		// 	transform: "translateX(0)",
		// 	transition: transitions.create(["width", "background-color"], {
		// 		easing: transitions.easing.sharp,
		// 		duration: transitions.duration.enteringScreen,
		// 	}),
		// },
	});

	// styles for the sidenav when miniSidenav={true}
	const drawerCloseStyles = () => ({
		background: backgroundValue,
		transform: `translateX(${pxToRem(-320)})`,
		transition: transitions.create("transform", {
			easing: transitions.easing.sharp,
			duration: transitions.duration.shorter,
		}),

		[breakpoints.up("xl")]: {
			boxShadow: transparentSidenav ? "none" : xxl,
			marginBottom: transparentSidenav ? 0 : "inherit",
			left: "0",
			width: pxToRem(260),
			overflowX: "hidden",
			transform: "translateX(0)",
			transition: transitions.create(["width", "background-color"], {
				easing: transitions.easing.sharp,
				duration: transitions.duration.shorter,
			}),
		},
	});
	const {sidebarOpen, setSidebarOpen} = useContext(sidebar)

	return {
		"& .MuiDrawer-paper": {
			boxShadow: xxl,
			border: "none",
			...(sidebarOpen ? drawerOpenStyles() : drawerCloseStyles() ),
			background:
				"linear-gradient(132.17deg, rgba(255, 45, 45, 0.08) 23.87%, rgba(255, 122, 0, 0.06) 84.7%, rgba(255, 122, 0, 0.06) 84.7%)",

			boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.25) !important",
			backdropFilter:"blur(40px)"
		},
	};
});
