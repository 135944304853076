import React, { useEffect, useState } from "react";
import styles from "./UserManagement.module.css";
import StaffUser from "./StaffUser";
import { useContext } from "react";
import { restaurant as restaurantData } from "../../../providers/RestaurantProvider";
import logger from "../../../utils/logger";
import { useNavigate, useParams } from "react-router-dom";
import commonStyles from "../../../styles.module.css";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Sidenav from "examples/Sidenav";
import routes from "routes";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

function UserManagement() {
	const params = useParams();
	const restaurantContext = useContext(restaurantData);
	const { restaurant } = restaurantContext;
	const [staffMap, setStaffMap] = useState({
		CAPTAIN: [],
		ADMIN: [],
		WAITER: [],
		CASHIER: [],
	});

	const doMapping = () => {
		let staffDetails = {
			CAPTAIN: [],
			ADMIN: [],
			KITCHEN: [],
			CASHIER: [],
		};
		restaurant.staff.forEach((s) => {
			if (staffDetails[s.role]) {
				staffDetails[s.role].push(s);
			} else {
				staffDetails[s.role] = [s];
			}
		});
		setStaffMap(staffDetails);
	};
	useEffect(() => {
		logger(staffMap);
	}, [staffMap]);
	useEffect(() => {
		doMapping();
	}, []);

	const [type, setType] = useState(1);
	return (
		<DashboardLayout>
			<Sidenav className="animate" routes={routes.userManagement} />
			<DashboardNavbar />
			<div
				 
			>
				<div className={styles.mainContainer}>
					<StaffUser staff={staffMap[params.role.toUpperCase()] ?? []} />
				</div>
			</div>
		</DashboardLayout>
	);
}

export default UserManagement;
