
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import styles from "./rawMaterial.module.css";

// Authentication layout components

// Images
import {
    Dialog,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
} from "@mui/material";
import { useContext, useState } from "react";
import axios from "axios";
import logger from "utils/logger";
import toast from "utils/toast";
import { ClipLoader } from "react-spinners";
import { inventory } from "providers/InventoryProvider";
// import BasicLayout from "../../../components/";

function AddStockDialog({ open, setOpen, addItem, setAddItem }) {
    const [isLoading, setIsLoading] = useState(false)
    const inventoryContext = useContext(inventory);

    const [stock, setStock] = useState(0);
    const [cost, setCost] = useState(0);


    const saveHandler = async (e) => {
        e.preventDefault();
        setIsLoading(true)

        try {
            const data = {
                addedStock: stock,
                inventoryItem: addItem.id,
                cost
            };
            const res = await axios.post(
                `/restaurants/${localStorage.getItem(
                    "restaurant"
                )}/inventory/inventory-bill`,
                data,
                {
                    headers: {
                        "x-auth-token": localStorage.getItem("token"),
                    },
                }
            );
            logger(res);
            if (res.status === 200) {

                toast.success("Successfully Updated", {
                    autoClose: 2000,
                });
                let itemCopy = [...inventoryContext.inventoryItems];
                logger(addItem)
                for (let i = 0; i < itemCopy.length; i++) {
                    if (itemCopy[i]._id === addItem.id) {
                        const newCost = (Number(addItem.cost * addItem.currentStock) + Number(cost * stock)) / (Number(stock) + Number(addItem.currentStock))
                        itemCopy[i].currentStock += Number(stock);
                        itemCopy[i].cost = newCost
                        break;
                    }
                }
                setAddItem("");
                inventoryContext.setInventoryItems(itemCopy);
                setIsLoading(false)
                setOpen(false);
            }
            return res
        } catch (error) {
            setIsLoading(false)
            toast.error(error?.response?.data?.message ?? "Network connection failed");
            logger("Error while saving added stock", error);
            return;
        }
    };
    return (
        <>
            <Dialog
                open={open}
                onClose={() => {
                    setOpen(false);
                }}
                size={"small"}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Card sx={{ width: "600px", maxWidth: "100%" }}>
                    <MDBox
                        variant="gradient"
                        bgColor="info"
                        // borderRadius="lg"
                        coloredShadow="success"
                        sx={{ maxWidth: "100%", borderRadius: "0px 0px 10px 10px" }}
                        mx={2}
                        mt={0}
                        p={1}
                        textAlign="center"
                    >
                        <MDTypography variant="h5" fontWeight="medium" color="white" mt={1}>
                            Add Stock
                        </MDTypography>
                    </MDBox>
                    <MDBox pt={2} pb={3} px={3}>
                        <MDBox component="form" role="form">
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    type="number"
                                    label="Stock"
                                    name="stock"
                                    value={stock}
                                    onChange={(e) => {
                                        setStock(e.target.value < 0 ? 0 : e.target.value);
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                                <MDInput
                                    type="text"
                                    label="Unit"
                                    name="unit"
                                    value={addItem?.unit}
                                    readOnly
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mb={2} className={styles.add_user_form_row}>
                                <MDInput
                                    type="number"
                                    label="Price per unit"
                                    name="price"
                                    value={cost}
                                    onChange={(e) => {
                                        setCost(Number(e.target.value < 0 ? 0 : e.target.value));
                                    }}
                                    variant="standard"
                                    fullWidth
                                />
                            </MDBox>
                            <MDBox mt={4} mb={1}>
                                <MDButton
                                    variant="gradient"
                                    onClick={(e) => {
                                        const callFunction = saveHandler(e);
                                        toast.promise(callFunction, {
                                            loading: "Stock Adding...",
                                            error: "Stock Adding Failed",
                                            success: "Stock Added Successfully"
                                        });
                                    }}
                                    color="info"
                                    fullWidth
                                >
                                    Add{" "}
                                    <ClipLoader
                                        className="ms-1"
                                        loading={isLoading}
                                        size={10}
                                        color="white"
                                    />
                                </MDButton>
                            </MDBox>
                        </MDBox>
                    </MDBox>
                </Card>
            </Dialog>
        </>
    );
}

export default AddStockDialog;
