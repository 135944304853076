import React from 'react'
import DashboardMobile from './DashboardMobile'
import Dashboard from './Dashboard'

export default function DashboardMain() {
  return (
    <>
      
      {
        window.innerWidth <= 912 ?
          <DashboardMobile /> :
          <Dashboard />
      }

    </>
  )
}
