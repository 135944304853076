/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import styles from "./footer.module.css";
// Material Dashboard 2 React base styles
import typography from "assets/theme/base/typography";
import { RiMailFill, RiMailLine, RiPhoneLine } from "react-icons/ri";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  const renderLinks = () =>
    links.map((link, index) => (
      <>
        {index === 1 && (
          <MDBox key={link.name} component="li" px={2} lineHeight={1}>
            <Link href={link.href} target="_blank">
              <MDTypography
                variant="button"
                display="flex"
                gap="5px"
                alignItems="center"
                fontWeight="regular"
                color="text"
              >
                |
              </MDTypography>
            </Link>
          </MDBox>
        )}
        <MDBox key={link.name} component="li" px={2} lineHeight={1}>
          <Link href={link.href} target="_blank">
            <MDTypography
              variant="button"
              display="flex"
              gap="5px"
              alignItems="center"
              fontWeight="regular"
              color="text"
            >
              {link.icon}
              {link.name}
            </MDTypography>
          </Link>
        </MDBox>
      </>
    ));

  return (
    <MDBox
      width="100%"
      display="flex"
      flexDirection={{ xs: "column", lg: "row" }}
      justifyContent="space-between"
      alignItems="center"
      px={1.5}
    >
      <MDBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexWrap="wrap"
        color="text"
        fontSize={size.sm}
        px={1.5}
      >
        &copy; {new Date().getFullYear()}, powered by
        <Link href={href} target="_blank">
          <MDTypography variant="button" fontWeight="medium">
            &nbsp;Shakespeare&nbsp;
          </MDTypography>
        </Link>
        for better Restaurant Management.
      </MDBox>
      <MDBox
        component="ul"
        sx={({ breakpoints }) => ({
          display: "flex",
          flexWrap: "wrap",
          alignItems: "center",
          justifyContent: "center",
          listStyle: "none",
          mt: 3,
          mb: 0,
          p: 0,

          [breakpoints.up("lg")]: {
            mt: 0,
          },
        })}
      >
        {renderLinks()}
      </MDBox>
    </MDBox>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  // company: { href: "https://www.creative-tim.com/", name: "Creative Tim" },
  company: { href: "https://shakespeare.vision", name: "Creative Tim" },
  links: [
    {
      href: `mailto:support@shakespeare.vision?subject=ADMIN Query&body=Restaurant ID: ${localStorage.getItem(
        "restaurant"
      )}%0ARole: ADMIN%0AUser Agent: ${
        navigator.userAgent
      }%0A------------%0AI have a query regarding:%0A`,
      name: "Email",
      icon: <RiMailLine />,
    },
    {
      href: `https://wa.me/919849518781?text=Restaurant ID: ${localStorage.getItem(
        "restaurant"
      )}%0ARole: ADMIN%0AUser Agent: ${
        navigator.userAgent
      }%0A------------%0AI have a query regarding:%0A`,
      name: "(91) 98495 18781",
      icon: <RiPhoneLine />,
    },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
