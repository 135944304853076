import { createContext, useContext, useEffect } from "react";
import axios from "axios";
import { useState } from "react";
import toast from "../utils/toast";
import logger from "../utils/logger";
import { restaurant } from "./RestaurantProvider";

const admin = createContext();

const { Provider } = admin;

const AdminProvider = ({ children }) => {
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(true)
  const [orders, setOrders] = useState([]);
  const restaurantContext = useContext(restaurant);
  const [orderStatusMappedTable, setOrderStatusMappedTable] = useState({
    settled: [],
    completed: [],
    running: [],
    cancelled: [],
  });

  const fetchOrders = async ({ setLastSyncTime, from, to }) => {
    try {
      const currentTimestamp = new Date().getTime();
      setLastSyncTime(currentTimestamp);
      const res = await axios.get(
        `/restaurants/${localStorage.getItem(
          "restaurant"
        )}/orders/timeRange?from=${from}&to=${to}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const orders = res.data;
      const orderStatusMappedTable = {
        completed: [],
        cancelled: [],
        running: [],
        settled: [],
      };
      setOrders(orders);
      orders.forEach((order) => {
        if (orderStatusMappedTable[order.status]) {
          orderStatusMappedTable[order.status].push(order);
        } else {
          orderStatusMappedTable[order.status] = [order];
        }
      });
      let tot = 0;
      setOrderStatusMappedTable(orderStatusMappedTable);
      orderStatusMappedTable["settled"].forEach((order) => {
        order["kots"].forEach((kot) => {
          tot = tot + kot.total;
          logger(kot);
        });
      });
      tot = tot * 1.05;
      setTotal(tot.toFixed(2));
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const [runningOrders, setRunningOrders] = useState([]);
  const [runningKOTs, setRunningKOTs] = useState({});
  const fetchRunningOrders = async () => {
    try {
      const res = await axios.get(
        `/restaurants/${localStorage.getItem("restaurant")}/orders/running`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      const ord = res.data;
      logger("hii", ord);
      setRunningOrders(ord.orders);

      const kotData = {};

      for (let kot of ord.kots) {
        kotData[kot._id] = kot;
      }

      setRunningKOTs(kotData);
      return res
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const createItem = async (
    data,
    categoryToUpdate,
    setCategoryToUpdate,
    setConfirmDialogOpenAdd,
    items,
    setItems
  ) => {
    try {
      const res = await axios.post(
        `/restaurants/${localStorage.getItem("restaurant")}/items`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res);
      if (res.status === 201) {
        let temp = { ...categoryToUpdate };
        temp.items.push(res.data._id);
        setCategoryToUpdate(temp);
        logger(res.data._id);
        items[res.data._id] = res.data;
        setItems(items);
        logger("n", items[res.data._id]);
        setConfirmDialogOpenAdd(false);
        // updateMenu(setConfirmDialogOpenAdd);
      }
      return res
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const UpdateItem = async (
    data,
    setConfirmDialogOpenAdd,
    item,
    updateItem
  ) => {
    try {
      // return;
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}/items/${item._id}`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res);
      if (res.status === 200) {
        updateItem(res.data._id, res.data);
        setConfirmDialogOpenAdd(false);
      }
      return res
    } catch (error) {
      throw new Error(error?.response?.data?.message ?? "Something went wrong")
    }
  };

  const deleteItem = async ( itemId,categoryId) => {
    try {
      const res = await axios.delete(
        `/restaurants/${localStorage.getItem("restaurant")}/items/${itemId}/${categoryId}`,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      await restaurantContext.refreshRestaurant();
      toast.success("Successfully deleted Item")
      window.location.reload()
      
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const deleteItems = async (
    data,
    categoryToUpdate,
    setCategoryToUpdate,
    items,
    setItems,
    updateMenu,
    setWantDelete
  ) => {
    try {
      const res = await axios.post(
        `/restaurants/${localStorage.getItem("restaurant")}/items/delete`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res);

      if (res.status === 200) {
        let temp = { ...categoryToUpdate };
        for (let i = 0; i < data.ids.length; i++) {
          let id = data.ids[i];
          delete items[id];
          let index = temp.items.indexOf(id);
          if (index > -1) {
            temp.items.splice(index, 1);
          }
        }
        setCategoryToUpdate(temp);
        setItems(items);
        updateMenu();
        setWantDelete(false);
      }
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const updateTaxes = async (taxes, setRestaurant, setConfirmEdit) => {
    try {
      const data = {
        taxes: taxes,
      };
      const res = await axios.put(
        `/restaurants/${localStorage.getItem("restaurant")}`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      logger(res);
      if (res.status === 200) {
        setRestaurant(res.data);
        setConfirmEdit(false);
      }
    } catch (error) {
      logger(error);
      toast.error(
        error?.response?.data?.message ?? "Network connection failed",
        {
          autoClose: 2000,
        }
      );
    }
  };

  const [globalMenu, setGlobalMenu] = useState([]);
  const [backStack, setBackStack] = useState([]);

  const [categoryToUpdate, setCategoryToUpdate] = useState(null);
  const updateOne = (cat, backStack, setBackStack) => {
    setBackStack([...backStack, cat]);
    setCategoryToUpdate(cat);
  };

  const goBack = (
    categoryToUpdate,
    setCategoryToUpdate,
    backStack,
    setBackStack
  ) => {
    const updatedArray = backStack.slice(0, -1);
    setBackStack(updatedArray);
    let len = updatedArray.length;
    setCategoryToUpdate(updatedArray[len - 1]);
  };

  const updateMenu = async (
    setConfirmDialogOpenAdd,
    mainMenu,
    parentCategoryId,
    name
  ) => {
    try {
      setGlobalMenu([...globalMenu]);
      logger(globalMenu);
      const data = {
        // menu: globalMenu,
        mainMenu,
        parentCategoryId,
        name,
      };
      const res = await axios.post(
        `/restaurants/${localStorage.getItem("restaurant")}/category`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      if (setConfirmDialogOpenAdd) {
        setConfirmDialogOpenAdd(false);
      }
      return res;
    } catch (err) {
      logger(err);
      toast.error(err?.response?.data?.message ?? "Network connection failed");
    }
  };
  const deleteCategory = async (mainMenu, categoryId, parentCategoryId) => {
    try {
      setGlobalMenu([...globalMenu]);
      logger(globalMenu);
      const data = {
        mainMenu,
        parentCategoryId,
      };
      const res = await axios.put(
        `/restaurants/${localStorage.getItem(
          "restaurant"
        )}/category/${categoryId}/delete`,
        data,
        {
          headers: {
            "x-auth-token": localStorage.getItem("token"),
          },
        }
      );
      await restaurantContext.refreshRestaurant();
      toast.success("Successfully deleted category")
      window.location.reload()
      return res.data;
    } catch (err) {
      logger(err);
      toast.error(err?.response?.data?.message ?? "Network connection failed");
    }
  };

  const [editUser, setEditUser] = useState(null);

  useEffect(() => {
    let restaurantData = JSON.parse(localStorage.getItem("restaurantData"));
    if (restaurantData?.menu) {
      restaurantData.menu = globalMenu;
      localStorage.setItem("restaurantData", JSON.stringify(restaurantData));
    }
  }, [globalMenu]);

  useEffect(() => {
    setGlobalMenu([...(restaurantContext.restaurant?.menu ?? [])]);
  }, [restaurantContext.restaurant]);

  const rightsOptions = [
    {
      label: "Item Master",
      fields: [
        { label: "Read", value: "Item Master_READ" },
        { label: "Write", value: "Item Master_WRITE" },
      ],
    },
    {
      label: "Tax Configuration",
      fields: [
        { label: "Read", value: "Tax Configuration_READ" },
        { label: "Write", value: "Tax Configuration_WRITE" },
      ],
    },
    {
      label: "Customer Management",
      fields: [
        { label: "Read", value: "Customer Management_READ" },
        { label: "Write", value: "Customer Management_WRITE" },
      ],
    },
    {
      label: "KOT Management",
      fields: [
        { label: "Read", value: "KOT Management_READ" },
        { label: "Write", value: "KOT Management_WRITE" },
      ],
    },
    {
      label: "Reports",
      fields: [{ label: "Yes", value: "Reports_Yes" }],
    },
    {
      label: "Discount Configuration",
      fields: [
        { label: "Read", value: "Discount Configuration_READ" },
        { label: "Write", value: "Discount Configuration_WRITE" },
      ],
    },
    {
      label: "Area Table Management",
      fields: [
        { label: "Read", value: "Area Table Management_READ" },
        { label: "Write", value: "Area Table Management_WRITE" },
      ],
    },
    {
      label: "Allow KOT to Cancel",
      fields: [
        { label: "Reason", value: "Allow KOT to Cancel_Reason" },
        {
          label: "Reason With Password",
          value: "Allow KOT to Cancel_ReasonWithPassword",
        },
      ],
    },
    {
      label: "Allow Bill to Cancel",
      fields: [
        { label: "Reason", value: "Allow Bill to Cancel_Reason" },
        {
          label: "Reason With Password",
          value: "Allow Bill to Cancel_ReasonWithPassword",
        },
      ],
    },
    {
      label: "Category wise Taxes",
      fields: [
        { label: "Read", value: "Category wise Taxes_READ" },
        { label: "Write", value: "Category wise Taxes_WRITE" },
      ],
    },
    {
      label: "Waitlist",
      fields: [
        { label: "Read", value: "Waitlist_READ" },
        { label: "Write", value: "Waitlist_WRITE" },
      ],
    },
  ];

  return (
    <Provider
      value={{
        total,
        fetchOrders,
        orderStatusMappedTable,
        orders,
        fetchRunningOrders,
        runningOrders,
        categoryToUpdate,
        setCategoryToUpdate,
        updateOne,
        createItem,
        globalMenu,
        setGlobalMenu,
        updateMenu,
        UpdateItem,
        deleteItem,
        deleteItems,
        backStack,
        setBackStack,
        goBack,
        updateTaxes,
        editUser,
        setEditUser,
        rightsOptions,
        runningKOTs,
        deleteCategory,
        loading,
        setLoading
      }}
    >
      {children}
    </Provider>
  );
};

export { AdminProvider, admin };
